export default {
  Clark: [
    100,
    102,
    104,
    106,
    108,
    110,
    112,
    113,
    120,
    125,
    130,
    147,
    150,
    153,
    160,
    170,
    173,
    175,
    180,
    185,
    190,
    200,
    220,
    225,
    240,
    245,
    250,
    255,
    257,
    260,
    290,
    294,
    296,
    298,
    320,
    325,
    327,
    330,
    335,
    337,
    338,
    340,
    350,
    360,
    365,
    370,
    390,
    391,
    392,
    395,
    400,
    410,
    415,
    420,
    423,
    424,
    425,
    426,
    430,
    432,
    434,
    435,
    436,
    440,
    441,
    444,
    445,
    446,
    447,
    448,
    449,
    450,
    451,
    452,
    453,
    455,
    456,
    458,
    460,
    470,
    479,
    480,
    483,
    484,
    485,
    487,
    490,
    491,
    500,
    501,
    502,
    503,
    505,
    510,
    511,
    513,
    514,
    515,
    520,
    521,
    522,
    523,
    524,
    525,
    527,
    528,
    530,
    534,
    535,
    536,
    537,
    538,
    540,
    541,
    543,
    544,
    545,
    550,
    553,
    555,
    560,
    563,
    564,
    565,
    566,
    568,
    569,
    570,
    571,
    572,
    573,
    574,
    575,
    576,
    577,
    578,
    579,
    580,
    581,
    582,
    583,
    584,
    585,
    586,
    587,
    588,
    589,
    590,
    591,
    592,
    593,
    594,
    595,
    596,
    597,
    598,
    600,
    603,
    605,
    606,
    610,
    611,
    613,
    615,
    617,
    618,
    620,
    624,
    625,
    626,
    627,
    628,
    629,
    630,
    631,
    632,
    633,
    634,
    635,
    636,
    637,
    638,
    639,
    640,
    641,
    642,
    643,
    644,
    645,
    646,
    647,
    648,
    649,
    650,
    651,
    652,
    653,
    654,
    655,
    656,
    657,
    658,
    659,
    660,
    661,
    662,
    663,
    664,
    667,
    668,
    669,
    670,
    671,
    672,
    673,
    674,
    675,
    677,
    678,
    679,
    680,
    681,
    682,
    683,
    684,
    685,
    686,
    687,
    688,
    689,
    690,
    691,
    692,
    693,
    694,
    695,
    696,
    697,
    698,
    699,
    700,
    710,
    715,
    720,
    725,
    900,
    903,
    905,
    910,
    912,
    913,
    914,
    917,
    920,
    925,
    930,
    935,
    940,
    947,
    950,
    951,
    953,
    956,
    957,
    958,
    959,
    960,
    961,
    962,
    963,
    964,
    965,
    966,
    967,
    968,
    969,
    985
  ],
  Cowlitz: [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72
  ],
  Klickitat: [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    11,
    12,
    13,
    14,
    16,
    17,
    19,
    20,
    21,
    22,
    23,
    24,
    27,
    28,
    101,
    102,
    103,
    104,
    109,
    110
  ],
  Lewis: [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    101,
    102,
    103,
    104,
    105,
    106,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    209,
    300,
    400,
    501,
    502,
    503,
    504,
    505,
    506,
    600,
    700,
    800,
    900
  ],
  Pacific: [
    1,
    2,
    3,
    4,
    6,
    7,
    8,
    9,
    11,
    12,
    13,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    32,
    34,
    101,
    103,
    105,
    106,
    107,
    108,
    110,
    113,
    115,
    117
  ],
  Skamania: [
    100,
    101,
    102,
    103,
    104,
    105,
    106,
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    300,
    303,
    304,
    305,
    307,
    309,
    310,
    311
  ],
  Thurston: [27, 30, 31, 46, 48, 52, 53, 77, 130, 148, 154, 156, 157, 158, 801],
  Wahkiakum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
};
