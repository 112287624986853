import React from "react";
import ChartistGraph from "react-chartist";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from "components/Card/Card.jsx";
import Table from "components/Table/Table.jsx";
import {
    roundedLineChart,
    straightLinesChart,
    simpleBarChart,
    colouredLineChart,
    multipleBarsChart,
    colouredLinesChart,
    pieChart,
} from "variables/charts.jsx";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

export const RoyaltiesPage = ({ handleChange, timeframe, onInput }) => {
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ margin: '45px 0 0 0' }}>
                    <CardHeader color="warning">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <GridItem xs={4}>
                                <h4><b>$52,185.26</b></h4>
                                <p>Current balance</p>
                            </GridItem>
                            <GridItem xs={5}>
                                <FormControl variant="filled" style={{ width: '130px' }}>
                                    <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={timeframe}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'weekly'}>Weekly</MenuItem>
                                        <MenuItem value={'monthly'}>Monthly</MenuItem>
                                        <MenuItem value={'yearly'}>Yearly</MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </div>
                        <ChartistGraph
                            className="ct-chart-white-colors"
                            data={roundedLineChart.data}
                            type="Line"
                            options={roundedLineChart.options}
                            listener={roundedLineChart.animation}
                        />
                    </CardHeader>
                </GridItem>
            </GridContainer>
            <GridContainer style={{
                background: 'linear-gradient(180deg, rgba(255,69,149,1) 0%, rgba(210,59,198,1) 96%)',
                color: '#fff',
                display: 'flex',
                justifyContent: 'space-around',
                height: '20vh',
                margin: '30px 0px 0 -15px'
            }}>
                <GridItem
                    style={{
                        color: 'black',
                        backgroundColor: '#fff',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 0 0 20px',
                    }}
                >
                    <center>
                        <ArrowDropDownIcon
                            style={{ color: 'red', margin: '0 0 -13px 0' }}
                        />
                        <h5>
                            <strong>0.5%</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Interscope Records</p>
                    </center>
                </GridItem>
                <GridItem
                    style={{
                        color: 'black',
                        backgroundColor: '#fff',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 0 0 0',
                    }}
                >
                    <ArrowDropUpIcon
                        style={{ color: 'green', margin: '0 0 -13px 31px' }}
                    />
                    <center>
                        <h5>
                            <strong>1.7%</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Suburban Noize</p>
                    </center>
                </GridItem>
                <GridItem
                    style={{
                        color: 'black',
                        backgroundColor: '#fff',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 20px 0 0'
                    }}
                >
                    <ArrowDropDownIcon
                        style={{ color: 'red', margin: '0 0 -13px 31px' }}
                    />
                    <center>
                        <h5>
                            <strong>0.8%</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Universal Pictures</p>
                    </center>
                </GridItem>
            </GridContainer>
            <Card
                style={{
                    backgroundColor: '#223380',
                    borderRadius: '10px',
                    color: '#fff',
                    width: '95vw',
                    margin: '30px 0px 0 -5px'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <GridItem xs={6}>
                        <CardHeader>
                            <h4><b>$4,371.77</b></h4>
                            <p>Monthly Revenue</p>
                        </CardHeader>
                    </GridItem>
                    <GridItem xs={6}>
                        <FormControl variant="filled" style={{ width: '130px', margin: '17px 0 0px 53px' }}>
                            <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={timeframe}
                                onChange={handleChange}
                            >
                                <MenuItem value={'weekly'}>Weekly</MenuItem>
                                <MenuItem value={'monthly'}>Monthly</MenuItem>
                                <MenuItem value={'yearly'}>Yearly</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <GridItem xs={6}>
                        <ChartistGraph
                            data={pieChart.data}
                            type="Pie"
                            options={pieChart.options}
                        />
                    </GridItem>
                    <GridItem xs={6} style={{ margin: '24px 0 0 30px', fontSize: 'large' }}>
                        <ul>
                            <li>{pieChart.services[0]}</li>
                            <li>{pieChart.services[1]}</li>
                            <li>{pieChart.services[2]}</li>
                            <li>{pieChart.services[3]}</li>
                            <li>{pieChart.services[4]}</li>
                        </ul>
                    </GridItem>
                </div>
            </Card>
            <GridItem xs={12} style={{ background: 'linear-gradient(180deg, rgba(255,69,149,1) 0%, rgba(210,59,198,1) 96%)', margin: '30px 0 0 0', borderRadius: '10px' }}>
                <Table
                    tableHead={['Ranking', 'Song Name', 'Earnings']}
                    tableData={[
                        ['23.5%', 'Yesterday Don/t...', '$12,109.10'],
                        ['16.2%', 'Forgiven', '$8,194.19'],
                        ['1.87%', 'Set Me Free', '$1,143.77'],
                        ['10.9%', 'Cruel Intentions', '$5,181.11'],
                        ['0.78%', 'Reckoning', '$998.77'],
                        ['11.3%', 'Worship Me', '$1,765.82'],
                        ['5.1%', 'Meant To Go', '$786.28'],
                    ]}
                />
            </GridItem>
        </div>
    )
}

export default RoyaltiesPage;