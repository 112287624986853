import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const LabelsPage = () => (
    <div>
        <h3 style={{ color: '#f26599' }}><strong>Your Record Deals</strong></h3>
        <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
            <GridItem xs={11}
                style={{
                    color: '#fff',
                    background: 'linear-gradient(270deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
                    borderRadius: '5px',
                }}>
                <AccountCircleIcon style={{ width: '20%', height: '10vh', margin: '12px 14px -20px 0' }} />
                <GridItem xs={6} style={{ display: 'inline-block', margin: '0 0 15px 0' }}>
                    <h5><strong>Columbia Records</strong></h5>
                    <p>Label Bio</p>
                </GridItem>
                <PersonAddIcon style={{ width: '15%', height: '7vh', margin: '0px 0 -10px 57px', background: '#887aad', borderRadius: '50%' }} />
            </GridItem>
        </GridContainer>
        <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
            <GridItem xs={11}
                style={{
                    color: '#fff',
                    background: 'linear-gradient(270deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
                    borderRadius: '5px',
                    margin: '20px 0 0 0',
                }}>
                <AccountCircleIcon style={{ width: '20%', height: '10vh', margin: '12px 14px -20px 0' }} />
                <GridItem xs={6} style={{ display: 'inline-block', margin: '0 0 15px 0' }}>
                    <h5><strong>Warner Music</strong></h5>
                    <p>Label Bio</p>
                </GridItem>
                <PersonAddIcon style={{ width: '15%', height: '7vh', margin: '0px 0 -10px 90px', background: '#887aad', borderRadius: '50%' }} />
            </GridItem>
        </GridContainer>
        <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
            <GridItem xs={11}
                style={{
                    color: '#fff',
                    background: 'linear-gradient(270deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
                    borderRadius: '5px',
                    margin: '20px 0 0 0',
                }}>
                <AccountCircleIcon style={{ width: '20%', height: '10vh', margin: '12px 14px -20px 0' }} />
                <GridItem xs={6} style={{ display: 'inline-block', margin: '0 0 15px 0' }}>
                    <h5><strong>Virgin Records</strong></h5>
                    <p>Label Bio</p>
                </GridItem>
                <PersonAddIcon style={{ width: '15%', height: '7vh', margin: '0px 0 -10px 84px', background: '#887aad', borderRadius: '50%' }} />
            </GridItem>
        </GridContainer>
        <GridContainer
            style={{
                background:
                    'linear-gradient(0deg, rgba(247,78,100,1) 0%, rgba(242,101,153,1) 100%)',
                height: '50vh',
                color: '#fff',
                margin: '20px 0 0 -14px',
                padding: '10px'
            }}
        >
            <h3><strong>Labels Currently Following</strong></h3>
            <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
                <GridItem xs={11}
                    style={{
                        color: '#fff',
                        backgroundColor: '#f7b7d1',
                        borderRadius: '5px',
                        height: '87px',
                        margin: '0 0 20px 0',
                    }}>
                    <AccountCircleIcon style={{ width: '20%', height: '10vh', margin: '8px 0 0 0' }} />
                    <GridContainer>
                        <GridItem xs={6} style={{ display: 'inline-block', margin: '-63px 0px 0 105px' }}>
                            <h5><strong>Interscope Records</strong></h5>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
                <GridItem xs={11}
                    style={{
                        color: '#fff',
                        backgroundColor: '#f7b7d1',
                        borderRadius: '5px',
                        height: '87px',
                        margin: '0 0 20px 0',
                    }}>
                    <AccountCircleIcon style={{ width: '20%', height: '10vh', margin: '8px 0 0 0' }} />
                    <GridContainer>
                        <GridItem xs={6} style={{ display: 'inline-block', margin: '-63px 0px 0 105px' }}>
                            <h5><strong>Suburban Noize Records</strong></h5>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridItem xs={12} style={{ backgroundColor: '#1c398c', height: '30vh', borderRadius: '5px' }}>
                <h3 style={{ margin: '20px 0 0 15px' }}><strong>Pending Requests</strong></h3>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <GridItem xs={5} style={{ background: '#597fea', borderRadius: '5px', height: '16vh', margin: '20px 0 0 0' }}>
                        <center><AccountCircleIcon style={{ margin: '20px' }} />
                            <p>Atlantic Records</p>
                        </center>
                    </GridItem>
                    <GridItem xs={5} style={{ background: '#597fea', borderRadius: '5px', height: '16vh', margin: '20px 0 0 0' }}>
                        <center><AccountCircleIcon style={{ margin: '20px' }} />
                            <p>Def Jam Records</p>
                        </center>
                    </GridItem>
                </div>
            </GridItem>
        </GridContainer>
    </div>
);

export default LabelsPage;