const cardAvatarStyle = theme => ({
  cardAvatar: {
    "&$cardAvatarProfile img,&$cardAvatarTestimonial img": {
      width: "115%",
      height: "auto"
    }
  },
  cardAvatarProfile: {
    maxWidth: "115%",
    maxHeight: "115%",
    [theme.breakpoints.down("lg")]: {
      margin: "-105vh -40px 0px -40px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "-79vh -40px 0px -40px"
    },
    margin: "-60vh -40px 0px -40px",
    [theme.breakpoints.down("sm")]: {
      margin: "-69vh -40px 0px -40px"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "-60vh -40px 0px -40px"
    },
    overflow: "hidden",
    padding: "0",
    "&$cardAvatarPlain": {
      marginTop: "0"
    }
  },
  cardAvatarPlain: {},
  cardAvatarTestimonial: {
    margin: "-50px auto 0",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    "&$cardAvatarPlain": {
      marginTop: "0"
    }
  },
  cardAvatarTestimonialFooter: {
    marginBottom: "-50px",
    marginTop: "10px"
  }
});

export default cardAvatarStyle;
