// Orders Data
const clientThreads = {
  columns: [
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "DESCRIPTION",
      accessor: "description"
    },
    {
      Header: "IMAGE",
      accessor: "image"
    },
    {
      Header: "LINK",
      accessor: "link"
    },
    {
      Header: "CATEGORY",
      accessor: "category"
    },
    {
      Header: "LINK",
      accessor: "link"
    }
  ],
  dataLoaded: false
};

export const initState = {
  clientThreads
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_CLIENT_THREADS_LIST":
      console.log("SET_CLIENT_THREADS_LIST");
      return {
        ...state,
        clientThreads: {
          ...state.clientThreads,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
    case "GET_CURRENT_CLIENT_THREAD":
      console.log("GET_CURRENT_CLIENT_THREAD");
      return {
        ...state,
        currentClientThread: {
          ...state.currentClientThread,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };

    default:
      return state;
  }
};
