export const initState = {
  following: [],
  recordings: [],
};

export default (state = initState, { type, data }) => {
  switch (type) {
    case 'UPDATE_USER_DATA':
      return { ...state, ...data };
    case 'LOG_OUT':
      return initState;
    default:
      return state;
  }
};
