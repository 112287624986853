import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Assignment from "@material-ui/icons/Assignment";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SearchInput from 'components/SearchInput';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "variables/charts.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class Charts extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ margin: '70px 0 0 0' }}>
        <SearchInput placeholder="Search All Analytics" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: '20px 0 60px 0',
          }}
        >
          <GridItem
            style={{
              color: 'black',
              backgroundColor: '#fff',
              height: '14vh',
              width: '26%',
              borderRadius: '50%',
              border: '2px solid black',
            }}
          >
            <center>
              <h5>
                <strong>$252K</strong>
              </h5>
              <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>
                Monthly Payout Average
              </p>
            </center>
          </GridItem>
          <GridItem
            style={{
              color: 'black',
              backgroundColor: '#fff',
              height: '14vh',
              width: '26%',
              borderRadius: '50%',
              border: '2px solid black',
            }}
          >
            <center>
              <h5>
                <strong>$13.7K</strong>
              </h5>
              <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>
                Average Revenue Per Artist
              </p>
            </center>
          </GridItem>
          <GridItem
            style={{
              color: 'black',
              backgroundColor: '#fff',
              height: '14vh',
              width: '26%',
              borderRadius: '50%',
              border: '2px solid black',
            }}
          >
            <center>
              <h5>
                <strong>1.417M</strong>
              </h5>
              <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>
                Average Streams Per Month
              </p>
            </center>
          </GridItem>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="rose">
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={roundedLineChart.data}
                  type="Line"
                  options={roundedLineChart.options}
                  listener={roundedLineChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Songs Pitched</h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="info">
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={simpleBarChart.data}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Songs Accepted</h4>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="info">
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={simpleBarChart.data}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Songs Declined</h4>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{ backgroundColor: '#8f8f8f' }}>
              <div style={{ margin: '0 0 0 20px' }}>
                <h4 className={classes.cardIconTitle}>Volume Ranked Writers</h4>
                <p style={{ color: '#fff' }}>Based on accepted pitches</p>
              </div>
              <CardBody>
                <Table
                  tableHeaderColor="1c398c"
                  tableHead={['Rank', 'Writer', 'Accepted Pitches']}
                  tableData={[
                    ['1', 'Jada Pinkett Smith', '33 songs'],
                    ['2', 'Glass Animals', '30 songs'],
                    ['3', 'alt-j', '24 songs'],
                    ['4', 'Portugal. the Man', '21 songs'],
                    ['5', 'Cardi B', '20 songs'],
                  ]}
                  coloredColls={[3]}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{ backgroundColor: '#8f8f8f' }}>
              <div style={{ margin: '0 0 0 20px' }}>
                <h4 className={classes.cardIconTitle}>Quality Ranked Writers</h4>
                <p style={{ color: '#fff' }}>
                  Based on acceptence to declined ratio
                </p>
              </div>
              <CardBody>
                <Table
                  tableHeaderColor="1c398c"
                  tableHead={['Rank', 'Writer', 'Ratio']}
                  tableData={[
                    ['1', 'Jada Pinkett Smith', '33:5'],
                    ['2', 'Glass Animals', '30:7'],
                    ['3', 'alt-j', '24:6'],
                    ['4', 'Portugal. the Man', '21:6'],
                    ['5', 'Cardi B', '20:8'],
                  ]}
                  coloredColls={[3]}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ backgroundColor: '#83c965', margin: '20px 0 40px ' }}>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={roundedLineChart.data}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Streaming</h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ backgroundColor: '#1c398c' }}>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={roundedLineChart.data}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Royalty Cost & Overhead</h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{ backgroundColor: '#8f8f8f' }}>
              <div style={{ margin: '0 0 0 20px' }}>
                <h4 className={classes.cardIconTitle}>Payouts By Writer</h4>
              </div>
              <CardBody>
                <Table
                  tableHeaderColor="1c398c"
                  tableHead={['Rank', 'Writer', 'Ratio']}
                  tableData={[
                    ['1', 'Jada Pinkett Smith', '$12,345.67'],
                    ['2', 'Glass Animals', '$8,019.18'],
                    ['3', 'alt-j', '$9,028.12'],
                    ['4', 'Portugal. the Man', '$10,301.47'],
                    ['5', 'Cardi B', '$9,586.88'],
                  ]}
                  coloredColls={[3]}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{ backgroundColor: '#8f8f8f' }}>
              <div style={{ margin: '0 0 0 20px' }}>
                <h4 className={classes.cardIconTitle}>Profits By Writer</h4>
              </div>
              <CardBody>
                <Table
                  tableHeaderColor="1c398c"
                  tableHead={['Rank', 'Writer', 'Ratio']}
                  tableData={[
                    ['1', 'Jada Pinkett Smith', '$12,345.67'],
                    ['2', 'Glass Animals', '$8,019.18'],
                    ['3', 'alt-j', '$9,028.12'],
                    ['4', 'Portugal. the Man', '$10,301.47'],
                    ['5', 'Cardi B', '$9,586.88'],
                  ]}
                  coloredColls={[3]}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ backgroundColor: '#1c398c', margin: '20px 0 40px ' }}>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={roundedLineChart.data}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Streaming Revenue Forecast</h4>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart style={{ backgroundColor: '#1c398c' }}>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={roundedLineChart.data}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Artist Overhead Forecast</h4>
            </CardBody>
          </Card>
        </GridItem>
      </div>
    );
  }
}

export default withStyles(chartsStyle)(Charts);
