import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ScrollBar from 'react-perfect-scrollbar';
import PreviousLyrics from './PreviousLyrics.jsx'


class LyricsPage extends React.Component {

    // componentDidMount() {
    //     if (navigator.platform.indexOf("Win") > -1) {
    //         const ps = new ScrollBar(this.refs.mainPanel);
    //     }
    // }

    state = {
        miniActive: false
    };
    render() {
        // const { classes } = this.props;
        // const mainPanel =
        //     classes.mainPanel +
        //     " " +
        //     cx({
        //         [classes.mainPanelSidebarMini]: this.state.miniActive
        //     });
        return (
            <div>
                <GridContainer>
                    <ScrollBar>
                        <GridItem xs={12} style={{ color: '#f26599', margin: '0 0 0 10px' }}>
                            <h2><b>Select a Genre</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>Alternative</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>Heavy Metal</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>Rock</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>R&B Hip Hop</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>R&B Old School</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>R&B Pop</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>Soul Music</b></h2>
                        </GridItem>
                        <GridItem xs={11} style={{ color: '#fff', background: 'linear-gradient(68deg, rgba(1,191,58,1) 0%, rgba(0,154,4,1) 96%)', margin: '10px', height: '15vh' }}>
                            <h2 style={{ margin: '30px 0 0 30px' }}><b>Techno</b></h2>
                        </GridItem>
                    </ScrollBar>
                </GridContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button
                        style={{
                            width: '40vw',
                            margin: '20px 0 20px 0',
                            height: '60px',
                            border: '1px solid black',
                            fontSize: '15px',
                        }}
                        onClick={(e) => this.handleSubmit(e)}>
                        VIEW PREVIOUS
                        </button>
                    <button
                        style={{
                            width: '40vw',
                            margin: '20px 0 20px 0',
                            height: '60px',
                            border: '1px solid black',
                            fontSize: '15px',
                        }}
                        onClick={(e) => this.handleSubmit(e)}>
                        CONTINUE
                        </button>
                </div>
                <PreviousLyrics />
            </div>
        )
    }
}

export default LyricsPage;