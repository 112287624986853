import React, { useState } from 'react';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// core components
import GridItem from 'components/Grid/GridItem.jsx';
import Loader from 'components/Loader';
import { connect } from 'react-redux';
import FollowedUser from 'components/FollowedUser';
import ProfileViewFollowerdUsers from '../ProfileViewFollowerdUsers';

class SectionCarouselDesktop extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
    };

    const { uid } = this.props.user || {};

    if (this.props.user.following.length < 8) {
      console.log(this.props.user.following.length);
      return uid ? (
        <ProfileViewFollowerdUsers list={this.props.user.following || []} />
      ) : (
        <Loader />
      );
    } else if (this.props.user.following.length >= 8) {
      return uid ? (
        <div>
          <Carousel {...settings}>
            {this.props.user.following.map((item, i) => (
              <div key={`user_avatar_${i}`} style={{ margin: '0' }}>
                <FollowedUser data={item} />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <Loader />
      );
    }
  }
}

const mapStateToProps = (state) => {
  const user = state.profile || {};
  return { user };
};

export default connect(mapStateToProps)(SectionCarouselDesktop);
