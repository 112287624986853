import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import Loader from 'components/Loader';
import LibraryTabs from 'components/CustomTabs/LibraryTabs.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import SearchInput from 'components/SearchInput';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';

// Images
import forest from 'assets/img/arcq/forest.png';
import gameboy from 'assets/img/arcq/gameboy.png';
import FamilyReunion from 'assets/img/arcq/FamilyReunion.jpg';
import Parties from 'assets/img/arcq/Parties.jpg';
import Weather from 'assets/img/arcq/weather.jpg';
import FamilyHeritage from 'assets/img/arcq/FamilyHertiage.jpg';
import Face1 from 'assets/img/faces/face1.jpg';
import Face2 from 'assets/img/faces/face2.jpg';
import Face3 from 'assets/img/faces/face3.jpg';
import Face4 from 'assets/img/faces/face4.jpg';

class PublicList extends React.Component {
  render() {
    const { classes, uid } = this.props;

    return (
      <div>
        {uid ? (
          <div style={{ padding: '0px 20px 0px 20px' }}>
            <GridContainer>
              <GridItem xs={12}>
                <div style={{ margin: '20px 0' }}>
                  <SearchInput
                    placeholder="Search..."
                    onInput={() => null}
                    color="#8bc1a8"
                  />
                </div>
                {/* Private and Public Posts */}
                <div>
                  <LibraryTabs
                    headerColor="primary"
                    tabs={[
                      {
                        tabName: 'All',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridContainer
                                style={{
                                  padding: '15px',
                                  width: '107%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '20px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '20px',
                                    }}
                                    className={classes.title}
                                  >
                                    Public Posts
                                  </h4>
                                </GridItem>

                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Face3}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px',
                                  width: '107%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '20px 0px 70px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 0px 0px',
                                      fontSize: '20px',
                                    }}
                                    className={classes.title}
                                  >
                                    Archived Public Posts
                                  </h4>
                                </GridItem>

                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            color: '#2B2E34',
                                            padding: '2px 5px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            backgroundColor: '#FAEA61',
                                            marginTop: '110px',
                                            fontSize: '14px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Archived</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Face1}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </GridContainer>
                          </div>
                        ),
                      },
                      {
                        tabName: 'Date',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '20px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  Sunday
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{ backgroundColor: 'black' }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{ backgroundColor: 'black' }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face3}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>

                            <GridContainer
                              style={{
                                margin: '0px 0px -16px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '30px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  September 5 2019
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face1}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face4}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face3}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '10px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '10px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face1}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>
                            <GridContainer
                              style={{
                                margin: '0px 0px 50px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '30px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  August 28 2019
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: '/view-post',
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face4}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>
                          </div>
                        ),
                      },
                      {
                        tabName: 'Topic',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Environmentalism
                                  </h4>
                                </GridItem>

                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Environmentalism is a broad philosophy, ideology,
                                    and social movement regarding concerns for
                                    environmental protection and improvement of the
                                    health of the environment.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Trees & Plants</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={forest}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Weather Patterns</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={Weather}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Family Heritage
                                  </h4>
                                </GridItem>
                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Family heritage is the legacy of physical
                                    artifacts and intangible attributes of a family
                                    that is inherited from past generations. Not all
                                    legacies of past generations are "heritage",
                                    rather heritage is a product of selection by
                                    society.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Family Reunions</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={FamilyHeritage}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Traditions</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={FamilyReunion}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 70px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Recreation
                                  </h4>
                                </GridItem>

                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Having fun is an art form. What do you do in your
                                    spare time? Share it with the group.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Parties & Social</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Parties}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: '/view-post',
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Video Games</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={gameboy}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </GridContainer>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

PublicList.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  recordings: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { uid } = state.auth.user || {};
  const { recordings } = state.profile.user || {};
  return { uid, recordings };
};

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(PublicList);
