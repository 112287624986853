import React, { useState, useEffect } from 'react';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { getGPTModels } from 'store/actions/gptActions';
import { updateUser } from 'store/actions/profileActions';
import { displayError } from 'store/actions/errorActions';
import { capitalize } from 'utils';
import { firebase } from 'firebase/fbConfig';
import config from 'config';
import { setScreenName } from 'store/actions/screenNameActions';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LoaderGif from 'assets/img/loader-dots.gif';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import store from 'services/StoreService';

const { defaultOracleResponseLength } = config;
const callable = firebase
  .functions()
  .httpsCallable('generateText', { timeout: 540000 });

const Oracle = ({ uid, type, gptModels, getGPTModels, setScreenName }) => {
  let model;
  const [open, setOpen] = useState(false);
  const [selectedSample, setSelectedSample] = useState('');
  const [length, setLength] = useState(defaultOracleResponseLength);
  const [prompt, setPrompt] = useState('');
  const [callingModel, setCallingModel] = useState(false);
  const [nbOfSamples, setNbOfSamples] = useState(1);
  const [modelSamples, setModelSamples] = useState([]);

  const getModelSamples = async () => {
    try {
      const data = await store.getModelSamples(uid, model.name);
      console.log('Get Model Samples ======');
      console.log(data);
      if (!data) return;
      data.sort((a, b) => b.createdAt - a.createdAt);
      setModelSamples(data);
    } catch (error) {
      console.error(error.toString());
    }
  };

  const submit = async () => {
    try {
      let prompts = [];
      // Simply set the value and exit if it's not a submit event
      if (!prompt) {
        alert('Please enter a prompt');
        return;
      }
      // Get oracles's response
      setCallingModel(true);
      const t0 = Date.now();
      for (let i = 0; i < nbOfSamples; i++) {
        prompts.push(prompt.trim());
      }
      const responses = await Promise.all(
        prompts.map(
          async (text) =>
            await callable({
              text,
              model: model.name,
              length: length || defaultOracleResponseLength,
              samples: 1,
              parallelComputation: false,
            })
        )
      );
      console.log('Gpt call took:', (Date.now() - t0) / 1000, 'seconds');
      const samples = responses.map((e) => e.data.results[0][0]);
      const createdAt = Date.now();
      const entry = { prompt, samples, createdAt };
      await store.addSample(uid, model.name, entry);
      setCallingModel(false);
      // Reset inputs
      setPrompt('');
      setNbOfSamples(1);
      // const { error, results } = response.data;
      // if (error) {
      //   throw new Error(error);
      // }
      console.log(samples);
      setModelSamples([].concat(entry, modelSamples));
      setCallingModel(false);
    } catch (error) {
      setCallingModel(false);
      console.error(error.toString());
    }
  };

  useEffect(() => {
    if (!gptModels) {
      getGPTModels();
    } else if (uid && model) {
      setScreenName(capitalize(model.name));
      getModelSamples();
    }
  }, [uid, gptModels]);

  if (!uid || !gptModels) return <Loader />;

  model = gptModels.find((e) => e.type === type);

  if (!model) return <Redirect to='/not-found' />;

  return (
    <div>
      <GridContainer justify='center'>
        <GridItem xs={12} style={{ textAlign: 'center', marginTop: '60px' }}>
          <TextareaAutosize
            value={prompt}
            aria-label='minimum height'
            rowsMin={3}
            placeholder='Enter prompt'
            onChange={(e) => setPrompt(e.target.value)}
            style={{
              width: '225px',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #CCC',
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify='center' style={{ marginTop: '30px' }}>
        <GridItem>
          <FormControl variant='outlined'>
            <InputLabel id='demo-simple-select-outlined-label'>Nb</InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={nbOfSamples}
              onChange={(e) => setNbOfSamples(e.target.value)}
              label='samples'
            >
              {range(1, 6).map((e) => (
                <MenuItem key={e} value={e}>
                  {e} sample{e > 1 ? 's' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl variant='outlined'>
            <InputLabel id='demo-simple-select-outlined-label'>
              Length
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={length}
              onChange={(e) => setLength(e.target.value)}
              label='length'
            >
              {range(100, 600, 100).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          xs={12}
          style={{
            textAlign: 'center',
            marginTop: '30px',
          }}
        >
          {callingModel ? (
            <div>
              <img src={LoaderGif} style={{ width: '30px' }} alt='...' />
            </div>
          ) : (
            <Button
              variant='outlined'
              disableElevation
              style={{ color: '#319874' }}
              onClick={submit}
            >
              Submit
            </Button>
          )}
        </GridItem>
      </GridContainer>
      {!!modelSamples.length && (
        <div>
          <GridContainer justify='center' style={{ marginTop: '20px' }}>
            <GridItem>Click to see your samples</GridItem>
          </GridContainer>
          {modelSamples.map((entry) => (
            <GridContainer
              key={entry.prompt}
              justify='center'
              style={{ padding: '20px 0 20px 0' }}
            >
              <GridItem
                xs={12}
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                  color: '#319874',
                }}
              >
                {entry.prompt}
              </GridItem>
              {(entry.samples || []).map((e, i) => (
                <GridItem key={i}>
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      paddingTop: '15px',
                      textAlign: 'center',
                      fontSize: '20px',
                      background: '#DBDBDB',
                      borderRadius: '50px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpen(true);
                      setSelectedSample(e);
                    }}
                  >
                    {i + 1}
                  </div>
                </GridItem>
              ))}
            </GridContainer>
          ))}
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedSample('');
        }}
      >
        <div
          style={{
            padding: '20px',
          }}
        >
          {selectedSample}
        </div>
      </Dialog>
    </div>
  );
};

Oracle.propTypes = {
  uid: PropTypes.string,
  type: PropTypes.string.isRequired,
  gptModels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  events: PropTypes.array,
  updateUser: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
  getGPTModels: PropTypes.func.isRequired,
  setScreenName: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps.match.params;
  const { gptModels, profile } = state;
  const { uid, events } = profile || {};
  return { uid, events, type, gptModels };
};

export default connect(mapStateToProps, {
  updateUser,
  getGPTModels,
  displayError,
  setScreenName,
})(Oracle);
