import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from 'components/Card/Card.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';

const UserVideoList = ({ data, classes }) => (
  <div style={{ padding: '0px 35px 0px 0px', margin: '0 0 0 15px' }}>
    <GridContainer
      style={{
        margin: '10px 10px 0 -35px',
        width: '100%',
        padding: '0px 0px 0px 3%',
      }}
    >
      {data.map((video) => (
        <GridItem lg={3} md={3} sm={4} xs={4} key={video.id}>
          <Link
            to={{
              pathname: `/view-post/${video.id}`,
            }}
          >
            <Card
              style={{
                backgroundColor: 'black',
                marginBottom: '0px',
              }}
              className={classes.textWhite}
            >
              <div className={classes.cardImgOverlay}>
                <p
                  style={{
                    marginTop: '-1px',
                    fontSize: '16px',
                    color: '#FFFFFF',
                  }}
                  className={classes.bodyText}
                >
                  {video.topic}
                </p>
              </div>
              <div
                style={{
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  borderRadius: '5px',
                }}
              >
                <video
                  style={{
                    opacity: '.6',
                    width: '192%',
                    margin: '0px 0px -6px -47%',
                    height: '120px',
                    borderRadius: '0px',
                  }}
                  className={classes.cardImg}
                  data-holder-rendered="true"
                >
                  <source type="video/mp4" src={video.url} />
                </video>
              </div>
            </Card>
          </Link>
        </GridItem>
      ))}
      {/* SNIPPET UserVideoList.jsx_1 */}
    </GridContainer>
  </div>
);

UserVideoList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(UserVideoList);
