import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// core components
import Loader from 'components/Loader';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/actionsStyle';

class Notifications extends React.Component {
  render() {
    const { classes, uid } = this.props;

    return uid ? (
      <div>
        <GridContainer style={{ padding: '60px 20px' }}>
          <GridItem xs={12} sm={12} md={12}>
            <div>
              <SnackbarContent
                message={
                  <span>
                    <b>INFO ALERT:</b> You've got some friends nearby, stop looking
                    at your phone and find them...
                  </span>
                }
                close
                color="info"
                icon={InfoOutlinedIcon}
              />
              <SnackbarContent
                message={
                  <span>
                    <b>SUCCESS ALERT:</b> You've got some friends nearby, stop
                    looking at your phone and find them...
                  </span>
                }
                close
                color="success"
                icon={Check}
              />
              <SnackbarContent
                message={
                  <span>
                    <b>WARNING ALERT:</b> You've got some friends nearby, stop
                    looking at your phone and find them...
                  </span>
                }
                close
                color="warning"
                icon={Warning}
              />
              <SnackbarContent
                message={
                  <span>
                    <b>DANGER ALERT:</b> You've got some friends nearby, stop looking
                    at your phone and find them...
                  </span>
                }
                close
                color="rose"
                icon={InfoOutlinedIcon}
              />
              <Clearfix />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => {
  const { uid, events } = state.profile || {};
  return { uid, events };
};

Notifications.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ),
};

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Notifications);
