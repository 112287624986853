import React from 'react';

const NotFound = () => (
  <div
    style={{
      color: 'white',
      fontSize: '30px',
      width: '100%',
      marginTop: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
    }}
  >
    Not Found
  </div>
);

export default NotFound;
