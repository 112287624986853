import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import BioSummary from 'components/BioSummary';
import Hidden from '@material-ui/core/Hidden';

export const ProfileViewBioSummary = ({ user, editable }) => (
  <GridContainer style={{ margin: '0px ' }}>
    {/* Desktop */}
    <Hidden xsDown implementation='css'>
      <GridItem
        xs={12}
        style={{
          margin: editable ? '-40% 0% 0% 0%' : '-40% 0% 0% 0%',
        }}
      >
        <BioSummary user={user} editable={editable} />
      </GridItem>
    </Hidden>
    {/* Mobile */}
    <Hidden smUp implementation='css'>
      <GridItem
        xs={12}
        style={{
          margin: editable ? '-112% -75% 8% -2%' : '-112% -75% 8% -2%',
          height: '222px',
          maxWidth: '174%',
          background:
            'linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(107,199,66,0) 100%)',
        }}
      >
        <BioSummary user={user} editable={editable} />
      </GridItem>
    </Hidden>
  </GridContainer>
);

ProfileViewBioSummary.propTypes = {
  user: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default ProfileViewBioSummary;
