import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.jsx';
import store from 'services/StoreService';
import Storage from 'services/StorageService';
import config from 'config';
import { buildAvatarPath } from 'utils';
import { firebase } from 'firebase/fbConfig';
import LoaderGif from 'assets/img/loader-dots.gif';

const PictureUpload = ({ uid, onSuccess, onError, currentPic }) => {
  let callable;

  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState(currentPic);

  const onSelect = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!config.picAllowedExt.includes(fileExtension)) {
      onError(
        `Invalid file: only ${config.picAllowedExt.join(', ')} files are allowed`
      );
      return;
    }
    reader.onloadend = () => {
      const imagePreview = reader.result;
      const avatar = `${uid}.${fileExtension}`;
      setFile(file);
      setAvatar(avatar);
      setImagePreview(imagePreview);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    if (uploading) return;
    setUploading(true);
    Storage.uploadToStorage({
      data: file,
      onError,
      onSuccess: onPicUploaded,
      destination: `${config.avatarStorageFolder}/${avatar}`,
    });
  };

  const onPicUploaded = async () => {
    try {
      callable = callable || firebase.functions().httpsCallable('squareImage');
      const { type } = file;
      const name = avatar;
      const storageFolder = config.avatarStorageFolder;
      const squared = await callable({ name, type, storageFolder });
      if (!squared) {
        throw new Error('The image could not be resized. Please try again.');
      }
      const avatarPath = buildAvatarPath(avatar);
      await store.updateAvatar(uid, avatarPath);
      onSuccess(avatarPath);
      setImagePreview(avatarPath);
      setUploading(false);
      setFile(null);
    } catch (error) {
      setFile(null);
      setUploading(false);
      setImagePreview(currentPic);
      onError(
        'An error ocurred during the uploading of the file (file too large?). Please try again later'
      );
    }
  };

  return (
    <div
      style={{ backgroundColor: 'rgb(43, 79, 92)' }}
      className="picture-container"
    >
      <div className="picture">
        <img src={imagePreview} className="picture-src" alt="Your avatarr" />
        <input type="file" onChange={onSelect} />
      </div>
      {uploading ? (
        <img
          style={{
            marginTop: '-100px',
            width: '70px',
            padding: '0 10px',
            background: '#50817A',
            borderRadius: '10px',
          }}
          src={LoaderGif}
          alt="uploading..."
        />
      ) : file ? (
        <Button
          style={{
            margin: '-100px 0px 0px 0px',
            boxShadow:
              '0 2px 2px 0 rgba(156, 39, 176, 0), 0 3px 1px -2px rgba(156, 39, 176, 0)',
          }}
          color="primary"
          onClick={handleSubmit}
        >
          UPDATE
        </Button>
      ) : (
        <h6 className="description">Choose Picture</h6>
      )}
    </div>
  );
};

PictureUpload.propTypes = {
  onSuccess: () => null,
};

PictureUpload.propTypes = {
  uid: PropTypes.string.isRequired,
  currentPic: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PictureUpload;
