// _TODO: Store the video blob only once the use has confirmed
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RecordVideo from './RecordVideo';
import RecordConfirm from './RecordConfirm';
import { updateUser } from 'store/actions/profileActions';
import Loader from 'assets/img/preloader.gif';
import store from 'services/StoreService';
import Storage from 'services/StorageService';
import userEvents from 'config/user_events';

const Record = ({ history, updateUser, user, uid }) => {
  const [videoData, setVideoData] = useState({});
  const [uploadProgress, setUploadProgress] = useState(false);
  const [waitForConfirmation, setWaitForConfirmation] = useState(false);

  const onVideoUploaded = (url, videoData) => {
    setVideoData({ ...videoData, url });
    setUploadProgress(null);
    setWaitForConfirmation(true);
  };

  const saveVideo = (videoBlob, videoData) => {
    const timestamp = new Date().getTime().toString();
    Storage.uploadToStorage({
      data: videoBlob,
      onSuccess: (url) => onVideoUploaded(url, videoData),
      destination: `userVideos/${uid}${timestamp}`,
      onProgress: (v) => v && setUploadProgress(`${parseInt(v * 100, 10)} %`),
    });
  };

  const onConfirm = async (description) => {
    const createdAt = new Date().getTime();
    // Add recording to db
    const entry = await store.addRecording(uid, { ...videoData, description });
    // Credit user with token for adding a post
    const event = userEvents.find((e) => e.event === 'private-post');
    const data = { ...event, data: entry.id, createdAt };
    const events = (user.events || []).concat(data);
    // Add event to db
    await store.updateUser(uid, { events });
    // Append new entry to the recodings entry in the app state
    const recordings = [].concat(user.recordings || [], entry);
    // Add recording ans event to app state
    updateUser({ recordings, events });
    history.push('/profile');
  };

  if (uploadProgress) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
      >
        <div
          style={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            position: 'absolute',
          }}
        >
          {uploadProgress}
        </div>
        <img style={{ width: '20%' }} src={Loader} alt="Loading..." />
      </div>
    );
  }

  if (!waitForConfirmation) {
    return <RecordVideo uid={uid} onSubmit={saveVideo} />;
  }

  return (
    <RecordConfirm
      onStartOver={() => setWaitForConfirmation(false)}
      onConfirm={onConfirm}
      videoData={videoData}
    />
  );
};

const mapStateToProps = ({ profile }) => {
  const { uid, recordings, events } = profile;
  return { uid, user: { recordings, events } };
};

Record.propTypes = {
  uid: PropTypes.string,
  user: PropTypes.shape({
    events: PropTypes.array,
    recordings: PropTypes.array,
  }).isRequired,
  history: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { updateUser }
)(Record);
