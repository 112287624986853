const commentStyle = {
  textField: {
    width: '80%',
    fontWeight: 500,
    color: 'white',
    background: '#5A626A',
  },
  input: {
    color: 'white',
  },
  listText: {
    color: 'white',
  },
};

export default commentStyle;
