// Orders Data
const cases = {
  columns: [
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "DESCRIPTION",
      accessor: "description"
    },
    {
      Header: "IMAGE",
      accessor: "image"
    },
    {
      Header: "LINK",
      accessor: "link"
    },
    {
      Header: "CATEGORY",
      accessor: "category"
    },
    {
      Header: "LINK",
      accessor: "link"
    }
  ],
  dataLoaded: false
};

export const initState = {
  cases
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_CASES_LIST":
      console.log("SET_CASES_LIST");
      return {
        ...state,
        cases: {
          ...state.cases,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
    case "GET_CURRENT_CASE":
      console.log("GET_CURRENT_CASE");
      return {
        ...state,
        currentCase: {
          ...state.currentCase,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };

    default:
      return state;
  }
};
