import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'react-router-dom';

export const ButtonSendPost = () => (
  <div>
    {/* Desktop */}
    <Hidden xsDown implementation='css'>
      <Link
        to={{
          pathname: `/record`,
        }}
      >
        <Button
          style={{
            margin: '-80% 0% 0% 35%',
            borderRadius: '50px',
            boxShadow: 'none',
            backgroundColor: '#6ABC43',
          }}
          color='primary'
        >
          <b>
            Send Post
            <LabelImportantIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '17px',
                height: '17px',
              }}
            />
          </b>
        </Button>
      </Link>
    </Hidden>
    {/* Mobile */}
    <Hidden smUp implementation='css'>
      <Link
        to={{
          pathname: `/record`,
        }}
      >
        <Button
          style={{
            margin: '-120% 0% 0% 10%',
            borderRadius: '50px',
            boxShadow: 'none',
            backgroundColor: '#6ABC43',
          }}
          color='primary'
        >
          <b>
            Send Post
            <LabelImportantIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '17px',
                height: '17px',
              }}
            />
          </b>
        </Button>
      </Link>
    </Hidden>
  </div>
);

export default ButtonSendPost;
