import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { db } from 'firebase/fbConfig.js';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import styles from 'assets/jss/material-dashboard-pro-react/components/ActionSubmitFormStyle.jsx';

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false,
    defaultLoaded: false,
  };
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let dataObj = {
      status: 'confirmed',
      updatedAt: new Date(),
    };

    dataObj = this.state.comment !== '' && {
      ...dataObj,
      comment: this.state.comment,
    };

    db.collection('TEST-actions-list')
      .doc(this.props.actionID)
      .set(dataObj, { merge: true })
      .then(() => {
        console.log(`Update TEST-actions-list with id: ${this.props.actionID}`);
        this.setState({
          comment: '',
          messageSent: true,
        });
      })
      .catch((err) => {
        console.log(
          `Update TEST-actions-list with id: ${this.props.actionID} with error: ${err}`
        );
      });
  };

  resetRequest = () => {
    window.location.reload();
  };
  setDefaults = () => {
    console.log('SET_FORM_DEFAULTS');
    this.setState({
      comment: this.props.comment ? this.props.comment : '',
      defaultLoaded: true,
    });
  };
  resetForm = () => {
    this.setState({
      comment: '',
      defaultLoaded: false,
    });
  };

  render() {
    const { classes, modalClose } = this.props;

    // reset modal when closed
    if (modalClose && this.state.messageSent) {
      this.resetRequest();
    }
    if (modalClose && this.state.defaultLoaded) {
      this.resetForm();
    }
    if (!modalClose && !this.state.defaultLoaded) {
      this.setDefaults();
    }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify='center'>
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Action Confirmed!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify='center'>
                <GridItem xs={11}>
                  <h5 className={classes.bodyText}>
                    Update comment and confirm?
                  </h5>
                  {this.state.messageError ? (
                    <h5 className={classes.bodyText}>
                      There Has Been An Error
                    </h5>
                  ) : null}
                  <p
                    className={classes.bodyText}
                    style={{
                      marginBottom: '0',
                      marginTop: '20px',
                    }}
                  >
                    Comment
                  </p>
                  <CustomInput
                    id='comment'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      multiline: true,
                      rows: 5,
                      value: this.state.comment,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                type='submit'
                color='primary'
                style={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                }}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
