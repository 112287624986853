import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from "components/Card/Card.jsx";
import PendingArtistsCarousel from './Sections/PendingArtistsCarousel.jsx';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AcceptanceConfirmation from './AcceptanceConfirmation.jsx';

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export const PitchesPage = () => {
    return (
        <div>
            <h4><b>Pending Artists</b></h4>
            <PendingArtistsCarousel />
            <GridContainer style={{ background: 'linear-gradient(177deg, rgba(250,36,127,1) 0%, rgba(207,1,165,1) 96%)', color: '#fff', margin: '50px 0 0 -15px', padding: '20px 0 0 0' }}>
                <h4 style={{ margin: '0 0 10px 20px' }}><b>NEW SONG IDEAS</b></h4>
                {/* Map over each grid item once pulling from api */}
                <GridItem xs={12}>
                    <Card style={{
                        background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)', height: '17vh', width: '95vw', margin: '0 0 15px 7px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <AccountCircleIcon style={{ width: '30vw', height: '15vh' }} />
                            <AudioPlayer
                                src="https://www.computerhope.com/jargon/m/example.mp3"
                                onPlay={(e) => console.log('onPlay')}
                                style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                                header='Now Playing: Example'
                            />
                            <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>     
                                <Select
                                >
                                    <MenuItem value={'favorite'}>Favorite</MenuItem>
                                    <MenuItem value={'reject'}>Reject</MenuItem>
                                    <MenuItem value={'accept'}>Accept</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <Card style={{
                        background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)', height: '17vh', width: '95vw', margin: '0 0 15px 7px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <AccountCircleIcon style={{ width: '30vw', height: '15vh' }} />
                            <AudioPlayer
                                src="https://www.computerhope.com/jargon/m/example.mp3"
                                onPlay={(e) => console.log('onPlay')}
                                style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                                header='Now Playing: Example'
                            />
                            <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>
                                <Select
                                >
                                    <MenuItem value={'favorite'}>Favorite</MenuItem>
                                    <MenuItem value={'reject'}>Reject</MenuItem>
                                    <MenuItem value={'accept'}>Accept</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <Card style={{
                        background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)', height: '17vh', width: '95vw', margin: '0 0 15px 7px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <AccountCircleIcon style={{ width: '30vw', height: '15vh' }} />
                            <AudioPlayer
                                src="https://www.computerhope.com/jargon/m/example.mp3"
                                onPlay={(e) => console.log('onPlay')}
                                style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                                header='Now Playing: Example'
                            />
                            <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>
                                <Select
                                >
                                    <MenuItem value={'favorite'}>Favorite</MenuItem>
                                    <MenuItem value={'reject'}>Reject</MenuItem>
                                    <MenuItem value={'accept'}>Accept</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer >
                <GridItem xs={12} style={{ backgroundColor: '#1c398c' }}>
                    <h4 style={{ margin: '20px 0 10px 20px', color: '#fff' }}><b>FAVORITES</b></h4>
                    <Card style={{
                        background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)', height: '17vh', width: '95vw', margin: '0 0 15px 7px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <AccountCircleIcon style={{ width: '30vw', height: '15vh' }} />
                            <AudioPlayer
                                src="https://www.computerhope.com/jargon/m/example.mp3"
                                onPlay={(e) => console.log('onPlay')}
                                style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                                header='Now Playing: Example'
                            />
                            <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>
                                <Select
                                >
                                    <MenuItem value={'run-calculation'}>Run Calculation</MenuItem>
                                    <MenuItem value={'reject'}>Reject</MenuItem>
                                    <MenuItem value={'accept'}>Accept</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Card>
                    <Card style={{
                        background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)', height: '17vh', width: '95vw', margin: '0 0 15px 7px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <AccountCircleIcon style={{ width: '30vw', height: '15vh' }} />
                            <AudioPlayer
                                src="https://www.computerhope.com/jargon/m/example.mp3"
                                onPlay={(e) => console.log('onPlay')}
                                style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                                header='Now Playing: Example'
                            />
                            <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>
                                <Select
                                >
                                    <MenuItem value={'run-calculation'}>Run Calculation</MenuItem>
                                    <MenuItem value={'reject'}>Reject</MenuItem>
                                    <MenuItem value={'accept'}>Accept</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Card>
                </GridItem>
            </GridContainer>
            <AcceptanceConfirmation />
        </div>
    )
}

export default PitchesPage;