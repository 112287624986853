import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';

export const LibraryByDate = ({ classes, data }) => (
  <div style={{ padding: '0px 0px 15px 0px' }}>
    {Object.keys(data).map((key) => (
      <GridContainer
        key={key}
        style={{
          margin: '10px 0px -24px 0px',
          width: '100%',
          padding: '0px 0px 0px 0px',
        }}
      >
        <GridItem xs={12}>
          <h3
            style={{
              margin: '20px 0px 0px 0px',
              fontSize: '22px',
            }}
            className={classes.title}
          >
            {key}
          </h3>
        </GridItem>
        {data[key].map((e) => (
          <GridItem key={e.id} xs={4}>
            <Link
              to={{
                pathname: `/view-post/${e.id}`,
              }}
            >
              <Card
                style={{ backgroundColor: 'black' }}
                className={classes.textWhite}
              >
                <div className={classes.cardImgOverlay}>
                  {e.type === 'archived' && (
                    <h4
                      style={{
                        color: '#2B2E34',
                        padding: '2px 5px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        backgroundColor: '#FAEA61',
                        marginTop: '45px',
                        fontSize: '14px',
                      }}
                      className={classes.bodyText}
                    >
                      <strong>Archived</strong>
                    </h4>
                  )}
                </div>
                <div>
                  <video className={classes.feedImg} data-holder-rendered="true">
                    <source type="video/mp4" src={e.url} />
                  </video>
                </div>
              </Card>
            </Link>
          </GridItem>
        ))}
      </GridContainer>
    ))}
  </div>
);

LibraryByDate.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(LibraryByDate);
