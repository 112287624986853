import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SearchInput from 'components/SearchInput';
import RoundedBox from 'components/BoxRounded';
import FollowerList from 'components/FollowerList';
import store from 'services/StoreService';
import Style from 'assets/jss/material-dashboard-pro-react/views/actionsStyle';

import { updateUser } from 'store/actions/profileActions';

const Followers = ({ items, uid, updateUser }) => {
  const [searchResults, setSearchResults] = useState(items);

  useEffect(
    () => {
      setSearchResults(items);
    },
    [items]
  );

  const blockAccess = async (fuid) => {
    // Remove the followed user with this id
    const following = items.filter((e) => e.id !== fuid);
    // Update the db
    await store.updateFollowedUsers(uid, following);
    // Update the app state
    updateUser({ following });
  };

  const setAccess = async (fuid, value) => {
    // Update the access key for that followed user
    const list = items.map((e) => (e.id !== fuid ? e : { ...e, access: value }));
    // Update the db
    await store.updateFollowedUsers(uid, list);
    // Update the app state
    updateUser({ following: list });
  };

  const getFollowers = async (input) => {
    if (!input) {
      setSearchResults(items);
      return;
    }
    const results = [];
    const reg = new RegExp(input, 'i');
    items.forEach((e) => {
      if (
        !reg.test(e.firstName) &&
        !reg.test(e.lastName) &&
        !reg.test(e.displayName)
      )
        return;
      results.push(e);
    });
    setSearchResults(results);
  };

  return (
    <div>
      <GridContainer justify="center" style={{ marginBottom: '60px' }}>
        <GridItem xs={12}>
          <GridContainer
            justify="center"
            style={{
              marginTop: '10px',
              marginBottom: '0px',
              width: '100%',
              padding: '0px 0% 0px 6%',
            }}
          >
            {[
              { number: items.length, title: 'Following' },
              { number: 0, title: 'Followers' },
            ].map((e, i) => (
              <GridItem key={i} xs={6}>
                <RoundedBox title={e.number.toString()} subtitle={e.title} />
              </GridItem>
            ))}
          </GridContainer>
          {/* SEARCH INPUT*/}
          <GridContainer>
            <GridItem xs={11} style={{ margin: '18px 47px 18px 25px' }}>
              <SearchInput
                color="#91BFA7"
                onInput={getFollowers}
                placeholder="Search..."
              />
            </GridItem>
          </GridContainer>
          {/* PRIVATE AND PUBLIC FRIENDS */}
          <div>
            <FollowerList
              followers={searchResults}
              setAccess={setAccess}
              blockAccess={blockAccess}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Followers.propTypes = {
  uid: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      access: PropTypes.string,
    })
  ),
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { following, uid } = state.profile || {};
  return { items: following || [], uid };
};

export default compose(
  connect(
    mapStateToProps,
    { updateUser }
  ),
  withStyles(Style)
)(Followers);
