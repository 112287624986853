import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';


export const ResourcesPage = () => {
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} style={{ color: '#f26599', margin: '0 0 0 15px' }}>
                    <h2><b>Add New Resource</b></h2>
                    <h5>Recording Info</h5>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <AttachFileIcon style={{ backgroundColor: '#f26599', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0', transform: 'rotate(330deg)' }} />
                    <h5>Audio</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="file"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ textAlign: 'right' }}>
                    <h5>Song Title</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ textAlign: 'right' }}>
                    <h5>Group Name</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AttachFileIcon style={{ backgroundColor: '#f26599', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0', transform: 'rotate(330deg)' }} />
                    <h5>Lyrics</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="file"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                {/* Get clarification on how the genres and tags forms should work, dropdown menu on open? */}
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AddIcon style={{ backgroundColor: '#55338b', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0' }} />
                    <h5>Genres</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AddIcon style={{ backgroundColor: '#55338b', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0' }} />
                    <h5>Tags</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ textAlign: 'right' }}>
                    <h5>Comments</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ textAlign: 'right' }}>
                    <h5>A&R Agents</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={12} style={{ color: '#f26599', margin: '0 0 0 15px' }}>
                    <h5>Additional Files</h5>
                </GridItem>
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AttachFileIcon style={{ backgroundColor: '#f26599', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0', transform: 'rotate(330deg)' }} />
                    <h5>Art</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="file"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AttachFileIcon style={{ backgroundColor: '#f26599', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0', transform: 'rotate(330deg)' }} />
                    <h5>Misc.</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="file"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={12} style={{ color: '#f26599', margin: '0 0 0 15px' }}>
                    <h5>Rights Info</h5>
                </GridItem>
                <GridItem xs={4} style={{ textAlign: 'right' }}>
                    <h5>Date</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="date"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
                <GridItem xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AddIcon style={{ backgroundColor: '#55338b', color: '#fff', borderRadius: '50%', margin: '10px 10px 0 0' }} />
                    <h5>Writers</h5>
                </GridItem>
                <GridItem xs={8}>
                    <input type="text"
                        // name={service}
                        // value={item.songTitle}
                        // onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: '#000', width: '60vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </GridItem>
            </GridContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                <button
                    style={{
                        width: '40vw',
                        margin: '20px 0 20px 0',
                        height: '60px',
                        border: '1px solid black',
                        fontSize: '15px',
                    }}
                    onClick={(e) => this.handleSubmit(e)}>
                    CLEAR
                </button>
                <button
                    style={{
                        width: '40vw',
                        margin: '20px 0 20px 0',
                        height: '61px',
                        border: '2px solid #fff',
                        fontSize: '15px',
                        color: '#fff',
                        backgroundColor: '#6abc43'
                    }}
                    onClick={(e) => this.handleSubmit(e)}>
                    ADD RESOURCE
                </button>
            </div>
        </div>
    )
}

export default ResourcesPage;