import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from "components/Table/Table.jsx";
import prince from "assets/img/purple-rain.jpg";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ShareDetails from './ShareDetails.jsx'

export const SharePage = () => {
    return (
        <div>
            <GridContainer style={{
                color: '#fff',
                display: 'flex',
                justifyContent: 'space-around',
                height: '20vh',
                margin: '30px 0px 0 -15px'
            }}>
                <GridItem
                    style={{
                        color: '#fff',
                        background: 'linear-gradient(180deg, rgba(255,69,149,1) 0%, rgba(210,59,198,1) 96%)',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 0 0 20px',
                    }}
                >
                    <center>
                        <ArrowDropDownIcon
                            style={{ color: '#fff', margin: '0 0 -13px 0' }}
                        />
                        <h5>
                            <strong>10.1K</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Listens</p>
                    </center>
                </GridItem>
                <GridItem
                    style={{
                        color: '#fff',
                        background: 'linear-gradient(180deg, rgba(255,69,149,1) 0%, rgba(210,59,198,1) 96%)',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 0 0 0',
                    }}
                >
                    <ArrowDropUpIcon
                        style={{ color: '#fff', margin: '0 0 -13px 31px' }}
                    />
                    <center>
                        <h5>
                            <strong>7.3K</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Passes</p>
                    </center>
                </GridItem>
                <GridItem
                    style={{
                        color: '#fff',
                        background: 'linear-gradient(180deg, rgba(255,69,149,1) 0%, rgba(210,59,198,1) 96%)',
                        height: '13vh',
                        width: '23%',
                        borderRadius: '50%',
                        margin: '24px 20px 0 0'
                    }}
                >
                    <ArrowDropDownIcon
                        style={{ color: '#fff', margin: '0 0 -13px 31px' }}
                    />
                    <center>
                        <h5>
                            <strong>3.4K</strong>
                        </h5>
                        <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Saves</p>
                    </center>
                </GridItem>
            </GridContainer>
            <GridItem xs={12} style={{ backgroundColor: '#223380', margin: '30px 0 0 0', borderRadius: '10px' }}>
                <Table
                    tableHead={['Ranking', 'Song Title', 'A&R Agent', 'Passes', 'Favorites']}
                    tableData={[
                        ['1', 'Yesterday Don/t...', 'John Smith', '1.23K', '1.78K'],
                        ['2', 'Forgiven', 'John Smith', '1.23K', '1.78K'],
                        ['3', 'Set Me Free', 'John Smith', '1.23K', '1.78K'],
                        ['4', 'Cruel Intentions', 'John Smith', '1.23K', '1.78K'],
                        ['5', 'Reckoning', 'Emily Jacobs', '1.23K', '1.78K'],
                        ['6', 'Worship Me', 'John Smith', '1.23K', '1.78K'],
                        ['7', 'Meant To Go', 'Emily Jacobs', '1.23K', '1.78K'],
                    ]}
                />
            </GridItem>
            <GridContainer style={{
                background: 'linear-gradient(0deg, rgba(96,165,62,1) 0%, rgba(145,207,118,1) 100%)',
                color: '#fff',
                padding: '20px',
                margin: '20px 0 0 -13px'
            }}>
                <h3 style={{ margin: '0 0 0 20px' }}><b>Full Discography</b></h3>
                <GridItem xs={8} style={{ margin: '20px 0 0 20px', display: 'flex' }}>
                    <img
                        src={prince}
                        style={{
                            height: '118px',
                        }}
                    />
                    <GridContainer style={{ margin: '-15px 0 0 30px' }}>
                        <h4><b>Wicked Wisdom</b></h4>
                        <p>Produced by Ulrich Wild, Jay Baumgardner, Pocket Honore, and Will Smith</p>
                    </GridContainer>
                </GridItem>
                <GridItem xs={3}>
                    <GridContainer style={{ margin: '5px 0 0 15px' }}>
                        <h4><b>Feedback</b></h4>
                        <p>This is where feedback ...</p>
                        <button
                            style={{ border: '1px solid black' }}>Read More</button>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridItem xs={12}
                style={{
                    color: '#fff',
                    background: 'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
                    borderRadius: '5px',
                }}
            >
                <h2><b>Accepted Songs</b></h2>
                <Table
                    tableHead={['Date', 'Song']}
                    tableData={[
                        ['03/01/20', 'You Cant Handle'],
                        ['07/22/20', 'One'],
                        ['09/09/20', 'Saving Time'],
                        ['09/12/20', 'Dont Hate Me'],
                    ]}
                />
            </GridItem>
            <ShareDetails />
        </div>
    )
}

export default SharePage;