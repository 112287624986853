import { firebase, googleAuthProvider } from 'firebase/fbConfig.js';

class AuthService {
  emailSignup = async ({ email, password }) => {
    return await firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  googleSignin = async () => {
    return await firebase.auth().signInWithPopup(googleAuthProvider);
  };

  logout = async () => {
    await firebase.auth().signOut();
  };
}

const instance = new AuthService();
Object.freeze(instance);

export default instance;
 