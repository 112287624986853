import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Loader from 'assets/img/preloader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/actionsStyle';
import { Grid } from '@material-ui/core';

// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';
import { startSetCasesList } from 'store/actions/casesActions';
import avatar from 'assets/img/faces/user.png';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color='primary'
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  // filterData = (query, casesData) => {
  //     if (casesData) {
  //       var newArray = casesData.filter(function (el) {
  //         return el.clientFirstName.includes(query)
  //       });
  //       console.log(newArray);
  //           this.setState({
  //             filteredCasesData: newArray,
  //             dataFiltered:
  //           })

  //     }

  // }

  render() {
    const { AuthBool, classes, cases, query, image } = this.props;
    //console.log(cases.dataRows)
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    let filteredCasesData = [];
    if (cases.dataRows) {
      var newArray = cases.dataRows.filter(function (el) {
        return (
          el.clientFirstName.includes(query) ||
          el.clientFirstName.toLowerCase().includes(query) ||
          el.clientLastName.includes(query) ||
          el.clientLastName.toLowerCase().includes(query) ||
          el.caseType.includes(query) ||
          el.caseType.toLowerCase().includes(query) ||
          el.serviceNeeded.includes(query) ||
          el.serviceNeeded.toLowerCase().includes(query) ||
          el.summary.includes(query) ||
          el.summary.toLowerCase().includes(query) ||
          el.id.includes(query)
        );
      });
      console.log(newArray);
      filteredCasesData = newArray;
    }
    // if(cases) {this.filterData(query, cases.dataRows)}
    //console.log(cases.dataRows)

    return (
      <div>
        {this.state.dataLoaded ? (
          <div>
            {filteredCasesData &&
              filteredCasesData.map((caseRow) => {
                return (
                  <div>
                    <Card>
                      <Link
                        to={{
                          pathname: `/casechat/${caseRow.id}`,
                          state: { dataLoaded: false },
                        }}
                      >
                        <CardHeader>
                          <GridContainer>
                            <GridItem xs={2} sm={2} md={2}>
                              <img
                                src={avatar}
                                style={{
                                  borderRadius: '50%',
                                  height: '50px',
                                  marginTop: '8px',
                                  marginLeft: '-5px',
                                }}
                                alt='...'
                              />
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>
                              <h5 style={{ color: 'black', marginTop: '-2px' }}>
                                <b>
                                  <span style={{ fontSize: '16px' }}>
                                    {caseRow.clientFirstName}{' '}
                                    {caseRow.clientLastName}{' '}
                                  </span>
                                  -{' '}
                                  <span style={{ fontSize: '14px' }}>
                                    Case #{caseRow.id.slice(0, 5)}
                                  </span>
                                </b>
                                <br />
                              </h5>
                              <p
                                style={{
                                  color: '#333',
                                  lineHeight: '14px',
                                  fontSize: '12px',
                                  marginTop: '-10px',
                                  marginBottom: '0px',
                                }}
                              >
                                Case Type: {caseRow.caseType}
                                <br />
                                Service Needed: {caseRow.caseType}
                                <br />
                                Listed{' '}
                                {moment(caseRow.createdDate.toDate()).format(
                                  'MM-DD-YYYY'
                                )}
                              </p>
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                              <MoreVertIcon
                                style={{
                                  color: '#aaa',
                                  marginTop: '20px',
                                  fontSize: '32px',
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardHeader>
                      </Link>
                      {/*<CardBody>
                                           <Link to={{
                    pathname: `/case/${caseRow.id}`,
                    state: { dataLoaded: false }
                  }}>

                        <p ><span style={{color: "black"}}>Case Type:</span> <span style={{fontWeight: "900", color:"rgb(14, 178, 216)", backgroundColor: "#f7f3f0"}}>{caseRow.caseType}</span> </p>
                        <p><span style={{color: "black"}}>Service Needed:</span>  <span style={{fontWeight: "900", color:"rgb(14, 178, 216)"}}>{caseRow.serviceNeeded}</span></p>
                                             </Link>   

                      <Accordion
                        active={1}
                        collapses={[
                          {
                            title: "Summary",
                            content:
                             caseRow.summary
                          }
                        ]}
                      />
                    </CardBody>*/}
                    </Card>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>
            <Hidden smDown implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-25% 0% 0% -8%',
                  height: '180%',
                  zIndex: '99999',
                }}
              >
                <img
                  style={{
                    margin: '2% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-30% 0% 0% -10%',
                  height: '110%',
                  zIndex: '9999',
                }}
              >
                <img
                  style={{
                    margin: '15% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    cases: state.cases.cases,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  //console.log(ownProps.uid)
  return {
    startSetUsersList: dispatch(startSetUsersList()),
    startSetCasesList: dispatch(startSetCasesList(ownProps.uid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
