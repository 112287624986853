import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import carouselStyle from 'assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

class AgentsCarousel extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
        };

        return (
            <Carousel {...settings}>
                <div>
                    <center>
                        <AccountCircleIcon style={{ width: '30%', height: '10vh', margin: '0 0 -20px 0' }} />
                        <h3><b>John Smith</b></h3>
                        <button style={{ borderRadius: '2px', color: '#fff', backgroundColor: '#8f8f8f', border: 'none', height: '5vh'}}>VIEW PROFILE</button>
                    </center>
                </div>
                <div>
                    <center>
                        <AccountCircleIcon style={{ width: '30%', height: '10vh', margin: '0 0 -20px 0' }} />
                        <h3><b>Emily Jacobs</b></h3>
                        <button style={{ borderRadius: '2px', color: '#fff', backgroundColor: '#8f8f8f', border: 'none', height: '5vh' }}>VIEW PROFILE</button>
                    </center>
                </div>
                <div>
                    <center>
                        <AccountCircleIcon style={{ width: '30%', height: '10vh', margin: '0 0 -20px 0' }} />
                        <h3><b>Marco Lopes</b></h3>
                        <button style={{ borderRadius: '2px', color: '#fff', backgroundColor: '#8f8f8f', border: 'none', height: '5vh' }}>VIEW PROFILE</button>
                    </center>
                </div>
            </Carousel>
        );
    }
}

export default withStyles(carouselStyle)(AgentsCarousel);