import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Card from 'components/Card/Card.jsx';
import Loader from 'components/Loader';
import { getGPTModels } from 'store/actions/gptActions';

const PickOracle = ({ gptModels, getGPTModels }) => {
  useEffect(() => {
    if (!gptModels) {
      getGPTModels();
    }
  }, []);

  return !gptModels ? (
    <Loader />
  ) : (
    <div style={{ marginTop: '30px' }}>
      {gptModels.map((e) => (
        <Link key={e.type} to={`/oracle/${e.type}`}>
          <Card
            style={{
              color: 'white',
              padding: '25px',
              fontSize: '26px',
              textAlign: 'center',
              margin: '30px 0px 0px 0px',
              fontWeight: 'bold',
              backgroundColor: '#91BBA8',
              textTransform: 'capitalize',
            }}
          >
            {e.type}
          </Card>
        </Link>
      ))}
      <div style={{ height: '95px' }}></div>
    </div>
  );
};

PickOracle.propTypes = {
  getGPTModels: PropTypes.func.isRequired,
  gptModels: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ),
};

const mapStateToProps = (state) => {
  const { gptModels } = state;
  return { gptModels };
};

export default connect(mapStateToProps, { getGPTModels })(PickOracle);
