import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Loader from 'assets/img/preloader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/custodianStyle.jsx';
import { Grid } from '@material-ui/core';
import marc from 'assets/img/faces/marc.jpg';
import avatar from 'assets/img/faces/avatar.jpg';

// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';
import ChatMap from './CustodianMap';
import ChatCasesMap from './CustodianCasesMap';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      query: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    //console.log(this.state)
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color='primary'
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  render() {
    const { AuthBool, classes, user } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    return (
      <div>
        {user.uid ? (
          <div>
            {/* <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}> */}
            {/*<h3 className={classes.pageSubcategoriesTitle}>Page Subcategories</h3>*/}
            {/* <br />
                    <NavPills
                        color="primary"
                        alignCenter
                        tabs={[
                            {
                                tabButton: "Private",
                                //tabIcon: Info,
                                tabContent: (
                                    <div>
                                        <Card               
                                          style={{
                                            margin: "0"              
                                        }}
                                        >
                                        <div style={{width: "95%", margin: "0 auto", marginTop: "-10px"}}>
                                                      <Search style={{color: "#aaa", marginBottom: "-8px", marginRight: "10px"}} className={classes.headerLinksSvg + " " + classes.searchIcon} />

                                        <CustomInput
                                        formControlProps={{
                                          className: classes.top + " " + classes.search,
                                          style: {width: "85%"}
                                        }}
                                        id="query"
                                        inputProps={{
                                          placeholder: "Search...",
                                          onChange: (event) => this.handleChange(event),
                                          inputProps: {
                                            "aria-label": "Search",
                                            className: classes.searchInput
                                          }
                                        }}
                                      /></div>
                                      </Card> */}
            {/*<Card>
                                        <CardHeader>
                                            <GridContainer>
                                                <GridItem xs={3} >

                                                    <img src={marc} style={{ borderRadius: "50%", height: "50px" }} alt="..." />
                                                </GridItem>
                                                <GridItem xs={9}>

                                                    <h4 >
                                                        <b>Beverly Black</b>
                                                    </h4>
                                                    <p className={classes.cardCategory}>
                                                        <b>That's an interesting case you...</b>
                                                    </p>
                                                </GridItem>
                                            </GridContainer>
                                        </CardHeader>
                                        <CardBody>
                                        </CardBody>
                                        </Card>
                                        <Card>
                                        <CardHeader>
                                            <GridContainer>
                                                <GridItem xs={3} >

                                                    <img src={avatar} style={{ borderRadius: "50%", height: "50px" }} alt="..." />
                                                </GridItem>
                                                <GridItem xs={9}>

                                                    <h4 >
                                                        Diana Burton
                                                    </h4>
                                                    <p className={classes.cardCategory}>
                                                        We can meet up on South Broad...
                                                    </p>
                                                </GridItem>
                                            </GridContainer>
                                        </CardHeader>
                                        <CardBody>
                                        </CardBody>
                                        </Card>*/}
            {/* <ChatMap uid={user.uid}/>

                                    </div>
                                )
                            },
                            {
                                tabButton: "Public",
                                //tabIcon: LocationOn,
                                tabContent: (
                                    <div>
                                      <GridContainer>
                                        <GridItem xs={12}>
                                        <Card               
                                          style={{
                                            margin: "0"              
                                        }}
                                        >
                                        <div style={{width: "95%", margin: "0 auto", marginTop: "-10px"}}>
                                                      <Search style={{color: "#aaa", marginBottom: "-8px", marginRight: "10px"}} className={classes.headerLinksSvg + " " + classes.searchIcon} />

                                        <CustomInput
                                        formControlProps={{
                                          className: classes.top + " " + classes.search,
                                          style: {width: "85%"}
                                        }}
                                        id="query"
                                        inputProps={{
                                          placeholder: "Search...",
                                          onChange: (event) => this.handleChange(event),
                                          inputProps: {
                                            "aria-label": "Search",
                                            className: classes.searchInput
                                          }
                                        }}
                                      /></div>
                                      </Card>
                                      </GridItem>
                                        <GridItem xs={12}>
                                        <ChatCasesMap uid={user.uid} query={this.state.query} image={marc}/>
                                  </GridItem>
                                      </GridContainer>
                                    </div>
                                )
                            }
                        ]}
                    />
                </GridItem>
            </GridContainer> */}
            <div>
              <iframe
                className={classes.responsiveChat}
                maxWidth='100%'
                height='430'
                allow='microphone;'
                src='https://console.dialogflow.com/api-client/demo/embedded/e56ed5d5-0432-4a56-9360-e67c4f629d2c'
              />
            </div>
          </div>
        ) : (
          <div>
            <Hidden smDown implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-25% 0% 0% -8%',
                  height: '180%',
                  zIndex: '99999',
                }}
              >
                <img
                  style={{
                    margin: '2% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-30% 0% 0% -10%',
                  height: '110%',
                  zIndex: '9999',
                }}
              >
                <img
                  style={{
                    margin: '15% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    user: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);
