import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import ForecastingForm from '../../Forms/ForecastingForm.jsx';
import SearchInput from 'components/SearchInput';
import SectionCarousel from './Sections/SectionCarousel.jsx';
import avatar from 'assets/img/faces/marc.jpg';
import ExtendedTables from '../../Tables/ExtendedTables.jsx';
import { render } from 'enzyme';

export const ForecastingPage = ({ onInput }) => (

  <div style={{ color: '#fff' }}>
    <SearchInput placeholder="Unknown Artist" onInput={onInput} />
    <SectionCarousel />
    <Card
      style={{
        backgroundColor: '#223380',
        borderRadius: '10px',
        height: '20vh',
        margin: '48px 0 20px 0',
      }}
    >
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={10} lg={10}>
          <GridItem xs={2}>
            <img
              src={avatar}
              style={{
                height: '15vh',
                borderRadius: '50%',
                margin: '10px 0 0 -10px',
                display: 'inline-block',
              }}
            />
          </GridItem>
          <GridItem xs={6} style={{ margin: '-120px 0 0 120px' }}>
            <h2
              style={{
                color: '#fff',
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              Jada Pinkett Smith
            </h2>
            <p
              style={{
                color: '#fff',
                margin: '-11px 0 0 0',
              }}
            >
              Quick Stats
            </p>
          </GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem
          style={{
            color: 'black',
            backgroundColor: '#fff',
            height: '9vh',
            width: '17%',
            borderRadius: '50%',
            margin: '0 10px 0 160px',
          }}
        >
          <center>
            <h5>
              <strong>750K</strong>
            </h5>
            <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Streams</p>
          </center>
        </GridItem>
        <GridItem
          style={{
            color: 'black',
            backgroundColor: '#fff',
            height: '9vh',
            width: '17%',
            borderRadius: '50%',
            margin: '0 10px 0 0',
          }}
        >
          <center>
            <h5>
              <strong>13</strong>
            </h5>
            <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Songs</p>
          </center>
        </GridItem>
        <GridItem
          style={{
            color: 'black',
            backgroundColor: '#fff',
            height: '9vh',
            width: '17%',
            borderRadius: '50%',
          }}
        >
          <center>
            <h5>
              <strong>3</strong>
            </h5>
            <p style={{ margin: '-13px 0 0 0', fontSize: '10px' }}>Total Albums</p>
          </center>
        </GridItem>
      </GridContainer>
    </Card>
    <ForecastingForm />
  </div>
);

export default ForecastingPage;
