import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import SearchInput from 'components/SearchInput';
import SectionCarousel from './Sections/SectionCarousel.jsx';

const ManageLabelsPage = ({ onInput }) => {
  return (
    <div>
      <SearchInput placeholder="Search All Labels" onInput={onInput} />
      <SectionCarousel />
      <h2>
        <strong>Active Labels</strong>
      </h2>
      <GridContainer>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Atlantic Records</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Def Jam Records</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Interscope Records</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Suburban Noize</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          background:
            'linear-gradient(0deg, rgba(96,165,62,1) 0%, rgba(145,207,118,1) 100%)',
          height: '35vh',
          color: '#fff',
        }}
      >
        <h2 style={{ margin: '0 0 0 15px' }}>
          <strong>Pending Labels</strong>
        </h2>
        <GridItem xs={6}>
          <Card
            style={{
              backgroundColor: '#22307C',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Warner Music</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              backgroundColor: '#22307C',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Virgin Records</h5>
              <p>Label info</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ManageLabelsPage;
