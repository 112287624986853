import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { routerMiddleware } from 'connected-react-router';
import { ConnectedRouter } from 'connected-react-router';

import { firebase } from './firebase/fbConfig';
import { updateUser } from './store/actions/profileActions';
import store from './services/StoreService';
import rootReducer from './store/reducers/rootReducer';
import indexRoutes from 'routes/index.jsx';
import fbConfig from './firebase/fbConfig';
import * as serviceWorker from './serviceWorker';

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const stateStore = createStore(
  rootReducer(hist),
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      routerMiddleware(hist),
      logger
    ),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
  )
);

ReactDOM.render(
  <Provider store={stateStore}>
    <ConnectedRouter history={hist}>
      <Router history={hist}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </Router>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    // Name, email address, and profile photo Url
    // stateStore.dispatch(login(user));
    // Get user data from firestore
    const userData = await store.getUserData(user.uid);
    // Already saved in db means the user is reloading the page
    if (userData) {
      // So we populate the app state with the user data
      stateStore.dispatch(updateUser(userData));
    }
    // Redirect to the profile page as the user is already logged in
    if (
      hist.location.pathname === '/' ||
      hist.location.pathname === '/pages/login-page' ||
      hist.location.pathname === '/pages/signup-page'
    ) {
      hist.push('/profile');
    }
  } else {
    hist.push('/pages/login-page');
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
