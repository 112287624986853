// Orders Data
const actions = {
  columns: [
    {
      Header: "ACTION ID",
      accessor: "actionID",
      show: false
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "BODY",
      accessor: "description",
      show: false
    },
    {
      Header: "DUE",
      accessor: "dueDate"
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },
    {
      Header: "STATUS",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};
// Orders Data
const pendingActions = {
  columns: [
    {
      Header: "VOL EMAIL",
      accessor: "email"
    },
    {
      Header: "VOL UID",
      accessor: "volUID"
    },
    {
      Header: "ACTION ID",
      accessor: "actionId"
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "COMMENT",
      accessor: "comment"
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};

// Users for Leaderboard
const users = {
  tableHead: ["NAME", "SCORE"],
  dataLoaded: false
};

export const initState = {
  actions,
  pendingActions,
  users
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_ACTIONS_LIST":
      console.log("SET_ACTIONS_LIST");
      return {
        ...state,
        actions: {
          ...state.actions,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
    case "SET_PENDING_ACTIONS_LIST":
      console.log("SET_PENDING_ACTIONS_LIST");
      return {
        ...state,
        pendingActions: {
          ...state.pendingActions,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
    case "SET_USERS_LIST":
      console.log("SET_USERS_LIST");
      return {
        ...state,
        users: {
          ...state.users,
          dataRows: action.tableData,
          userIndex: action.userIndex,
          dataLoaded: true
        }
      };
    case "RESET_ACTIONS_DATA":
      console.log("RESET_ACTIONS_DATA");
      return {
        ...state,
        actions: {
          ...state.actions,
          dataLoaded: false
        }
      };
    default:
      return state;
  }
};
