import { storage } from 'firebase/fbConfig.js';

class Storage {
  static uploadToStorage = ({
    data,
    destination,
    onSuccess,
    onError,
    onProgress,
  }) => {
    const storageRef = storage.ref(destination);
    const uploadTask = storageRef.put(data);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress
        onProgress &&
          onProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      },
      (error) => {
        onProgress && onProgress(null);
        console.log(error);
        onError(error);
      },
      async () => {
        onProgress && onProgress(null);
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        onSuccess(url);
      }
    );
  };
}

export default Storage;
