// Orders Data
const actions = {
  columns: [
    {
      Header: "ACTION ID",
      accessor: "actionID",
      show: false
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "BODY",
      accessor: "description",
      show: false
    },
    {
      Header: "DUE",
      accessor: "dueDate"
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },
    {
      Header: "STATUS",
      accessor: "status"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};
// Orders Data
const pendingActions = {
  columns: [
    {
      Header: "VOL EMAIL",
      accessor: "email"
    },
    {
      Header: "VOL UID",
      accessor: "volUID"
    },
    {
      Header: "ACTION ID",
      accessor: "actionId"
    },
    {
      Header: "TITLE",
      accessor: "title"
    },
    {
      Header: "COMMENT",
      accessor: "comment"
    },
    {
      Header: "DAYS DUE",
      accessor: "days",
      show: false
    },
    {
      Header: "POINTS",
      accessor: "points"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};

// Orders Data
const users = {
  columns: [
    {
      Header: "USERNAME",
      accessor: "username"
    },
    {
      Header: "FIRST NAME",
      accessor: "firstName"
    },
    {
      Header: "LAST NAME",
      accessor: "lastName"
    },
    {
      Header: "PHONE",
      accessor: "phone"
    },
    {
      Header: "EMAIL",
      accessor: "email"
    },
    {
      Header: "COUNTY",
      accessor: "county"
    },
    {
      Header: "PRECINCT",
      accessor: "precinct"
    },
    {
      Header: "GROUPS",
      accessor: "groups"
    },
    {
      Header: "SCORE",
      accessor: "score"
    },
    {
      Header: "VOL UID",
      accessor: "volUID"
    },
    {
      Header: "USER TYPE",
      accessor: "userType"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};

export const initState = {
  actions,
  pendingActions,
  users
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_ADMIN_USERS_LIST":
      console.log("SET_ADMIN_USERS_LIST");
      return {
        ...state,
        users: {
          ...state.users,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
    default:
      return state;
  }
};
