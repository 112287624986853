
import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
// import DateTimePicker from 'react-datetime-picker';
// import DatePicker from 'react-date-picker';

import { Redirect } from 'react-router-dom'
import compose from 'recompose/compose';
import moment from 'moment'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Lock from "@material-ui/icons/Lock";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Wizard from "components/Wizard/Wizard.jsx";


import { startGoogleLogin, emailSignup } from "../../store/actions/authActions"
import { createUser } from "../../store/actions/profileActions"

import Style from "assets/jss/material-dashboard-pro-react/views/setupStyle";
import CreateCaseForm from "../../components/Forms/CreateCaseForm";

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class SignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            advertiser: false,
            email: this.props.authUser ? this.props.authUser.email : "no email",
            continueForm: false,
            setup: true
        };
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleClickOpen = (modal, actionID, actionTitle) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            editActionID: actionID,
            editActionTitle: actionTitle
        });
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            authInfo: {
                ...this.state.authInfo,
                [e.target.id]: e.target.value,
            }
            // email: this.props.authUser.email
        })
        console.log(this.state)
    }


    handleSelectChange = (e) => {
        this.setState({
            userType: e.target.value
        })
        console.log(this.state)
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state.userType.toLowerCase())
        this.props.createUser(this.state, this.props.authUser.uid, this.state.userType.toLowerCase())
    }

    // onChange = dob => this.setState({ dob })

    handleDateChange = (dob) => {
        console.log(dob)
        if (dob) {
            this.setState(() => ({ dob: dob, dobAPI: moment(dob).format('YYYY-MM-DD') }))
        }
    }


    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }

    handleError = (error) => {
        var errorCode = error.code;

        this.setState({
            signupError: true,
            errorCode
        })
    }

    isAdvertiser = () => {

        if (this.state.advertiser == "undefined") {
            this.setState({
                advertiser: true,
            })
        } else {
            this.setState({
                advertiser: !this.state.advertiser,
            })
        }

    }

    continueForm = () => {

        this.setState({
            continueForm: true,
        })

    }


    render() {
        const { classes, startGoogleLogin, authError } = this.props;

        // !!authError && !this.state.signupError ? (this.handleError(authError)) : (null);

        const userTypes = ['Agent', 'Artist']

        // console.log(this.state)


        // if (this.props.user.userType) {
        //     return <Redirect to='/profile' />
        // }
        return (
            <div className={classes.container}>
                {!this.state.continueForm &&
                    <GridContainer justify="center" style={{ marginTop: "20vh" }}>
                        <GridItem xs={12} sm={10} md={8} lg={6}>
                            {/*   <Wizard
                       validate
                       color="primary"
                       steps={[
                         { stepName: "About", stepComponent: Step1, stepId: "about" },
                         { stepName: "Account", stepComponent: Step2, stepId: "account" },
                         { stepName: "Practice", stepComponent: Step3, stepId: "address" }
                       ]}
                       title="Create Your Account"
                       subtitle=""
                       finishButtonClick={e => alert(e)}
                     />*/}

                            <h2 className={classes.cardTitle} style={{ fontSize: "1.8rem", color: "#333" }}>Welcome!</h2>
                            {/*<div className={classes.center}>
                               <Button
                                 justIcon
                                 round
                                 color="google"
                                 onClick={startGoogleLogin}>
                                 <i className="fab fa-google" />
                               </Button>
                               {` `}
                 </div>*/}
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                Email: {this.props.authUser ? this.props.authUser.email : "loading..."}
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="First Name"
                                        id="firstName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Last Name"
                                        id="lastName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                    <CustomInput
                                        labelText="First Name"
                                        id="firstName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="Last Name"
                                        id="lastName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                {/* <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    Date of Birth<br />
                                    <DateTimePicker
                                        onChange={this.onChange}
                                        value={this.state.dob}
                                    />
                                    <DatePicker
                                        onChange={this.handleDateChange}
                                        value={this.state.dob ? this.state.dob : ''}
                                    />

                                </Card> */}
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="Email Address"
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="Phone Number"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            maxLength: "16",
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="User Name"
                                        id="userName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            maxLength: "16",
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="Password"
                                        id="password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            maxLength: "16",
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <Card style={{ paddingLeft: "20px", marginBottom: "0", marginTop: "20px" }}>
                                    <CustomInput
                                        labelText="Confirm Password"
                                        id="password"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            maxLength: "16",
                                            type: "text",
                                            disableUnderline: true,
                                            onChange: (event) => this.handleChange(event),
                                        }}
                                    />
                                </Card>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}>
                                    <InputLabel
                                        htmlFor="userType"
                                        className={classes.selectLabel}>
                                        User Type:
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu
                                        }}
                                        classes={{
                                            select: classes.select
                                        }}
                                        required
                                        value={this.state.userType}
                                        onChange={this.handleSelectChange}
                                        inputProps={{
                                            id: 'userType',
                                            name: 'userType',
                                        }}
                                    >
                                        {
                                            userTypes.map((item) => (
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem
                                                    }}
                                                    value={item}
                                                    key={item}
                                                >{item}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={-1}
                                            onClick={() => this.isAdvertiser()}
                                            checked={this.state.advertiser}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            color="primary"
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot
                                            }}
                                        />
                                    }
                                    classes={{ label: classes.bodyText }}
                                    label='Advertiser?'
                                />
                                {this.state.userType ?
                                    <div className={classes.center}>
                                        <Button
                                            type="submit"
                                            //onClick={() => this.continueForm()}
                                            //color="primary"
                                            style={{ backgroundColor: "#0EB2D8", fontSize: "1rem" }}
                                        >
                                            <b>Continue</b> <ArrowRightAltIcon />
                                        </Button>
                                    </div> :
                                    "Please fill out form."

                                }
                                <Dialog
                                    classes={{
                                        root: classes.modalRoot,
                                        paper: classes.modal + " " + classes.modalLarge
                                    }}
                                    open={this.state.openAssignLawyer}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.handleClose("openAssignLawyer")}
                                    aria-labelledby="shipment-modal-slide-title"
                                    aria-describedby="shipment-modal-slide-description"
                                >
                                    <DialogTitle
                                        id="shipment-modal-slide-title"
                                        disableTypography
                                        className={classes.modalHeader}
                                    >
                                        <h2 className={classes.modalTitle}><b>Legal Disclaimer</b></h2>
                                    </DialogTitle>
                                    <DialogContent
                                        style={{ backgroundColor: "#f7f3f0" }}
                                        id="shipment-modal-slide-description"
                                        className={classes.modalBody}
                                    > <br />


                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <Button
                                                    className={classes.ltrButtonWhite}
                                                    style={{
                                                        backgroundColor: "#170f56",
                                                        marginTop: "-68px",
                                                        color: "white",
                                                        width: "100%"
                                                    }}
                                                    key="close"
                                                    aria-label="Close"
                                                    onClick={() => this.handleClose("openAssignLawyer")}
                                                >
                                                    <b>PREVIOUS</b>
                                                </Button>

                                            </GridItem>
                                            <GridItem xs={6}>
                                                <Button
                                                    className={classes.ltrButtonWhite}
                                                    style={{
                                                        backgroundColor: "#170f56",
                                                        marginTop: "-68px",
                                                        color: "white",
                                                        width: "100%"
                                                    }}
                                                    key="close"
                                                    aria-label="Close"
                                                    type="submit"
                                                >
                                                    <b>CONTINUE</b>
                                                </Button>
                                            </GridItem>
                                        </GridContainer>

                                        {/* FORM */}

                                    </DialogContent>
                                </Dialog>
                            </form>
                        </GridItem>
                    </GridContainer>
                }
                {(this.state.continueForm && !this.state.continueForm2) &&
                    <GridContainer>
                        <GridItem xs={12}>
                            {this.state.userType == "Client" ?
                                {/* <CreateCaseForm /> */ }
                                :
                                <p>Lawyer</p>
                            }

                        </GridItem>
                    </GridContainer>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    createUser: (authObject, uid, userType) => dispatch(createUser(authObject, uid, userType)),
})

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authUser: state.auth.user,
        user: state.profile.user,
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(SignupPage);