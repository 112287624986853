import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import { Link } from 'react-router-dom';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Timeline from '@material-ui/icons/Timeline';
import InputAdornment from '@material-ui/core/InputAdornment';
import People from '@material-ui/icons/People';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Loader from 'assets/img/preloader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';
import { Grid } from '@material-ui/core';

// images
import Token from 'assets/img/arcq/arq-token.png';
import marc from 'assets/img/faces/marc.jpg';
import avatar from 'assets/img/faces/avatar.jpg';

import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from 'variables/charts.jsx';

// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class InitializeChatWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color='primary'
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const { AuthBool, classes, uid } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    return (
      <div>
        {uid ? (
          <div>
            <GridContainer style={{ marginTop: '60px', padding: '0px 20px' }}>
              <Card
                style={{
                  backgroundColor: '#6ABC43',
                  borderRadius: '6px 6px 0px 0px',
                }}
              >
                <CardBody>
                  <GridContainer
                    justify='center'
                    style={{ marginTop: '-30px ' }}
                  >
                    <GridItem xs={3} sm={3} md={3} lg={3}>
                      <img
                        src={Token}
                        style={{ width: '100%', margin: '-10px 0px 0px 0px' }}
                        alt='ARCQ Token'
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    justify='center'
                    style={{ padding: '10px', marginLeft: '10px' }}
                  >
                    <GridItem xs={10} sm={10} md={10} lg={10}>
                      <p
                        style={{
                          fontWeight: '600',
                          color: '#1B3D59',
                          fontSize: '19px',
                        }}
                      >
                        Initializing your bot will cost 500,000 ARQ tokens.This
                        is a one time, non-refundable
                      </p>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    justify='center'
                    style={{ margin: '-20px 0px 25px 65px' }}
                  >
                    <GridItem xs={8} sm={8} md={8} lg={8}>
                      <p
                        style={{
                          fontWeight: '600',
                          color: '#1B3D59',
                          fontSize: '19px',
                        }}
                      >
                        action.
                      </p>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    justify='center'
                    style={{ margin: '-40px 0px 0px 0px', padding: '20px' }}
                  >
                    <GridItem xs={11} sm={11} md={11} lg={11}>
                      <p
                        style={{
                          fontWeight: '400',
                          color: '#1D3F5A',
                          fontSize: '16px',
                        }}
                      >
                        You have 515,901 ARQ tokens, You will not be able to
                        transfer your credits back
                      </p>
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    justify='center'
                    style={{ margin: '-30px 0px 10px 100px' }}
                  >
                    <GridItem xs={11} sm={11} md={11} lg={11}>
                      <p
                        style={{
                          fontWeight: '400',
                          color: '#1D3F5A',
                          fontSize: '16px',
                        }}
                      >
                        into the app.
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer
                    justify='center'
                    style={{ margin: '-20px 25px 20px ' }}
                  >
                    <GridItem xs={11} sm={11} md={11} lg={11}>
                      <p
                        style={{
                          fontWeight: '400',
                          color: '#1D3F5A',
                          fontSize: '16px',
                        }}
                      >
                        Are you sure you wish to proceed?
                      </p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              {/* Buttons Sections  */}
              <GridContainer justify='center'>
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <button
                    style={{
                      fontSize: '18px',
                      margin: '-14px 0px 0px 7px',
                      backgroundColor: '#1B3D59',
                      borderRadius: '0px 0px 2px 10px',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: '#FFFFFF', marginTop: '10px' }}>
                      {' '}
                      Go Back{' '}
                    </p>
                  </button>
                </GridItem>

                <GridItem
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ margin: '-15px 0px 0px -4px' }}
                >
                  <Link
                    to={{
                      pathname: `/view-post`,
                    }}
                  >
                    <button
                      style={{
                        background:
                          'linear-gradient(0deg, rgba(149,207,155,1) 0%, rgba(216,225,116,1) 100%)',
                        borderRadius: '0px 0px 10px 0px',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#1B3D59',
                          marginTop: '10px',
                        }}
                      >
                        Yes, initalize my bot
                      </p>
                    </button>
                  </Link>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </div>
        ) : (
          <div>
            <Hidden smDown implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-25% 0% 0% -8%',
                  height: '180%',
                  zIndex: '99999',
                }}
              >
                <img
                  style={{
                    margin: '2% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-30% 0% 0% -10%',
                  height: '110%',
                  zIndex: '9999',
                }}
              >
                <img
                  style={{
                    margin: '15% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { uid } = state.profile || {};
  return {
    uid,
    AuthBool: !!state.auth.user,
    users: state.admin.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(InitializeChatWizard);
