import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import SearchInput from 'components/SearchInput';
import SectionCarousel from './Sections/SectionCarousel.jsx';

const ManageArtistsPage = ({ onInput }) => {
  return (
    <div>
      <SearchInput placeholder="Search All Artists" onInput={onInput} />
      <SectionCarousel />
      <h2>
        <strong>Active Artists</strong>
      </h2>
      <GridContainer>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(247,78,100,1) 0%, rgba(242,101,153,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Jada Pinkett Smith</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(247,78,100,1) 0%, rgba(242,101,153,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Glass Animals</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(247,78,100,1) 0%, rgba(242,101,153,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Portugal. the Man</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(247,78,100,1) 0%, rgba(242,101,153,1) 100%)',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Cardi B</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        style={{
          background:
            'linear-gradient(0deg, rgba(96,165,62,1) 0%, rgba(145,207,118,1) 100%)',
          height: '35vh',
          color: '#fff',
        }}
      >
        <h2 style={{ margin: '0 0 0 15px' }}>
          <strong>Pending Artists</strong>
        </h2>
        <GridItem xs={6}>
          <Card
            style={{
              backgroundColor: '#22307C',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>alt-J</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card
            style={{
              backgroundColor: '#22307C',
              borderRadius: '10px',
              height: '20vh',
              margin: '0 0 20px 0',
              color: '#fff',
            }}
          >
            <div style={{ margin: '0 0 0 10px' }}>
              <h5>Tame Impala</h5>
              <p>Artist Bio</p>
            </div>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ManageArtistsPage;
