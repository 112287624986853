import config from 'config';

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const buildAvatarPath = (avatar) =>
  // Add timstamp for cache busting purposes
  `${config.avatarStoragePath}${avatar}?alt=media&token=${new Date().getTime()}`;
