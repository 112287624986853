import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Hidden from '@material-ui/core/Hidden';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import categoryTopics from 'config/categoryTopics';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';

//Sections
import CarouselDesktop from './sections/SectionCarouselDesktop.jsx';
import CarouselMobile from './sections/SectionCarouselMobile.jsx';

class Topics extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div style={{ padding: '0px 20px 80px 20px', overflowX: 'hidden' }}>
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer style={{ margin: '20px 0px 10px 0px' }}>
              <GridItem xs={6}>
                <h3
                  style={{ margin: '0px 10px 0px -5px', fontSize: '22px' }}
                  className={classes.title}
                >
                  Trending Topics
                </h3>
              </GridItem>
              {/* <GridItem xs={4}>
                  <h3 style={{ margin: "7px 5px 0px 0px", fontWeight: "300", background: "E8E8E8", fontSize: "15px", textAlign: "right" }}
                   className={classes.title}>See more</h3>
                </GridItem>
                <GridItem xs={2}>
                  < ArrowForwardIosRoundedIcon style={{ color: "#ffff", margin: "5px -10px 0px 0px" }} />
                </GridItem> */}
            </GridContainer>
            {/* Carousel */}
            <Hidden xsDown implementation="css">
              <div style={{ margin: '0px 0px 20px 0px', borderRadius: '20px' }}>
                <CarouselDesktop />
              </div>
            </Hidden>
            <Hidden smUp implementation="css">
              <div style={{ margin: '0px 0px 20px 0px', borderRadius: '20px' }}>
                <CarouselMobile />
              </div>
            </Hidden>
          </GridItem>
        </GridContainer>
        {/* trending video and see more icons */}
        <GridContainer style={{ margin: '20px 0px 10px 0px' }}>
          <GridItem xs={7}>
            <h3
              style={{ margin: '10px 10px 10px -15px', fontSize: '22px' }}
              className={classes.title}
            >
              Categories
            </h3>
          </GridItem>
          {/* <GridItem xs={4}>
            <div style={{ marginRight: '-15px', float: 'right' }}>
              <CustomDropdown
                buttonText="Sort By"
                dropdownList={[
                  'Recent',
                  { divider: true },
                  'Views',
                  { divider: true },
                  'Rating',
                ]}
              />
            </div>
          </GridItem> */}
        </GridContainer>
        {/* Category Cards */}
        {categoryTopics.map((e) => (
          <GridContainer
            key={e.category}
            style={{
              padding: '15px 15px 0px 15px',
              width: '103%',
              boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
              borderRadius: '10px',
              backgroundColor: '#1B3D59',
              margin: '-5px 0px 20px -14px',
            }}
          >
            <GridItem xs={8}>
              <h4
                style={{ margin: '0px 10px 10px 0px', fontSize: '22px' }}
                className={classes.title}
              >
                {e.category}
              </h4>
            </GridItem>
            <GridItem xs={3}>
              <Link
                to={{
                  pathname: `/category/${e.category}`,
                }}
              >
                <h3
                  style={{
                    margin: '7px 5px 0px 0px',
                    fontWeight: '600',
                    fontSize: '14px',
                    textAlign: 'right',
                  }}
                  className={classes.title}
                >
                  View All
                </h3>
              </Link>
            </GridItem>
            <GridItem xs={1}>
              <Link
                to={{
                  pathname: `/category/${e.category}`,
                }}
              >
                <ArrowForwardIosRoundedIcon
                  style={{
                    color: '#ffff',
                    fontSize: '14pt',
                    margin: '6px -10px 0px 0px',
                  }}
                />
              </Link>
            </GridItem>
            <GridItem xs={12}>
              <p style={{ color: 'white' }}>{e.description}</p>
            </GridItem>
            {/* Mobile */}
            {e.topics.slice(0, 2).map((e) => (
              <GridItem key={e.name} xs={6}>
                <Hidden smUp implementation="css">
                  <Link
                    to={{
                      pathname: `/topic-detail/${e.name}`,
                    }}
                  >
                    <Card className={classes.textWhite}>
                      <div className={classes.cardImgOverlay}>
                        <h4
                          style={{ marginTop: '0px', fontSize: '16px' }}
                          className={classes.bodyText}
                        >
                          <strong>{e.name}</strong>
                        </h4>
                      </div>
                      <div className={classes.imgDarken}>
                        <img
                          style={{
                            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                          }}
                          className={classes.cardImg}
                          src={e.image}
                          alt="..."
                          data-holder-rendered="true"
                        />
                      </div>
                    </Card>
                  </Link>
                </Hidden>
              </GridItem>
            ))}
            {/* Desktop */}
            {e.topics.slice(0, 4).map((e) => (
              <GridItem key={e.name} xs={3}>
                <Hidden xsDown implementation="css">
                  <Link
                    to={{
                      pathname: `/topic-detail/${e.name}`,
                    }}
                  >
                    <Card className={classes.textWhite}>
                      <div className={classes.cardImgOverlay}>
                        <h4
                          style={{ marginTop: '0px', fontSize: '16px' }}
                          className={classes.bodyText}
                        >
                          <strong>{e.name}</strong>
                        </h4>
                      </div>
                      <div className={classes.imgDarken}>
                        <img
                          style={{
                            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                          }}
                          className={classes.cardImg}
                          src={e.image}
                          alt="..."
                          data-holder-rendered="true"
                        />
                      </div>
                    </Card>
                  </Link>
                </Hidden>
              </GridItem>
            ))}
          </GridContainer>
        ))}
        <div style={{ height: '75px' }} />
      </div>
    );
  }
}

Topics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(Topics);
