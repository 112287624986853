import React from 'react';
import PropTypes from 'prop-types';
import withStyle from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

export const VideoDescriptionForm = ({ classes, handleInput }) => (
  <Card
    style={{
      background: '#1B3D59',
      padding: '0px 0px 40px 15px',
      width: '94%',
    }}
  >
    <CustomInput
      style={{ marginLeft: '-10px', width: '80%' }}
      formControlProps={{
        className: classes.top + ' ' + classes.search,
        style: {
          marginLeft: '-5px',
          width: '95%',
          paddingTop: '9px',
        },
      }}
      inputProps={{
        placeholder: 'Short description (200 characters or less)',
        inputProps: {
          'aria-label': 'Search',
          className: classes.searchInput,
          onChange: (e) => handleInput(e.target.value),
        },
      }}
    />
  </Card>
);

VideoDescriptionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleInput: PropTypes.func.isRequired,
};

export default withStyle(Style)(VideoDescriptionForm);
