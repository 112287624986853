import React from 'react';
import ConnectedButton from 'components/ButtonConnected';
import SendPostButton from 'components/ButtonSendPost';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

export const UserProfileViewButtons = (props) => (
  <GridContainer style={{ margin: '-10px 20px -70px 10px' }}>
    <GridItem xs={5}>
      <ConnectedButton {...props} />
    </GridItem>
    <GridItem xs={5}>
      <SendPostButton />
    </GridItem>
  </GridContainer>
);

export default UserProfileViewButtons;
