import React from 'react';
import LoaderGif from 'assets/img/preloader.gif';
import Hidden from '@material-ui/core/Hidden';

export const Loader = () => (
  <div
    style={{
      background:
        '#26202A',
      position: 'absolute',
      width: '110%',
      marginLeft: '-7%',
      height: '100%',
    }}
  >
    {/* Desktop */}
      <Hidden xsDown implementation="css">
      <img
      style={{
        margin: '45% 0% 0% 41%',
        position: 'relative',
        width: '20%',
      }}
      src={LoaderGif}
      alt="..."
      />
      </Hidden>
      {/* Mobile */}
        <Hidden smUp implementation="css">
    <img
      style={{
        margin: '65% 0% 0% 41%',
        position: 'relative',
        width: '20%',
      }}
      src={LoaderGif}
      alt="..."
    />
    </Hidden>
  </div>
);

Loader.propTypes = {};

export default Loader;
