import React from 'react';
import PropTypes from 'prop-types';

import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import Card from 'components/Card/Card.jsx';

const VideoCategoryItem = ({ item, onSelectTopic }) => {
  const { category, topics } = item;
  const content = (
    <div>
      {topics.map(({ name }) => (
        <span
          key={name}
          style={{
            cursor: 'pointer',
            display: 'inline-block',
            margin: '3px',
            padding: '8px',
            background: '#67898C',
            borderRadius: '10px',
          }}
          onClick={() => onSelectTopic({ category, topic: name })}
        >
          {name}
        </span>
      ))}
    </div>
  );
  return (
    <GridContainer>
      <GridItem xs={11} sm={11} md={11} lg={11}>
        <div
          style={{
            margin: '25px 0px -10px 25px',
            width: '96%',
          }}
        >
          <Card
            style={{
              padding: '0px 0px 0px 10px',
              backgroundColor: '#232020',
              opacity: '1.5',
            }}
          >
            <Accordion
              active={null}
              collapses={[{ title: item.category, content }]}
            />
          </Card>
        </div>
      </GridItem>
    </GridContainer>
  );
};

VideoCategoryItem.propTypes = {
  onSelectTopic: PropTypes.func.isRequired,
  item: PropTypes.shape({
    category: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default VideoCategoryItem;
