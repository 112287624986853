// Orders Data
const availableData = {
  columns: [
    {
      Header: "ORDER #",
      accessor: "order"
    },
    {
      Header: "CUSTOMER",
      accessor: "customer"
    },
    {
      Header: "PALLET CT",
      accessor: "pallet"
    },
    {
      Header: "WEIGHT",
      accessor: "weight"
    },
    {
      Header: "PICKUP ZIP",
      accessor: "zip"
    },
    {
      Header: "BILLABLE",
      accessor: "billable"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataRows: [
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"],
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"],
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"]
  ]
};

const pendingData = {
  columns: [
    {
      Header: "ORDER #",
      accessor: "order"
    },
    {
      Header: "BROKER",
      accessor: "broker"
    },
    {
      Header: "RATING",
      accessor: "rating"
    },
    {
      Header: "BID",
      accessor: "bid"
    },
    {
      Header: "BILLABLE",
      accessor: "billable"
    },
    {
      Header: "PALLET CT",
      accessor: "pallet"
    },
    {
      Header: "WEIGHT",
      accessor: "weight"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataRows: [
    [50267, "Breaking Freight", 3, "$1589.00", "$1901.40", 28, "481lbs"],
    [50437, "Breaking Freight", 3, "$1589.00", "$1901.40", 28, "481lbs"]
  ]
};

// brokers Data
const brokerData = {
  columns: [
    {
      Header: "BROKER",
      accessor: "broker"
    },
    {
      Header: "ORDERS (QT)",
      accessor: "orders"
    },
    {
      Header: "AMOUNT",
      accessor: "amount"
    },
    {
      Header: "PERFORMANCE",
      accessor: "performance"
    },
    {
      Header: "PROFIT RATING",
      accessor: "profit"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataRows: [
    ["Breaking Freight", 15, 12589.0, 3, 0.32],
    ["Freight to Brake", 76, 14589.0, 3, 0.15],
    ["Young Dumb & Brokering", 85, 15427.0, 3, 0.234],
    ["Mr Tim Tim’s Freight", 42, 69420.0, 3, 0.171],
    ["Mr Tim Tim’s Freight", 73, 66766.0, 3, 0.171]
  ]
};

const initState = {
  orders: {
    availableData: availableData,
    pendingData: pendingData
  },
  brokers: brokerData
};

export default (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
