import React from 'react';
import PropTypes from 'prop-types';
import VideoRecorder from 'react-video-recorder';

import Loader from 'assets/img/preloader.gif';
import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';

// core components
import Transition from 'components/Transition';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

// Forms
import VideoForm from 'components/Forms/VideoForm.jsx';

class RecordVideo extends React.Component {
  state = {
    data: null,
    updateProfileModal: true,
  };

  handleClose = (modal) => {
    this.setState({ [modal]: false });
  };

  render() {
    const { classes, onSubmit } = this.props;

    return (
      <div>
        {/* Mobile  View HTML  */}
        <Hidden smUp implementation="css">
          <GridContainer justify="center" style={{ marginBottom: '50px' }}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <img
                  style={{
                    margin: '78% 0% -78% 42%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt="..."
                />
                {/* SNIPPET Record.jsx_3 */}
                <VideoRecorder
                  isOnInitially={true}
                  replayVideoAutoplayAndLoopOff={true}
                  isFlipped={false}
                  showReplayControls={false}
                  onRecordingComplete={(videoBlob) => {
                    onSubmit(videoBlob, this.state.data);
                  }}
                />
              </GridItem>
            </GridItem>
          </GridContainer>
        </Hidden>
        {/* Tablet View */}
        {/* <Hidden smDown implementation="css">
          <GridContainer justify="center">
            <GridItem xs={12}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    margin: '15vh 0 0 0',
                    height: '78vh',
                    overflowY: 'hidden',
                  }}
                >
                  <VideoRecorder
                    isOnInitially={true}
                    replayVideoAutoplayAndLoopOff={true}
                    isFlipped={false}
                    showReplayControls={false}
                    onRecordingComplete={(videoBlob) => {
                      onSubmit(videoBlob, this.state.data);
                    }}
                  />
                </div>
              </GridItem>
            </GridItem>
          </GridContainer>
        </Hidden> */}

        {/* Desktop View HTML  */}
        <Hidden xsDown implementation="css">
          <GridContainer justify="center">
            <GridItem xs={12}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {/* SNIPPET Record.jsx_1 */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    margin: '29vh 0 0 0',
                    height: '62vh',
                    width: '79vw',
                    overflowY: 'hidden',
                  }}
                >
                  <VideoRecorder
                    isOnInitially={true}
                    replayVideoAutoplayAndLoopOff={true}
                    isFlipped={false}
                    showReplayControls={false}
                    onRecordingComplete={(videoBlob) => {
                      onSubmit(videoBlob, this.state.data);
                    }}
                  />
                </div>
              </GridItem>
            </GridItem>
          </GridContainer>
        </Hidden>

        {/* MODAL POST RESPONSE */}
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + ' ' + classes.modalSmall,
          }}
          keepMounted
          open={this.state.updateProfileModal}
          onClose={() => this.handleClose('updateProfileModal')}
          aria-labelledby="shipment-modal-slide-title"
          aria-describedby="shipment-modal-slide-description"
          disableBackdropClick={true}
          TransitionComponent={Transition}
        >
          <DialogContent
            style={{ marginTop: '-30px' }}
            id="shipment-modal-slide-description"
            className={classes.modalBody}
          >
            <VideoForm
              onSubmit={(data) => {
                this.setState({ data: { ...data } });
                this.handleClose('updateProfileModal');
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

RecordVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(Style)(RecordVideo);
