import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import { Link } from 'react-router-dom';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Loader from 'assets/img/preloader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/trendingStyle.jsx';
import { Grid } from '@material-ui/core';
//Sections
import Carousel from './sections/SectionCarousel.jsx';

// Images
import forest from 'assets/img/arcq/forest.png';
import gameboy from 'assets/img/arcq/gameboy.png';
import lightTunnel from 'assets/img/arcq/lightTunnel.png';
import redHeadshot from 'assets/img/arcq/redHeadshot.png';
import trending1 from 'assets/img/trending1.jpg';
import trending2 from 'assets/img/trending2.jpg';
import trending3 from 'assets/img/trending3.jpg';
// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';
import { startSetCasesList } from 'store/actions/casesActions';
import CreateCaseForm from '../../components/Forms/CreateCaseForm';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class Trending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateProfileModal: false,
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      query: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color='primary'
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    //console.log(this.state)
  };

  render() {
    const { AuthBool, classes, uid, cases } = this.props;
    //console.log(cases)
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    const TrendingData = [
      {
        img: trending1,
        about: 'Is there any meaning to life?',
      },
    ];
    const TrendingDataPic = [
      {
        img: trending1,
        about: 'Does life have any meaning?',
      },
      {
        img: trending2,
        about: ' Should we fund ocean exploration or space exploration?',
      },
      {
        img: trending3,
        about: 'Is there Life after death?',
      },
    ];

    return (
      <div>
        {uid ? (
          <div>
            {/* Meaning of Life and self- DISCOVERY   */}

            <GridContainer justify='center'>
              <GridItem>
                <h5
                  style={{
                    color: '#2B2E34',
                    fontFamily: 'Raleway',
                    fontSize: '12px',
                    margin: '15px 0px 0px 40px',
                    fontWeight: '700',
                  }}
                >
                  {' '}
                  TOPIC: SELF-DISCOVERY
                </h5>
                <h4
                  style={{
                    color: '#2B2E34',
                    margin: '0px -15px -5px 0px',
                    fontWeight: '700',
                  }}
                >
                  Is There any meaning to Life?
                </h4>
              </GridItem>
            </GridContainer>
            {/* Carousel with images in SectionCarousel.jsx */}
            <GridContainer>
              <GridItem xs={12}>
                <div
                  style={{
                    margin: '0px 0px 0px 0px',
                    borderRadius: '20px',
                    padding: '15px',
                  }}
                >
                  <Carousel />
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={3} />
              <GridItem xs={6}>
                <Button
                  style={{
                    margin: '-3% 0% 2% 0%',
                    borderRadius: '50px',
                    width: '100%',
                    boxShadow: 'none',
                    color: '#1B3D59',
                    background:
                      'linear-gradient(90deg, rgba(142,206,159,1) 0%, rgba(241,232,102,1) 100%)',
                  }}
                  color='primary'
                  onClick={() => this.handleClickOpen('updateProfileModal')}
                >
                  <b>Post A Response</b>
                </Button>
              </GridItem>
              <GridItem xs={3} />
            </GridContainer>
            {/* MODAL POST RESPONSE */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + ' ' + classes.modalSmall,
              }}
              open={this.state.updateProfileModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.handleClose('updateProfileModal')}
              aria-labelledby='shipment-modal-slide-title'
              aria-describedby='shipment-modal-slide-description'
            >
              <DialogTitle
                id='shipment-modal-slide-title'
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key='close'
                  aria-label='Close'
                  onClick={() => this.handleClose('updateProfileModal')}
                >
                  {' '}
                  <Close className={classes.modalClose} />
                </Button>
                <h2 className={classes.modalTitle}>
                  <center>Post Type</center>
                </h2>
              </DialogTitle>
              <DialogContent
                id='shipment-modal-slide-description'
                className={classes.modalBody}
              />
            </Dialog>
          </div>
        ) : (
          <div>
            <Hidden smDown implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-25% 0% 0% -8%',
                  height: '180%',
                  zIndex: '99999',
                }}
              >
                <img
                  style={{
                    margin: '2% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-30% 0% 0% -10%',
                  height: '110%',
                  zIndex: '9999',
                }}
              >
                <img
                  style={{
                    margin: '15% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { uid } = state.profile || {};
  return {
    uid,
    AuthBool: !!state.auth.user,
    users: state.admin.users,
    cases: state.cases.cases,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  console.log(this.props);
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Trending);
