import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SnackbarContent from 'components/Snackbar/FollowerSnackbarContent.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Placeholder from 'components/Placeholder';
import CustomTabs from 'components/CustomTabs/FollowerTabs.jsx';
import Chat from '@material-ui/icons/Chat';
import Face from '@material-ui/icons/Face';

export const FollowerList = ({ followers, setAccess, blockAccess }) => {
  const tabs = [{ name: 'public', icon: Chat }, { name: 'private', icon: Face }];
  const list = {
    public: followers.filter((e) => e.access === 'public'),
    private: followers.filter((e) => e.access === 'private'),
  };

  return (
    <CustomTabs
      headerColor="primary"
      tabs={tabs.map((tab, i) => ({
        tabName: tab.name,
        tabIcon: tab.icon,
        tabContent: !list[tab.name].length ? (
          <Placeholder text={`No ${tab.name} connections yet`} />
        ) : (
          <GridContainer
            style={{
              margin: '10px 10px -24px -4px',
              width: '99.5%',
              padding: '0px 0px 0px 3%',
            }}
          >
            <GridContainer style={{ padding: '0px 20px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  {list[tab.name].map((user) => {
                    const action = i === 1 ? 'Remove' : 'Give';
                    const access = tabs[i === 1 ? 0 : 1].name;
                    return (
                      <SnackbarContent
                        key={user.id}
                        data={user}
                        color={i === 1 ? 'success' : 'info'}
                        options={[
                          {
                            text: `${action} private access`,
                            action: () => setAccess(user.id, access),
                          },
                          {
                            text: 'Remove this connection',
                            action: () => blockAccess(user.id),
                          },
                        ]}
                      />
                    );
                  })}
                </div>
              </GridItem>
            </GridContainer>
            <Clearfix />
          </GridContainer>
        ),
      }))}
    />
  );
};

FollowerList.propTypes = {
  setAccess: PropTypes.func.isRequired,
  blockAccess: PropTypes.func.isRequired,
  followers: PropTypes.arrayOf(
    PropTypes.shape({
      access: PropTypes.string.isRequired,
    })
  ),
};

export default FollowerList;
