import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/CustomButtons/Button.jsx';

export const RoundedBox = ({ title, subtitle }) => (
  <Button
    style={{
      height: '70px',
      width: '100%',
      borderRadius: '10px',
      backgroundColor: '#2F5665',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0)',
    }}
    color="primary"
  >
    <b
      style={{
        textTransform: 'capitalized',
        color: '#fffff',
        margin: '0px 0px 0px 0px',
      }}
    >
      <span style={{ fontSize: '16px', fontWeight: '600' }}>{title}</span>
      <br />
      {subtitle}
    </b>
  </Button>
);

RoundedBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default RoundedBox;
