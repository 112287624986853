import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import { Link } from 'react-router-dom';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Face from '@material-ui/icons/Face';
import Chat from '@material-ui/icons/Chat';
import Loader from 'assets/img/preloader.gif';

// core components
import LibraryTabs from 'components/CustomTabs/LibraryTabs.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';
import Hidden from '@material-ui/core/Hidden';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import { Grid } from '@material-ui/core';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Badge from 'components/Badge/Badge.jsx';

// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';

// Images
import DefaultProfile from 'assets/img/default-avatar.png';
import profile6 from 'assets/img/profile6.jpg';
import profile1 from 'assets/img/profile1.jpg';
import profile2 from 'assets/img/profile2.jpg';
import profile3 from 'assets/img/profile3.jpg';
import profile4 from 'assets/img/profile4.jpg';
import profile5 from 'assets/img/profile5.jpg';
import forest from 'assets/img/arcq/forest.png';
import gameboy from 'assets/img/arcq/gameboy.png';
import lightTunnel from 'assets/img/arcq/lightTunnel.png';
import Environmentalism from 'assets/img/arcq/Environmentalism.jpg';
import FamilyReunion from 'assets/img/arcq/FamilyReunion.jpg';
import Parties from 'assets/img/arcq/Parties.jpg';
import Graduation from 'assets/img/arcq/Graduation.jpg';
import Timesless from 'assets/img/arcq/Timesless.jpg';
import Weather from 'assets/img/arcq/weather.jpg';
import FamilyHeritage from 'assets/img/arcq/FamilyHertiage.jpg';
import Face1 from 'assets/img/faces/face1.jpg';
import Face2 from 'assets/img/faces/face2.jpg';
import Face3 from 'assets/img/faces/face3.jpg';
import Face4 from 'assets/img/faces/face4.jpg';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const { AuthBool, classes } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    const TrendingImages = [forest, gameboy, lightTunnel];

    return (
      <div>
        {this.state.dataLoaded ? (
          <div style={{ padding: '0px 20px 0px 20px' }}>
            <GridContainer>
              <GridItem xs={12}>
                {/* Private and Public Posts */}
                <div>
                  <LibraryTabs
                    headerColor="primary"
                    tabs={[
                      {
                        tabName: 'All',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridContainer
                                style={{
                                  padding: '15px',
                                  width: '107%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '20px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '20px',
                                    }}
                                    className={classes.title}
                                  >
                                    Private Responses
                                  </h4>
                                </GridItem>

                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Face3}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          className={classes.cardImg}
                                          src={Face4}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          className={classes.cardImg}
                                          src={Face1}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          className={classes.cardImg}
                                          src={Face2}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay} />
                                      <div>
                                        <img
                                          className={classes.cardImg}
                                          src={Face3}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px',
                                  width: '107%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '20px 0px 70px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 0px 0px',
                                      fontSize: '20px',
                                    }}
                                    className={classes.title}
                                  >
                                    Archived Private Recordings
                                  </h4>
                                </GridItem>

                                <GridItem xs={4}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card
                                      style={{ margin: '5px 0px' }}
                                      className={classes.textWhite}
                                    >
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            color: '#2B2E34',
                                            padding: '2px 5px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            backgroundColor: '#FAEA61',
                                            marginTop: '110px',
                                            fontSize: '14px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Archived</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Face1}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </GridContainer>
                          </div>
                        ),
                      },
                      {
                        tabName: 'Date',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '20px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  Sunday
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{ backgroundColor: 'black' }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{ backgroundColor: 'black' }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face3}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>

                            <GridContainer
                              style={{
                                margin: '0px 0px -16px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '30px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  September 5 2019
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face1}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face4}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face3}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '10px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '10px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face1}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>
                            <GridContainer
                              style={{
                                margin: '0px 0px 50px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0',
                              }}
                            >
                              <GridItem xs={12}>
                                <h3
                                  style={{
                                    margin: '30px 0px 0px 0px',
                                    fontSize: '22px',
                                  }}
                                  className={classes.title}
                                >
                                  August 28 2019
                                </h3>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face2}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                              <GridItem xs={4}>
                                <Link
                                  to={{
                                    pathname: `/view-post`,
                                  }}
                                >
                                  <Card
                                    style={{
                                      marginBottom: '-5px',
                                      backgroundColor: 'black',
                                    }}
                                    className={classes.textWhite}
                                  >
                                    <div className={classes.cardImgOverlay} />
                                    <div>
                                      <img
                                        style={{
                                          boxShadow:
                                            '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                        }}
                                        className={classes.cardImg}
                                        src={Face4}
                                        alt="..."
                                        data-holder-rendered="true"
                                      />
                                    </div>
                                  </Card>
                                </Link>
                              </GridItem>
                            </GridContainer>
                          </div>
                        ),
                      },
                      {
                        tabName: 'Topic',
                        tabContent: (
                          <div style={{ padding: '0px 0px 15px 0px' }}>
                            <GridContainer
                              style={{
                                margin: '10px 0px -24px 0px',
                                width: '100%',
                                padding: '0px 0px 0px 0px',
                              }}
                            >
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Environmentalism
                                  </h4>
                                </GridItem>
                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Environmentalism is a broad philosophy, ideology,
                                    and social movement regarding concerns for
                                    environmental protection and improvement of the
                                    health of the environment.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Trees & Plants</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={forest}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Weather Patterns</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={Weather}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 0px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Family Heritage
                                  </h4>
                                </GridItem>

                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Family heritage is the legacy of physical
                                    artifacts and intangible attributes of a family
                                    that is inherited from past generations. Not all
                                    legacies of past generations are "heritage",
                                    rather heritage is a product of selection by
                                    society.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Family Reunions</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={FamilyHeritage}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Traditions</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={FamilyReunion}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                              <GridContainer
                                style={{
                                  padding: '15px 15px 0px 15px',
                                  width: '100%',
                                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  borderRadius: '10px',
                                  backgroundColor: '#1B3D59',
                                  margin: '15px 0px 70px 0px',
                                }}
                              >
                                <GridItem xs={12}>
                                  <h4
                                    style={{
                                      margin: '0px 10px 10px 0px',
                                      fontSize: '22px',
                                    }}
                                    className={classes.title}
                                  >
                                    Recreation
                                  </h4>
                                </GridItem>

                                <GridItem xs={12}>
                                  <p style={{ color: 'white' }}>
                                    Having fun is an art form. What do you do in your
                                    spare time? Share it with the group.
                                  </p>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Parties & Social</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          style={{
                                            boxShadow:
                                              '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                          }}
                                          className={classes.cardImg}
                                          src={Parties}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6}>
                                  <Link
                                    to={{
                                      pathname: `/view-post`,
                                    }}
                                  >
                                    <Card className={classes.textWhite}>
                                      <div className={classes.cardImgOverlay}>
                                        <h4
                                          style={{
                                            marginTop: '0px',
                                            fontSize: '16px',
                                          }}
                                          className={classes.bodyText}
                                        >
                                          <strong>Video Games</strong>
                                        </h4>
                                      </div>
                                      <div className={classes.imgDarken}>
                                        <img
                                          className={classes.cardImg}
                                          src={gameboy}
                                          alt="..."
                                          data-holder-rendered="true"
                                        />
                                      </div>
                                    </Card>
                                  </Link>
                                </GridItem>
                              </GridContainer>
                            </GridContainer>
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
            <div>
              <Hidden smDown implementation="css">
                <div
                  style={{
                    background: "#26202A",
                    position: "absolute",
                    width: "110%",
                    margin: "-25% 0% 0% -8%",
                    height: "180%",
                    zIndex: "99999",
                  }}
                >
                  <img
                    style={{
                      margin: "2% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div
                  style={{
                    background: "#26202A",
                    position: "absolute",
                    width: "110%",
                    margin: "-30% 0% 0% -10%",
                    height: "110%",
                    zIndex: "9999",
                  }}
                >
                  <img
                    style={{
                      margin: "15% 0% 0% 38%",
                      position: "relative",
                      width: "20%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              </Hidden>
            </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(Style)
)(Users);
