import forestPic from 'assets/img/arcq/forest.png';
import selfDiscoveryPic from 'assets/img/arcq/Graduation.jpg';
import weatherPic from 'assets/img/arcq/weather.jpg';
import technologyPic from 'assets/img/arcq/lightTunnel.png';
import familyHeritagePic from 'assets/img/arcq/FamilyHertiage.jpg';
import recreationPic from 'assets/img/arcq/gameboy.png';
import ai from 'assets/img/arcq/ai.jpg';
import privacy from 'assets/img/arcq/privacy.jpg';
import cuttingEdge from 'assets/img/arcq/cutting-edge.jpg';
import quantum from 'assets/img/arcq/quantum.jpg';
import telecom from 'assets/img/arcq/telecom.jpg';
import future from 'assets/img/arcq/future.jpg';
import singularity from 'assets/img/arcq/singularity.jpg';
import brain from 'assets/img/arcq/brain-to-brain.jpg';
import moore from 'assets/img/arcq/moore.jpg';
import vr from 'assets/img/arcq/vr.jpg';
import optics from 'assets/img/arcq/optics.jpg';
import rocket from 'assets/img/arcq/rockets.jpg';
import hiking from 'assets/img/arcq/hiking.jpg';
import ev from 'assets/img/arcq/ev.jpg';
import plants from 'assets/img/arcq/plants.jpg';
import impact from 'assets/img/arcq/environment.jpg';
import pollution from 'assets/img/arcq/future-concerns.jpg';
import spiritual from 'assets/img/arcq/spirituality.jpg';
import christian from 'assets/img/arcq/christian.jpg';
import hindu from 'assets/img/arcq/hindu.jpg';
import buddhism from 'assets/img/arcq/buddhism.jpg';
import jewish from 'assets/img/arcq/jewish.jpg';
import origin from 'assets/img/arcq/origin.jpg';
import heirlooms from 'assets/img/arcq/heirloom.jpg';
import traditions from 'assets/img/arcq/religion.jpg';
import community from 'assets/img/arcq/community.jpg';
import baby from 'assets/img/arcq/baby.jpg';
import selfDiscovery from 'assets/img/arcq/self-discovery.jpg';
import career from 'assets/img/arcq/career.jpg';
import inspiration from 'assets/img/arcq/inspire.jpg';
import loveRelationships from 'assets/img/arcq/relationship.jpg';
import virtues from 'assets/img/arcq/virtue.jpg';
import meaning from 'assets/img/arcq/meaning.jpg';
import legacy from 'assets/img/arcq/legacy.jpg';
import estate from 'assets/img/arcq/estate.jpg';
import willWishes from 'assets/img/arcq/will.jpg';
import conditions from 'assets/img/arcq/insight.jpg';
import reasoning from 'assets/img/arcq/thought.jpg';
import story from 'assets/img/arcq/story.jpg';
import foodDrink from 'assets/img/arcq/food.jpg';
import dieting from 'assets/img/arcq/vegetables.jpg';
import cocktails from 'assets/img/arcq/cocktail.jpg';
import beers from 'assets/img/arcq/beer.jpg';
import wine from 'assets/img/arcq/wine.jpg';
import tea from 'assets/img/arcq/tea.jpg';
import foods from 'assets/img/arcq/foods.jpg';
import vegetarian from 'assets/img/arcq/veg.jpg';
import vegan from 'assets/img/arcq/vegan.jpg';
import glutenFree from 'assets/img/arcq/gluten-free.jpg';
import kosher from 'assets/img/arcq/kosher.jpg';
import lowCarb from 'assets/img/arcq/low-carb.jpg';
import love from 'assets/img/arcq/love-choices.jpg';
import joy from 'assets/img/arcq/joy.jpg';
import peace from 'assets/img/arcq/peace.jpg';
import patience from 'assets/img/arcq/portrait.jpg';
import kindness from 'assets/img/arcq/kindness.jpg';
import wrong from 'assets/img/arcq/wrong.jpg';
import death from 'assets/img/arcq/death.jpg';
import healthHygiene from 'assets/img/arcq/clean.jpg';
import exercise from 'assets/img/arcq/exercise.jpg';
import hair from 'assets/img/arcq/hair.jpg';
import body from 'assets/img/arcq/spa.jpg';
import dental from 'assets/img/arcq/dental.jpg';
import eyeCare from 'assets/img/arcq/eye-care.jpg';
import politics from 'assets/img/arcq/america.jpg';
import conservative from 'assets/img/arcq/republican.jpg';
import liberal from 'assets/img/arcq/liberal.jpg';
import moderate from 'assets/img/arcq/senate.jpg';
import progressive from 'assets/img/arcq/progressive.jpg';
import libertarians from 'assets/img/arcq/libertarian.jpg';
import green from 'assets/img/arcq/green.jpg';
import newsEvents from 'assets/img/arcq/current-events.jpg';
import foreign from 'assets/img/arcq/travel.jpg';
import sports from 'assets/img/arcq/sports.jpg';
import golf from 'assets/img/arcq/golf.jpg';
import football from 'assets/img/arcq/football.jpg';
import hockey from 'assets/img/arcq/hockey.jpg';
import lacrosse from 'assets/img/arcq/lacrosse.jpg';
import running from 'assets/img/arcq/running.jpg';
import tvShows from 'assets/img/arcq/television.jpg';
import videoGames from 'assets/img/arcq/games.jpg';
import skiing from 'assets/img/arcq/ski.jpg';
import experiences from 'assets/img/arcq/experience.jpg';
import relationships from 'assets/img/arcq/couple.jpg';
import school from 'assets/img/arcq/school.jpg';
import friends from 'assets/img/arcq/friends.jpg';
import places from 'assets/img/arcq/places.jpg';
import influence from 'assets/img/arcq/influence.jpg';
import embarrassing from 'assets/img/arcq/embarrassed.jpg';
import success from 'assets/img/arcq/success.jpg';

export default [
  {
    category: 'Technology',
    image: technologyPic,
    description:
      'Technology is always evolving and surprising us in new and interesting ways. Many new solutions can be realized by applying technology but sometimes it is difficult to use. What is your opinion of technology?',
    topics: [
      { name: 'Artificial Intelligence', image: ai },
      { name: 'Internet Privacy Law', image: privacy },
      { name: 'Cutting Edge', image: cuttingEdge },
      { name: 'Quantum Computing', image: quantum },
      { name: 'Communications & Telecom', image: telecom },
      { name: 'Future Outlook', image: future },
      { name: 'Singularity & Tech Evolution', image: singularity },
      { name: 'Brain To Brain', image: brain },
      { name: "Moore's Law", image: moore },
      { name: 'AR Versus VR', image: vr },
      { name: 'Optics & Photography', image: optics },
      { name: 'Satellite & Rocket Science', image: rocket },
      { name: 'Electric Vehicles', image: ev },
    ],
  },
  {
    category: 'Environmentalism',
    image: forestPic,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Trees & Plants', image: plants },
      { name: 'Weather Patterns', image: weatherPic },
      { name: 'Parks & Hiking', image: hiking },
      { name: 'Life Impact', image: impact },
      { name: 'Future Concerns', image: pollution },
    ],
  },
  {
    category: 'Spiritual Beliefs',
    image: spiritual,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Christianity', image: christian },
      { name: 'Hinduism', image: hindu },
      { name: 'Buddhism', image: buddhism },
      { name: 'Jewish', image: jewish },
    ],
  },
  {
    category: 'Family Heritage',
    image: familyHeritagePic,
    description:
      'Family heritage is the legacy of physical artifacts and intangible attributes of a family that is inherited from past generations. Not all legacies of past generations are "heritage", rather heritage is a product of selection by society.',
    topics: [
      { name: 'Origins & Ancestors', image: origin },
      { name: 'Family Heirlooms', image: heirlooms },
      { name: 'Special Traditions', image: traditions },
      { name: 'Community & Values', image: community },
      { name: 'New Family Members', image: baby },
    ],
  },
  {
    category: 'Self Discovery',
    image: selfDiscoveryPic,
    popular: true,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Occupation & Career', image: career },
      { name: 'Inspiration', image: inspiration },
      { name: 'Love & Relationships', image: loveRelationships },
      { name: 'Virtues & Attitude', image: virtues },
      { name: 'Finding Meaning', image: meaning },
    ],
  },
  {
    category: 'Personal Legacy',
    image: legacy,
    popular: true,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Estate', image: estate },
      { name: 'Wills & Wishes', image: willWishes },
      { name: 'Conditions', image: conditions },
      { name: 'Reasoning', image: reasoning },
      { name: 'Grand Story', image: story },
    ],
  },
  {
    category: 'Food & Drink',
    image: foodDrink,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Dieting', image: dieting },
      { name: 'Cocktails', image: cocktails },
      { name: 'Beers', image: beers },
      { name: 'Wine', image: wine },
      { name: 'Favorite Tea', image: tea },
      { name: 'Foods', image: foods },
      { name: 'Vegetarian', image: vegetarian },
      { name: 'Vegan', image: vegan },
      { name: 'Gluten Free', image: glutenFree },
      { name: 'Kosher', image: kosher },
      { name: 'Low Carb', image: lowCarb },
    ],
  },
  {
    category: 'Ethics & Values',
    image: forestPic,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Love', image: love },
      { name: 'Joy', image: joy },
      { name: 'Peace', image: peace },
      { name: 'Patience', image: patience },
      { name: 'Kindness', image: kindness },
      { name: 'Right & Wrong', image: wrong },
      { name: 'Death', image: death },
    ],
  },
  {
    category: 'Health & Hygiene',
    image: healthHygiene,
    popular: true,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Exercise', image: exercise },
      { name: 'Hair Care', image: hair },
      { name: 'Body Care', image: body },
      { name: 'Dental Care', image: dental },
      { name: 'Eye Care', image: eyeCare },
    ],
  },
  {
    category: 'Politics',
    image: politics,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Conservative', image: conservative },
      { name: 'Liberal', image: liberal },
      { name: 'Progressive', image: moderate },
      { name: 'Moderate', image: progressive },
      { name: 'Libertarian', image: libertarians },
      { name: 'Green', image: green },
      { name: 'Current Events', image: newsEvents },
      { name: 'Foreign Affairs', image: foreign },
    ],
  },
  {
    category: 'Recreation',
    image: recreationPic,
    popular: true,
    description:
      ' Having fun is an art form. What do you do in your spare time? Share it with the group.',
    topics: [
      { name: 'Favorite Sports', image: sports },
      { name: 'Golf', image: golf },
      { name: 'Football', image: football },
      { name: 'Hockey', image: hockey },
      { name: 'Lacrosse', image: lacrosse },
      { name: 'Running', image: running },
      { name: 'TV Shows', image: tvShows },
      { name: 'Video Games', image: videoGames },
      { name: 'Skiing & Snowboarding', image: skiing },
    ],
  },
  {
    category: 'Experiences',
    image: experiences,
    popular: true,
    description:
      'Environmentalism or environmental rights is a broadphilosophy, ideology,and social movement regardingconcerns for environmental protection and improvement ofthe health of the environment.',
    topics: [
      { name: 'Relationships', image: relationships },
      { name: 'School & Class', image: school },
      { name: 'Best Friends', image: friends },
      { name: 'Favorite Places', image: places },
      { name: 'Influential Events & People', image: influence },
      { name: 'Most Embarrassing Moments', image: embarrassing },
      { name: 'Most Successful Endeavors', image: success },
    ],
  },
];
