import React from "react";

import AddIcon from '@material-ui/icons/Add';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

const songTitles = [
    'Meant To Go',
    'Reckoning',
    'Yesterday Dont Mean',
];

class FinancingForm extends React.Component {

    constructor(props) {
        super(props);

        const formdata = {};

        songTitles.forEach(
            (service) => (formdata[service] = { numberOfStreams: 0, percentageOwned: 0 })
        );

        this.state = {
            formdata,
            errors: {},
            songTitle: '',
            royalties: 0,
            pro: 0,
            totalStreamsAmount: 0,
            averagePercentageOwned: 0,
        };
    }

    handleEdit(service, fieldName, e) {
        const { formdata, errors } = this.state;
        const data = formdata[service];
        let error = errors[service];
        let value = Number(e.target.value);
        if (!value) {
            error = { ...error, [fieldName]: 'cannot parse value' };
            value = e.target.value;
        } else {
            error = { ...error, [fieldName]: undefined };
        }
        this.setState({
            formdata: { ...formdata, [service]: { ...data, [fieldName]: value } },
            errors: { ...errors, [service]: error },
        });
    }

    getErrors(service) {
        return this.state.errors[service] || {};
    }

    calculateRevenue() {
        let revenueFromStreams = this.state.totalStreamsAmount * 0.4;
        return revenueFromStreams * (this.state.averagePercentageOwned * 0.01);
    }

    renderRow([service, item]) {
        const inputColor = this.getErrors(service).numberOfStreams
            ? '#f00'
            : '#000';

        return (
            <tr key={service}>
                <td>
                    <input type="text"
                        name={service}
                        value={item.songTitle}
                        onChange={(e) => this.handleEdit(service, 'songTitles', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: inputColor, width: '50vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </td>
                <td>
                    <input
                        type="number"
                        name={service + 'Royalties'}
                        value={item.royalties}
                        onChange={(e) => this.handleEdit(service, 'royalties', e)}
                        style={{ background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)', color: inputColor, width: '20vw', height: '5vh', border: '1px solid #fff', borderRadius: '3px' }}
                    />
                </td>
                <td>
                    <center>
                        <input
                            type="number"
                            name={service + 'pro'}
                            value={item.pro}
                            onChange={(e) => this.handleEdit(service, 'pro', e)}
                            style={{
                                background: 'linear-gradient(122deg, rgba(221, 221, 223, 1) 0%, rgba(167, 167, 167, 1) 100%)',
                                color: inputColor,
                                width: '25vw',
                                height: '5vh', border: '1px solid #fff', borderRadius: '3px'
                            }}
                        />
                    </center>
                </td>
            </tr>
        );
    }

    // Rewrite based on new formula based on royalities and PRO # (not # of streams and percentage owned)
    handleSubmit = (e) => {
        e.preventDefault();

        let totalStreamsAmount = 0;
        let totalPercentageOwned = 0;

        for (const item of Object.values(this.state.formdata)) {
            totalStreamsAmount += item.numberOfStreams || 0;
            totalPercentageOwned +=
                (item.percentageOwned || 0) * (item.numberOfStreams || 0);
        }

        this.setState({
            totalStreamsAmount,
            averagePercentageOwned: totalPercentageOwned / totalStreamsAmount,
        });
    };

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} style={{ color: '#f26599', display: 'flex', margin: '0 0 0 15px' }}>
                        <h2><b>Add New</b></h2>
                        <AddIcon style={{ backgroundColor: '#f26599', color: '#fff', borderRadius: '50%', margin: '45px 0 0px 15px' }} />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Song Title</th>
                                    <th>Royalties</th>
                                    <th>PRO #</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(this.state.formdata).map((entry) =>
                                    this.renderRow(entry)
                                )}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                            <button
                                style={{
                                    width: '40vw',
                                    margin: '20px 0 20px 0',
                                    height: '60px',
                                    border: '1px solid black',
                                    fontSize: '15px',
                                }}
                                onClick={(e) => this.handleSubmit(e)}>
                                Clear
                        </button>
                            <button
                                style={{
                                    width: '40vw',
                                    margin: '20px 0 20px 0',
                                    height: '61px',
                                    border: '2px solid #fff',
                                    fontSize: '15px',
                                    color: '#fff',
                                    backgroundColor: '#6abc43'
                                }}
                                onClick={(e) => this.handleSubmit(e)}>
                                Calculate
                        </button>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={6}>
                        <Card
                            style={{
                                background: 'linear-gradient(122deg, rgba(125,125,235,1) 0%, rgba(112,33,213,1) 100%)',
                                borderRadius: '10px',
                                height: '15vh',
                                margin: '20px 0 0 0',
                                color: '#fff',
                            }}
                        >
                            <center>
                                <p>Calculated Total Revenue/ Year</p>
                            </center>
                        </Card>
                    </GridItem>
                    <GridItem xs={6}>
                        <Card
                            style={{
                                background: 'linear-gradient(273deg, rgba(125,125,235,1) 0%, rgba(112,33,213,1) 100%)',
                                borderRadius: '10px',
                                height: '15vh',
                                margin: '20px 0 0 0',
                                color: '#fff',
                            }}
                        > 
                        <center>
                                <p>Calculated Monthly Payouts</p>
                        </center>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card
                            style={{
                                background: 'linear-gradient(273deg, rgba(125,125,235,1) 0%, rgba(112,33,213,1) 100%)',
                                borderRadius: '10px',
                                height: '15vh',
                                margin: '20px 0 0 0',
                                color: '#fff',
                            }}
                        ><center>
                                <p>Calculated Total Streams</p>
                         </center>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                    <button
                        style={{
                            width: '28vw',
                            margin: '20px 0 20px 0',
                            height: '60px',
                            border: '1px solid black',
                            fontSize: '15px',
                        }}
                        onClick={(e) => this.handleSubmit(e)}>
                        Clear
                        </button>
                    <button
                        style={{
                            width: '28vw',
                            margin: '20px 0 20px 0',
                            height: '60px',
                            border: '1px solid black',
                            fontSize: '15px',
                        }}
                        onClick={(e) => this.handleSubmit(e)}>
                        Save
                        </button>
                    <button
                        style={{
                            width: '28vw',
                            margin: '20px 0 20px 0',
                            height: '61px',
                            border: '2px solid #fff',
                            fontSize: '15px',
                            color: '#fff',
                            backgroundColor: '#6abc43'
                        }}
                        onClick={(e) => this.handleSubmit(e)}>
                        Submit
                        </button>
                </div>
            </div>
        );
    }
}

export default FinancingForm;

