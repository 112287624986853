import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from "components/Card/Card.jsx";

// Opens on click from table on FinancingPage

const PreviousEstimate = () => {
    return (
        <Card
            style={{
                backgroundColor: '#223380',
                borderRadius: '10px',
                color: '#fff',
                padding: '20px'
            }}
        >
            <h3><b>Previous Estimate</b></h3>
            <h5>03/01/2020</h5>
            <center>
                <GridItem xs={10} style={{ backgroundColor: '#5476d3', borderRadius: '5px' }}>
                    <div style={{ padding: '7px', margin: '10px 0 0 0' }}>
                        <h3>$103,190.00</h3>
                        <h5>Calaculate Total Revenue/Year</h5>
                    </div>
                </GridItem>
            </center>
            <center>
                <GridItem xs={10} style={{ backgroundColor: '#5476d3', borderRadius: '5px' }}>
                    <div style={{ padding: '7px', margin: '10px 0 0 0' }}>
                        <h3>$7,810.00</h3>
                        <h5>Calculated Monthly Payouts</h5>
                    </div>
                </GridItem>
            </center>
            <center>
                <GridItem xs={10} style={{ backgroundColor: '#5476d3', borderRadius: '5px' }}>
                    <div style={{ padding: '7px', margin: '10px 0 0 0' }}>
                        <h3>50,991,029</h3>
                        <h5>Calculated Total Streams</h5>
                    </div>
                </GridItem>
            </center>
                <button style={{ background: '#fff', color: '#000', border: '2px solid #000', margin: '10px 0 0 0', width: '40%', height: '6vh' }}>DELETE</button>
        </Card>
    )
}

export default PreviousEstimate;