// LTR STYLES
// Color Pallatte
const jetEngineBlack = '#1e1e1e';
const freightlinerGrey = '#282828';
const greenLight = '#7ddb46';
const snowWhite = '#f2f2f2';
const serviceBlue = '#18283f';
const unTealWeMeet = '#24b681';

const cardStyles = {
  background: jetEngineBlack,
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const header1 = {
  color: snowWhite,
  justify: 'center',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: '500',
  minHeight: '48px',
  fontFamily: `"Oswald", "Times New Roman", serif`,
};

const header3 = {
  ...header1,
  minHeight: '21px',
};

const bodyText = {
  color: snowWhite,
  fontWeight: '300',
  fontFamily: `"Monda", "Times New Roman", serif`,
};

const linkText = {
  ...bodyText,
  textDecoration: 'underline',
  color: greenLight,
  '&:hover,&:focus': {
    color: unTealWeMeet,
  },
  '&:visited': {
    color: serviceBlue,
  },
};

const ltrButton = {
  backgroundColor: 'transparent',
  border: '2px solid ' + greenLight,
  borderRadius: 0,
  ...header3,
  fontSize: '18px',
  padding: '10px 60px',
  '&:hover,&:focus': {
    backgroundColor: greenLight,
  },
  '&:active': {
    backgroundColor: unTealWeMeet,
    transition: 'none',
  },
};

const tableStyles = {};

export {
  bodyText,
  cardStyles,
  freightlinerGrey,
  greenLight,
  header1,
  header3,
  jetEngineBlack,
  linkText,
  ltrButton,
  serviceBlue,
  snowWhite,
  unTealWeMeet,
  tableStyles,
};
