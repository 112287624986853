import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import carouselStyle from "assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx";
import image1 from "assets/img/arcq/forest.png";
import image2 from "assets/img/arcq/gameboy.png";
import image3 from "assets/img/arcq/lightTunnel.png";
import trending1 from "assets/img/faces/face6.jpg";
import trending2 from "assets/img/faces/face5.jpg";
import trending3 from "assets/img/faces/face4.jpg";

class SectionCarousel extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    // boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.75)"
    return (
      <Carousel
        style={{ borderRadius: "5px", height: "550px" }}
        {...settings}
        id="home"
      >
        <Link
          to={{
            pathname: `/view-post`
          }}
        >
          <div style={{ borderRadius: "10px" }}>
            <img
              style={{ padding: "5px", height: "550px", borderRadius: "10px" }}
              src={trending1}
              alt="Slide1"
              className="slick-image"
            />
            <Carousel />
          </div>
        </Link>
        <Link
          to={{
            pathname: `/view-post`
          }}
        >
          <div style={{ borderRadius: "10px" }}>
            <img
              style={{ padding: "5px", height: "550px", borderRadius: "10px" }}
              src={trending2}
              alt="Slide2"
              className="slick-image"
            />
          </div>
        </Link>
        <Link
          to={{
            pathname: `/view-post`
          }}
        >
          <div style={{ borderRadius: "10px" }}>
            <img
              style={{ padding: "5px", height: "550px", borderRadius: "10px" }}
              src={trending3}
              alt="Slide3"
              className="slick-image"
            />
          </div>
        </Link>
      </Carousel>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);
