import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import Notifications from '@material-ui/icons/Notifications';
import Chat from '@material-ui/icons/Chat';
import logo from 'assets/img/logos/musiclear-logo.png';

// core components
import HeaderLinks from './HeaderLinks';
import Button from 'components/CustomButtons/Button.jsx';

import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';

function Header(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      console.log('openNotification was true!');
      setOpenNotification(null);
    } else {
      console.log(
        'openNotification && openNotification.contains(event.target) were not true'
      );
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  function makeBrand() {
    var name;
    // Check if the screen name was set in the app state
    if (props.screenName) {
      return props.screenName;
    }
    const { pathname } = props.location;
    props.routes.map((prop) => {
      if (prop.collapse) {
        prop.views.map((prop) => {
          if (prop.path === pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      var pathnamePart = pathname.split('/');
      var routePathPart = prop.path.split('/');
      if (routePathPart[1] === pathnamePart[1] && !pathnamePart[3]) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      var n = pathname.lastIndexOf('/');
      var id = pathname.substring(n + 1);

      if (props.users.dataRows !== undefined) {
        //let index = props.users.dataRows.indexOf(id);
        // console.log(pathname)
        if (pathname.includes('clientthreads')) {
          return 'Client Thread';
        } else if (pathname.includes('threads')) {
          let obj = props.users.dataRows.find((o) => o.volUID === id);
          console.log(props.users.dataRows);
          console.log(obj);
          return obj.firstName + ' ' + obj.lastName;
        } else if (pathname.includes('case')) {
          return 'Case #' + id.slice(0, 5);
        }
      }
      return 'ARCQ Social AI';
    }
  }
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  var pathname = props.location.pathname;
  var n = pathname.lastIndexOf('/');
  var id = pathname.substring(n + 1);

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      {/* <img
        src={logo}
        style={{ margin: '-1px 28px 0px 0px', width: '19%' }}
        alt="logo"
        className={classes.img}
      /> */}
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                style={{ background: '#6ABC43' }}
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList
                  style={{ color: '#FFFFFF' }}
                  className={classes.sidebarMiniIcon}
                />
              </Button>
            ) : (
              <Button
                style={{ background: '#6ABC43' }}
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert
                  style={{ color: '#FFFFFF' }}
                  className={classes.sidebarMiniIcon}
                />
              </Button>
            )}
          </div>
        </Hidden>
        {pathname.includes('case') ? (
          <div>
            {pathname.includes('cases') ? null : (
              <Link
                to={{
                  pathname: `/casechat/${id}`,
                  state: { dataLoaded: false },
                }}
              >
                <Button
                  className={classes.appResponsive}
                  color="transparent"
                  justIcon
                  aria-label="open drawer"
                >
                  <Chat style={{ color: '#fff' }} />
                </Button>
              </Link>
            )}
          </div>
        ) : null}
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener
                  onClickAway={() => setTimeout(handleCloseNotification)}
                >
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? 'إجلاء أوزار الأسيوي حين بل, كما'
                        : 'Mike John responded to your email!'}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? 'شعار إعلان الأرضية قد ذلك'
                        : 'You have 5 new tasks'}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive
                        ? 'ثمّة الخاصّة و على. مع جيما'
                        : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'قد علاقة' : 'Another Notification'}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={dropdownItem}
                    >
                      {rtlActive ? 'قد فاتّبع' : 'Another One'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button
            href="#"
            className={classes.title}
            style={{ padding: '0px 0px 0px 15px' }}
            color="transparent"
          >
            <b>{makeBrand()}</b>
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          {/* <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? 'notification-menu-list' : null}
            aria-haspopup="true"
            onClick={handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : '',
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
              }
            />
            <span className={classes.notifications}>5</span> */}
          {/*<Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {rtlActive ? "إعلام" : "Notification"}
            </span>
          </Hidden>*/}
          {/* </Button> */}

          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu style={{ color: '#fff' }} />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { screenName } = state || {};
  return { screenName };
};

export default compose(
  connect(mapStateToProps),
  withStyles(headerStyle)
)(Header);
