import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withStyle from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';

export const FollowedUser = ({ classes, data: { id, avatar } }) => (
  <Link
    to={{
      pathname: `/user-profile/${id}`,
    }}
  >
    <img
      style={{ width: '60px', height: '60px', borderRadius: '100%' }}
      className={classes.profilePic}
      src={avatar}
      alt="User Photo"
    />
  </Link>
);

FollowedUser.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyle(Style)(FollowedUser);
