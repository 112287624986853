import {
  getFirestoreData,
  updateFirestoreWithOneObject,
} from 'store/actions/firestoreActions.jsx';
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx';
import moment from 'moment';
import { db, firebase } from 'firebase/fbConfig.js';
import avatar from 'assets/img/faces/user.png';

// Get Users for Users Panel
export const startSetUsersList = () => {
  return (dispatch) => {
    db.collection('TEST-users')
      .get()
      .then(
        (snapshot) => {
          const data = [];
          // Parse data into array like in firebase.js
          snapshot.forEach((doc) => {
            var docData = doc.data();

            var groups;
            if (docData.groups) {
              docData.groups.length === 0
                ? (groups = 'no groups')
                : docData.groups.forEach((group) => {
                    groups ? (groups += ', ' + group) : (groups = group);
                  });
            }

            var termGroups;
            if (docData.termGroups) {
              docData.termGroups.length === 0
                ? (termGroups = 'no term groups')
                : docData.termGroups.forEach((termGroup) => {
                    termGroups
                      ? (termGroups += ', ' + termGroup)
                      : (termGroups = termGroup);
                  });
            }

            var docObj = {
              volUID: doc.id,
              firstName: docData.firstName ? docData.firstName : 'N/A',
              lastName: docData.lastName ? docData.lastName : 'N/A',
              displayName: docData.displayName,
              phone: docData.phone ? docData.phone : 'N/A',
              email: docData.email,
              profileURL: docData.profileURL ? docData.profileURL : avatar,
              county: docData.county ? docData.county : 'N/A',
              precinct: docData.precinct ? docData.precinct.toString() : 'N/A',
              userType: docData.userType ? docData.userType.toString() : 'N/A',
              groups,
              termGroups,
              score: docData.score,
            };
            data.push(docObj);
          });
          return data;
        },
        (error) => {
          console.log('error fetching data: ', error);
        }
      )
      .then((data) => {
        dispatch(setUsersList(data));
      });
  };
};

export const setUsersList = (dataRows) => ({
  type: 'SET_ADMIN_USERS_LIST',
  dataRows,
});
