import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import categoryTopics from 'config/categoryTopics';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CategoryHeader from './CategoryHeader';
import CategorySearch from './CategorySearch';

const Category = ({
  classes,
  match: {
    params: { category },
  },
}) => {
  const entry = categoryTopics.find((e) => e.category === category);
  const { topics, description } = entry || {};

  return !topics || !topics.length ? (
    <div
      style={{
        color: 'white',
        fontWeight: 'bold',
        fontSize: '26px',
        textAlign: 'center',
        marginTop: '20px',
      }}
    >
      Page not found
    </div>
  ) : (
    <div style={{ padding: '0px 20px 80px 20px' }}>
      <CategoryHeader title={category} description={description} />
      {/* <CategorySearch /> */}
      {/* Topics Cards */}
      <br/>
      <GridContainer>
        {topics.map((e) => (
          <GridItem key={e.name} xs={6}>
            <Link to={`/topic-detail/${e.name}`}>
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{
                      marginTop: '120px',
                      fontSize: '16px',
                    }}
                    className={classes.bodyText}
                  >
                    <strong>{e.name}</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    style={{
                      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                    }}
                    className={classes.cardImg}
                    src={e.image}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

Category.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withStyles(Style)(Category);
