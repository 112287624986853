import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AllInclusiveOutlinedIcon from '@material-ui/icons/AllInclusiveOutlined';
import withStyle from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';

export const ButtonFloating = ({ classes, text }) => (
  <div style={{ margin: '0px 20% 0px 20%' }}>
    <Link
      to={{
        pathname: '/initialize-chatbot',
      }}
    >
      <button className={classes.eternal}>
        <span style={{ margin: '0px 0px 37px 0px' }}>{text}</span>
        <AllInclusiveOutlinedIcon style={{ margin: '0px 0px -7px 15px' }} />
      </button>
    </Link>
  </div>
);

ButtonFloating.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyle(Style)(ButtonFloating);
