// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  darkColor,
  linkText
} from "assets/jss/material-dashboard-pro-react.jsx";
import { title } from "../../material-dashboard-pro-react";

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  img: {
    width: "97%",
    margin: "0px 0px 0px 0px"
  },
  login: {
    width: "97%",
    margin: "0px 0px 0px 0px"
  },
  formContainer: {
    margin: "0 auto"
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
    fontSize: "24px"
  },
  textCenter: {
    color: darkColor,
    textAlign: "center"
  },
  linkText,
  textDanger: {
    fontSize: "12px",
    color: dangerColor,
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: whiteColor
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  modal: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  modalCloseButton: {
    color: darkColor
  },
  modalTitle: {
    ...title,
    fontWeight: "900",
    textAlign: "center"
  },
  modalCloseButton: {},
  subdescription: {
    color: "#1C1B47",
    fontSize: "1rem",
    textAlign: "center"
  }
});

export default loginPageStyle;
