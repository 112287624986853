import React, { useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import userEvents from 'config/user_events';
import DoubleButton from 'components/DoubleButton';
import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';

const fees = userEvents.filter((e) => e.type === 'fee');
const rewards = userEvents.filter((e) => e.type === 'reward');
const lists = [rewards, fees];
const titles = ['Earn tokens by...', 'Spend tokens to...'];
const operators = ['+', '-'];

const RewardsFees = ({ classes }) => {
  const [index, setIndex] = useState(0);

  const onSelect = (ind) => {
    setIndex(ind);
  };

  return (
    <div style={{ marginBottom: '80px' }}>
      <div style={{ margin: '20px' }}>
        <DoubleButton labels={['Rewards', 'Fees']} onSelect={onSelect} />
      </div>
      <GridContainer style={{ margin: '0px 20px' }}>
        <GridItem xs={12}>
          <h3
            style={{ margin: '0px 0px 0px -10px', fontSize: '22px' }}
            className={classes.title}
          >
            {titles[index]}
          </h3>
        </GridItem>
      </GridContainer>
      {lists[index].map((item) => (
        <GridContainer key={item.event} style={{ margin: '10px' }}>
          <GridItem xs={2}>
            <Card
              style={{
                marginTop: '18px',
                padding: '11px 0px 0px 0px',
                position: 'relative',
                zIndex: '36',
                backgroundColor: '#89c89c',
              }}
            >
              <p
                style={{
                  color: 'white',
                  fontWeight: '800',
                  fontSize: '18px',
                  textAlign: 'center',
                }}
              >
                {operators[index]} {item.tokens}
              </p>
            </Card>
          </GridItem>
          <GridItem xs={10}>
            <Card
              style={{
                position: 'relative',
                zIndex: '35',
                margin: '9px 0px 0px -45px',
                padding: '3px 0px 3px 50px',
                backgroundColor: '#85ADA1',
              }}
            >
              <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                <h5
                  style={{
                    color: 'white',
                    fontWeight: '600',
                    textAlign: 'left',
                  }}
                >
                  {item.label}
                </h5>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ))}
      <br/><br/><br/><br/><br/>
    </div>
  );
};

RewardsFees.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(RewardsFees);
