import React from 'react';
import PropTypes from 'prop-types';
import EjectIcon from '@material-ui/icons/Eject';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const PostActions = ({
  isOwner,
  postStatus,
  setPostStatus,
  toggleComments,
}) => (
  <div
    style={{
      textAlign: 'center',
      position: 'fixed',
      zIndex: '1030',
      bottom: '75px',
      right: '15px',
      color: 'white',
    }}
  >
    {// Only show if the current user is the owner of the post
    isOwner &&
      [
        { status: 'public', label: 'Make public', icon: <VisibilityIcon /> },
        { status: 'private', label: 'Make private', icon: <VisibilityOffIcon /> },
        { status: 'archived', label: 'Archive', icon: <SaveAltIcon /> },
      ].map(
        (e) =>
          postStatus !== e.status && (
            <div
              key={e.status}
              onClick={() => setPostStatus(e.status)}
              style={{ marginBottom: '5px' }}
            >
              {e.icon}
              <div style={{ position: 'relative', marginTop: '-5px' }}>
                {e.label}
              </div>
            </div>
          )
      )}
    <div onClick={toggleComments}>
      <EjectIcon />
      <div style={{ position: 'relative', marginTop: '-10px' }}>Comments</div>
    </div>
  </div>
);

PostActions.propTypes = {
  isOwner: PropTypes.bool,
  postStatus: PropTypes.string.isRequired,
  setPostStatus: PropTypes.func.isRequired,
  toggleComments: PropTypes.func.isRequired,
};

export default PostActions;
