import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

// Icons
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import PollIcon from '@material-ui/icons/Poll';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FlareIcon from '@material-ui/icons/Flare';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  // var anchor =
  //   classes.a +
  //   cx({
  //     [' ' + classes.whiteColor]: white,
  //   });
  return (
    <footer className={classes.footer} style={{ width: '100%', margin: '20px 0 0 0' }}>
      <div className={container}>
        <div
          className={classes.right}
          style={{ textAlign: 'center', width: '100%', padding: '1px' }}
        >
          <GridContainer justify="center" style={{ margin: '18px 10px 10px -10px' }}>
            <GridItem xs={2}>
              <Link
                to={{
                  pathname: '/forecasting',
                }}
              >
                <BusinessIcon
                  style={{ color: '#FFF', height: '1.25em', width: '1.25em' }}
                />
              </Link>
            </GridItem>
            <GridItem xs={2}>
              <Link
                to={{
                  pathname: '/platform-analytics',
                }}
              >
                <PollIcon
                  style={{ color: '#FFF', height: '1.25em', width: '1.25em' }}
                />
              </Link>
            </GridItem>
            <GridItem xs={2}>
              <Link
                to={{
                  pathname: '/wallet',
                }}
              >
                <AccountBalanceWalletIcon
                  style={{ color: '#FFF', height: '1.25em', width: '1.25em' }}
                />
              </Link>
            </GridItem>
            <GridItem xs={2}>
              <Link
                to={{
                  pathname: '/pick-oracle',
                }}
              >
                <FlareIcon
                  style={{ color: '#FFF', height: '1.25em', width: '1.25em' }}
                />
              </Link>
            </GridItem>
            <GridItem xs={2}>
              <Link
                to={{
                  pathname: '/profile',
                }}
              >
                <PersonIcon
                  style={{ color: '#FFF', height: '1.25em', width: '1.25em' }}
                />
              </Link>
            </GridItem>
          </GridContainer>
          {/* <FooterText anchor={anchor} rtlActive={rtlActive} /> */}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
