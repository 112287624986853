import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import TokenTransactionItem from 'components/TokenTransactionItem';
import Loader from 'components/Loader';
import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';

// images
import Token from 'assets/img/arcq/arq-token.png';

const Wallet = ({ uid, classes, events }) => {
  const [earned, spent] = events.reduce(
    (r, v) => {
      const fee = v.type === 'fee';
      r[fee ? 1 : 0] += v.tokens;
      return r;
    },
    [0, 0]
  );

  return !uid ? (
    <Loader />
  ) : (
    <div>
      <GridContainer style={{ padding: '0px 20px', margin: '50px 0px 0px -15px' }}>
        <Card
          style={{
            background:
              'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
          }}
        >
          <CardBody>
            <GridContainer>
              <GridItem xs={3} sm={3} md={3}>
                {/* <img
                  src={Token}
                  style={{
                    width: '100%',
                    padding: '12px',
                    margin: '-10px 0px 0px 0px',
                  }}
                  alt="USD"
                /> */}
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <h4
                  style={{
                    color: 'white',
                    fontSize: '30px',
                    fontWeight: '600',
                    display: 'inline-block',
                    marginRight: '5px',
                  }}
                  className={classes.cardTitle}
                >
                  ${earned - spent}{' '}
                </h4>
                <span
                  style={{
                    display: 'inline-block',
                    color: 'white',
                    fontWeight: '600',
                    fontSize: '25px',
                  }}
                >
                  {' '}
                  USD
                </span>
                {/* <h6
                  style={{ fontSize: '20px', fontWeight: '400' }}
                  className={classes.cardSubtitle}
                >
                  ${(earned - spent)}{' '}
                  <span style={{ fontSize: '15px' }}>USD</span>
                </h6> */}
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                {/* <a href="https://www.arcq.app/shop/">
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      margin: '-3px 5px 0px 0px',
                      padding: '9px 0px 8px 3px',
                      float: 'right',
                      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.5)',
                      background:
                        'linear-gradient(33deg, rgba(148,207,155,1) 0%, rgba(244,232,101,1) 100%)',
                    }}
                  >
                    <AddIcon style={{ fontSize: '25px' }} />
                  </Button>
                </a> */}
                {/* </Link> */}
                {/* <br />
                <br />
                <p
                  style={{
                    marginRight: '5px',
                    float: 'right',
                    fontSize: '12px',
                    color: 'white',
                  }}
                >
                  Withdraw
                </p> */}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
      <GridContainer style={{ marginTop: '-20px', padding: '0px 20px' }}>
        <GridItem xs={4} sm={4} md={4}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              padding: '5%',
            }}
          >
            <CardHeader color="rose" icon>
              <h4
                style={{
                  color: 'white',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
                className={classes.cardTitle}
              >
                <b>{earned - spent}</b>
              </h4>
              <p
                style={{
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
              >
                <small>Earned</small>
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <Card style={{ backgroundColor: '#FAEA61', padding: '5%' }}>
            <CardHeader color="info" icon>
              <h4
                style={{
                  color: '#2B2E34',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
                className={classes.cardTitle}
              >
                <b>{Math.floor(Math.random() * 100 + 1)}%</b>
              </h4>
              <p
                style={{
                  color: '#2B2E34',
                  textAlign: 'center',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
              >
                <small>Approved</small>
              </p>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <Card
            style={{
              background:
                'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
              padding: '5%',
            }}
          >
            <CardHeader color="rose" icon>
              <h4
                style={{
                  color: 'white',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
                className={classes.cardTitle}
              >
                <b>{spent}</b>
              </h4>
              <p
                style={{
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
              >
                <small>Spent</small>
              </p>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ margin: '0px 20px 0px 20px' }}>
        <GridItem xs={12}>
          <h3
            style={{ margin: '0px 0px 0px -10px', fontSize: '22px' }}
            className={classes.title}
          >
            Transactions
          </h3>
        </GridItem>
      </GridContainer>
      {events
        .filter((item) => item.type === 'reward' || item.type === 'fee')
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((item) => (
          <TokenTransactionItem
            key={item.createdAt}
            label={item.label}
            tokens={item.tokens}
            isReward={item.type === 'reward'}
          />
        ))}
      <GridContainer style={{ margin: '0px 20px 80px 20px' }}>
        <GridItem xs={12} sm={12} md={12}>
          <Link
            to={{
              pathname: '/rewards-fees',
            }}
          >
            <Button
              style={{
                width: '90%',
                margin: '15px 0px 0px -8px',
                padding: '18px 20px',
                borderRadius: '10px',
                fontSize: '16px',
                background:
                  'linear-gradient(0deg, rgba(43,43,116,1) 0%, rgba(92,52,143,1) 100%)',
                textTransform: 'capitalize',
              }}
            >
              Learn How You Can Earn More Musiclear Utilities
            </Button>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { uid, events } = state.profile || {};
  return { uid, events };
};

Wallet.defaultProps = {
  events: [],
};

Wallet.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tokens: PropTypes.number.isRequired,
    })
  ),
};

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Wallet);
