import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Loader from 'components/Loader';
import UserVideos from 'components/UserVideos';
import FloatingButton from 'components/ButtonFloating';
import store from 'services/StoreService';
import ProfileViewPic from './ProfileViewPic';
import ProfileViewBioSummary from './ProfileViewBioSummary';
import UserProfileViewButtons from './UserProfileViewButtons';
import ProfileViewFollowerdUsers from './ProfileViewFollowerdUsers';
import ProfileViewPostActivity from './ProfileViewPostActivity';
import DefaultAvatar from 'assets/img/default-avatar.png';
import Hidden from '@material-ui/core/Hidden';

const UserProfile = ({ uid, followUser, unFollowUser, isAConnection }) => {
  const [user, setUser] = useState(null);

  const { recordings, following } = user || {};

  useEffect(
    () => {
      loadData();
    },
    [uid]
  );

  const loadData = async () => {
    try {
      const user = await store.getUserData(uid);
      await setUser(user);
    } catch (error) {
      console.log('error fetching data: ', error);
    }
  };

  const connectUser = () => {
    followUser({
      id: user.uid,
      avatar: user.avatar || DefaultAvatar,
      lastName: user.lastName || null,
      firstName: user.firstName || null,
      displayName: user.displayName || `${user.firstName} ${user.lastName}`,
    });
  };

  const unConnectUser = () => {
    unFollowUser(uid);
  };

  return user ? (
    <div>
      {/* Desktop   */}
      <Hidden xsDown implementation="css">
        <GridContainer style={{overflowX: 'hidden'}}>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <ProfileViewPic avatar={user.avatar} />
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <GridContainer>
              <GridItem xs={12}>
                <div style={{ margin: '20px 10px 0px 25px' }}>
                <ProfileViewBioSummary user={user} editable={false} />
                </div>
                <div style={{ padding: '0px 20px' }}>
                  <ProfileViewPostActivity recordings={recordings || []} />
                </div>
                <div style={{ margin: '140px 10px 0px 25px' }}>
                  <UserProfileViewButtons
                    connected={isAConnection(uid)}
                    connectUser={connectUser}
                    unConnectUser={unConnectUser}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <UserVideos data={recordings || []} />
          </GridItem>
        </GridContainer>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp implementation="css">
        <GridContainer style={{overflowX: 'hidden'}}>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <ProfileViewPic avatar={user.avatar} />
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <ProfileViewBioSummary user={user} editable={false} />
            <div style={{ margin: '50px 0 0 0' }}>
              <UserProfileViewButtons
                connected={isAConnection(uid)}
                connectUser={connectUser}
                unConnectUser={unConnectUser}
              />
            </div>
            <ProfileViewPostActivity recordings={recordings || []} />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <UserVideos data={recordings || []} />
          </GridItem>
        </GridContainer>
      </Hidden>

      {/* <GridContainer>
            <GridItem xs={12}>
              <FloatingButton text="Chat with user" />
            </GridItem>
          </GridContainer> */}
    </div>
  ) : (
    <Loader />
  );
};

UserProfile.propTypes = {
  uid: PropTypes.string.isRequired,
  followUser: PropTypes.func.isRequired,
  unFollowUser: PropTypes.func.isRequired,
  isAConnection: PropTypes.func.isRequired,
};

export default UserProfile;
