import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FinancingForm from '../../Forms/FinancingForm.jsx';
import Card from "components/Card/Card.jsx";
import LabelsCarousel from './Sections/LabelsCarousel.jsx';
import Table from "components/Table/Table.jsx";
import PreviousEstimate from './PreviousEstimate.jsx'

export const FinancingPage = () => {
    return (
        <div>
            <FinancingForm />
            <GridContainer>
                <GridItem xs={12}>
                    <Card
                        style={{
                            backgroundColor: '#212dd5',
                            borderRadius: '10px',
                            height: '42vh',
                            margin: '20px 0 0 0',
                            color: '#fff',
                        }}
                    >
                        <center>
                            <h3>Recommended Labels</h3>
                            <p>Based on your stats</p>
                        </center>
                        <LabelsCarousel />
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ background: 'linear-gradient(180deg, rgba(0,193,57,1) 0%, rgba(1,101,3,1) 100%)', color: '#fff', padding: '20px' }}>
                <h3><b>Previous Estimates</b></h3>
                {/* When 'View' option is clicked, open PreviousEstimate modal */}
                <Table
                    tableHead={['Date', 'Total Revenue', 'Action']}
                    tableData={[
                        ['03/01/20', '$75,681.98', 'Delete'],
                        ['07/22/20', '$50,029.21', 'View'],
                        ['09/09/20', '$78,028.11', 'View'],

                    ]}
                />
            </GridContainer>
            <PreviousEstimate />
        </div>
    )
};

export default FinancingPage;