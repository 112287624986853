import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';

// core components
import FormControl from '@material-ui/core/FormControl';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import store from 'services/StoreService';

import Style from 'assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx';

import { updateUser } from 'store/actions/profileActions.jsx';

// Assets
import precincts from 'variables/precincts.jsx';

class Form extends React.Component {
  componentDidMount = () => {
    var inputElement = document.getElementById('phone');
    inputElement.addEventListener('keydown', this.enforceFormat);
    inputElement.addEventListener('keyup', this.formatToPhone);
  };

  componentWillUnmount = () => {
    var inputElement = document.getElementById('phone');
    inputElement.removeEventListener('keydown', this.enforceFormat);
    inputElement.removeEventListener('keyup', this.formatToPhone);
  };

  //Phone number code modified from
  // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
  isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let targetValue;
    if (input.length > 6) {
      targetValue = `(${zip}) ${middle} - ${last}`;
    } else if (input.length > 3) {
      targetValue = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      targetValue = `(${zip}`;
    }
    target.value = targetValue;
    this.setState({
      phone: targetValue,
    });
  };

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.id === 'firstName' || e.target.id === 'lastName') {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    this.setState({
      [e.target.id]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // If empty then nothing to update
    if (isEmpty(this.state)) return this.props.onSave();
    // Update database
    await store.updateUser(this.props.uid, this.state);
    // Update app state
    this.props.updateUser(this.state);
    this.props.onSave();
  };

  render() {
    const { classes } = this.props;
    const { county } = this.state || {};

    return (
      <div
        style={{ backgroundColor: '#2B4F5C', width: '100%' }}
        className={classes.formContainer}
      >
        <form onSubmit={this.handleSubmit}>
          <GridContainer
            style={{
              padding: '0px 20px 0px 20px',
              borderRadius: '0px 0px 10px 10px',
              backgroundColor: '#2B4F5C',
            }}
            justify="center"
          >
            <GridItem xs={11}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.firstName
                        ? this.props.user.firstName
                        : '',
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.lastName
                        ? this.props.user.lastName
                        : '',
                    }}
                  />
                </GridItem>
                {/* SNIPPET profileForm.jsx_6 */}
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Phone Number"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: '16',
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.phone,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Address"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.address,
                    }}
                  />
                </GridItem>
                {/* SNIPPET profileForm.jsx_5 */}
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="City"
                    id="city"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.city,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="State"
                    id="state"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.state,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="Zip Code"
                    id="zip"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'number',
                      onChange: this.handleChange,
                      defaultValue: this.props.user.zip,
                    }}
                  />
                </GridItem>
                {/* SNIPPET profileForm.jsx_4 */}
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    style={{ maxLength: '40' }}
                    labelText="Bio"
                    id="bio"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'number',
                      multiline: true,
                      onChange: this.handleChange,
                      defaultValue: this.props.user.bio,
                    }}
                  />
                </GridItem>
                {/* SNIPPET profileForm.jsx_3 */}
                {/* PRECINCTS */
                // if county in precincts object
                county && precincts[county] ? (
                  <GridItem xs={6}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="precinct" className={classes.selectLabel}>
                        Precinct:
                      </InputLabel>
                      {/* SNIPPET profileForm.jsx_2 */}
                    </FormControl>
                  </GridItem>
                ) : null}
              </GridContainer>
              {/* SNIPPET profileForm.jsx_1 */}
              <Button
                type="submit"
                color="primary"
                style={{
                  margin: '20px 20px 20px 0px',
                  width: '100%',
                  boxShadow:
                    '0 2px 2px 0 rgba(156, 39, 176, 0), 0 3px 1px -2px rgba(156, 39, 176, 0)',
                }}
              >
                Save Changes
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  uid: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    bio: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    county: PropTypes.string,
    precinct: PropTypes.string,
  }).isRequired,
};

export default compose(
  connect(
    null,
    { updateUser }
  ),
  withStyles(Style)
)(Form);
