import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import { clearError } from 'store/actions/errorActions';
import dashboardRoutes from 'routes/dashboard.jsx';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';
import logo from 'assets/img/arcq/logo.png';
import Orb from 'assets/img/logos/musiclear-icon.png';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.invisable) return null;
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key} />;
        });
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

//console.log(switchRoutes)

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      loadChat: false,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.props.clearError();
    });
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }
  componentWillUnmount() {
    this.unlisten();
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logo={logo}
          // image={image} // Background image for sidebar
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          // color="blue" // Button Colors
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={dashboardRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <Footer fluid />
          {/* On the /maps/full-screen-maps route we want the map to be on
           full screen - this is not possible if the content and conatiner
           classes are present because they have some paddings which would 
           make the map smaller */}

          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}

          <img
            className={classes.orb}
            src={Orb}
            alt="orb"
            onClick={() => this.handleClickOpen('loadChat')}
          />

          <Dialog
            style={{
              margin: '0',
            }}
            open={this.state.loadChat}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose('loadChat')}
            aria-labelledby="arcq-modal-slide-title"
            aria-describedby="arcq-modal-slide-description"
          >
            {/* FORM */}
            <div className={classes.chat}>
              <iframe
                maxwidth="350"
                height="430"
                allow="microphone;"
                src="https://console.dialogflow.com/api-client/demo/embedded/e56ed5d5-0432-4a56-9360-e67c4f629d2c"
              />
            </div>
          </Dialog>
          <Dialog open={!!this.props.error}>
            <div
              style={{
                color: 'red',
                fontSize: '22px',
                padding: '25px',
                textAlign: 'center',
                marginBottom: '15px',
                lineHeight: '25px',
              }}
            >
              {this.props.error}
            </div>
            <Button variant="outlined" onClick={() => this.props.clearError()}>
              Close
            </Button>
          </Dialog>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    users: state.admin.users,
    error: state.error,
  };
};

export default compose(
  connect(
    mapStateToProps,
    { clearError }
  ),
  withStyles(appStyle)
)(Dashboard);
