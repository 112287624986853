// ##############################
// // // App styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const appStyle = (theme) => ({
  wrapper: {
    background: "#FFF",
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  [theme.breakpoints.down('sm')]: {
    orb: {
      height: '3.5rem',
      position: 'fixed',
      right: '0',
      bottom: '4rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      zIndex: '1029',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
      display: 'none',
      // animation: "MoveUpDown 2s linear infinite"
    },
  },
  [theme.breakpoints.up('md')]: {
    orb: {
      height: '3.5rem',
      position: 'fixed',
      right: '0',
      bottom: '-13px',
      marginRight: '1rem',
      marginBottom: '1rem',
      zIndex: '1029',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
      display: 'none',
      // animation: "MoveUpDown 2s linear infinite"
    },
  },
  '@keyframes MoveUpDown': {
    '0%, 100%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(-5px)',
    },
  },
  chat: {
    backgroundColor: grayColor,
  },
  chatButton: {
    position: 'fixed',
    right: '0',
    bottom: '0',
    zIndex: '9',
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '70px',
    padding: '0 0',
    minHeight: 'calc(100vh - 123px)',
    // overflowX: 'hidden',
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

export default appStyle;
