import React from 'react';
import PropTypes from 'prop-types';

import withStyle from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';

export const StartRecordingOverButton = ({ classes, onClick }) => (
  <button
    onClick={onClick}
    style={{
      borderWidth: '0px',
      width: '79%',
      borderRadius: '10px 0px 0px 10px',
      backgroundColor: '#53707F',
    }}
  >
    <p
      style={{
        fontSize: '22px',
        fontWeight: '600',
        color: '#FFFFFF',
        marginTop: '10px',
      }}
      className={classes.textWhite}
    >
      {' '}
      Start Over
    </p>
  </button>
);

StartRecordingOverButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyle(Style)(StartRecordingOverButton);
