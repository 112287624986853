import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';

import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

export const DoubleButton = ({ labels, classes, onSelect }) => {
  const selectedColor = '#1b3d59';
  const unSelectedColor = '#588b7f';

  const [selected, setSelected] = useState(0);

  const onClick = (index) => {
    setSelected(index);
    onSelect(index);
  };

  return (
    <div>
      {/* Mobile View  */}
      <Hidden smUp implementation="css">
        <GridContainer justify="center">
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <div
              style={{
                color: 'white',
                padding: '20px',
                fontSize: '22px',
                textAlign: 'center',
                fontWeight: 'bold',
                borderRadius: '5px 0px 0px 5px',
                backgroundColor: selected === 0 ? selectedColor : unSelectedColor,
                position: 'relative',
                left: '4px',
              }}
              onClick={() => onClick(0)}
            >
              {labels[0]}
            </div>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <div
              style={{
                color: 'white',
                padding: '20px',
                fontSize: '22px',
                textAlign: 'center',
                fontWeight: 'bold',
                borderRadius: '0px 5px 5px 0px',
                backgroundColor: selected === 1 ? selectedColor : unSelectedColor,
                position: 'relative',
                left: '-4px',
              }}
              onClick={() => onClick(1)}
            >
              {labels[1]}
            </div>
          </GridItem>
        </GridContainer>
      </Hidden>

      {/* Desktop View  */}
      <Hidden xsDown implementation="css">
        <GridContainer justify="center" style={{ margin: '20px 50px 0px 20px' }}>
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <button
              style={{
                borderRadius: '10px 0px 0px 10px',
                fontSize: '22px',
                backgroundColor: '#53707F',
                margin: '0px 0px 0px 475px',
              }}
            >
              <p
                style={{ color: '#FFFFFF', marginTop: '10px' }}
                className={classes.textWhite}
              >
                {' '}
                Rewards
              </p>
            </button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} lg={6} style={{ marginLeft: '-50px' }}>
            <Link
              to={{
                pathname: '/view-post',
              }}
            >
              <button
                style={{
                  borderRadius: '0px 10px 10px 0px',
                  backgroundColor: '#5B8F81',
                }}
              >
                <p
                  style={{
                    fontSize: '22px',
                    fontWeight: '600',
                    color: '#1B3D59',
                    marginTop: '10px',
                  }}
                >
                  Fees
                </p>
              </button>
            </Link>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
};

DoubleButton.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(Style)(DoubleButton);
