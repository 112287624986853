import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import VideoDescriptionForm from 'components/VideoDescriptionForm';
import PostVideoButton from 'components/PostVideoButton';
import StartRecordingOverButton from 'components/StartRecordingOverButton';
import VideoPreview from 'components/VideoPreview';

import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';

const RecordConfirm = ({
  classes,
  onConfirm,
  onStartOver,
  videoData: { url, topic, category },
}) => {
  const [description, setDescription] = useState(null);

  const onDescriptionInput = (description) => {
    setDescription(description);
  };

  return (
    <div>
      <GridContainer style={{ margin: '-10px 0px -1px 20px' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className={classes.Card}>
            <h3
              style={{
                textTransform: 'uppercase',
                marginBottom: '-30px',
                color: 'white',
                fontWeight: '600',
                fontSize: '18px',
              }}
            >
              Are you happy with this response?
            </h3>
            <h5
              style={{
                textTransform: 'uppercase',
                marginBottom: '-40px',
                color: 'white',
                fontWeight: '400',
                fontSize: '14px',
              }}
              className={classes.title}
            >
              Category: {category}
            </h5>
            <h5
              style={{
                textTransform: 'uppercase',
                color: 'white',
                fontWeight: '400',
                fontSize: '14px',
              }}
              className={classes.title}
            >
              Topic: {topic}
            </h5>
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        {/*VIDEO PREVIEW  */}
        <GridItem xs={12} sm={12} md={10} lg={8}>
          <VideoPreview videoUrl={url} />
          <div
            style={{
              boxShadow: '0px 5px 20px -5px rgba(0,0,0,0.75)',
              height: '75px',
              margin: '-75px 0px 0px 10%',
              width: '80%',
            }}
          />
        </GridItem>
      </GridContainer>
      {/* Mobile */}
      <Hidden smUp implementation="css">
        <GridContainer style={{ width: '113%' }} justify="center">
          {/* START OVER BUTTON */}
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <StartRecordingOverButton onClick={onStartOver} />
          </GridItem>
          {/* POST VIDEO BUTTON */}
          <GridItem xs={6} sm={6} md={6} lg={6} style={{ marginLeft: '-40px' }}>
            <PostVideoButton onPost={() => onConfirm(description)} />
          </GridItem>
        </GridContainer>
      </Hidden>
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <GridContainer justify="center">
          {/* START OVER BUTTON */}
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <StartRecordingOverButton onClick={onStartOver} />
          </GridItem>
          {/* POST VIDEO BUTTON */}
          <GridItem xs={6} sm={6} md={6} lg={6} style={{ marginLeft: '-150px' }}>
            <PostVideoButton onPost={() => onConfirm(description)} />
          </GridItem>
        </GridContainer>
      </Hidden>
      {/*VIDEO DESCRIPTION FORM  */}
      <GridContainer style={{ margin: '0px 17px' }} justify="center">
        <GridItem xs={11} sm={12} md={12} lg={12}>
          <VideoDescriptionForm handleInput={onDescriptionInput} />
        </GridItem>
      </GridContainer>
      {/* SNIPPET RecordConfrim.jsx_1 */}
    </div>
  );
};

RecordConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  videoData: PropTypes.shape({
    url: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onStartOver: PropTypes.func.isRequired,
};

export default withStyles(Style)(RecordConfirm);
