import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from 'prop-types';

import axios from 'axios';
import { db } from 'firebase/fbConfig.js';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import styles from 'assets/jss/material-dashboard-pro-react/components/CreateCaseFormStyle.jsx';

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false,
  };
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // update firestore document with PENDING and comment
    db.collection('TEST-users')
      .doc(this.state.uid)
      .collection('cases')
      .doc()
      .set(
        {
          uid: this.state.uid,
          clientFirstName: this.state.clientFirstName,
          clientLastName: this.state.clientLastName,
          caseType: this.state.caseType,
          serviceNeeded: this.state.serviceNeeded,
          summary: this.state.summary,
          createdDate: new Date(),
        },
        { merge: true }
      )
      .then(() => {
        this.setState({
          comment: '',
          messageSent: true,
        });
      })
      .catch((err) => {
        console.log(`${err}`);
      });
  };

  resetRequest = () => {
    window.location.reload();
  };

  render() {
    const { classes, modalClose, authUser, blogID } = this.props;
    //console.log(this.state)
    // reset modal when closed
    if (this.state.messageSent) {
      this.resetRequest();
    }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Case Submitted!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify="center">
                <GridItem xs={11}>
                  <h5 className={classes.bodyText}>Create a New Case</h5>
                  {this.state.messageError ? (
                    <h5 className={classes.bodyText}>There Has Been An Error</h5>
                  ) : null}
                  <CustomInput
                    labelText="Client First Name"
                    id="clientFirstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                  <CustomInput
                    labelText="Client Last Name"
                    id="clientLastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                  <CustomInput
                    labelText="Client ID"
                    id="clientID"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                  <CustomInput
                    labelText="Lawyer ID"
                    id="clientID"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />

                  <CustomInput
                    labelText="Case Type"
                    id="caseType"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                  <CustomInput
                    labelText="Service Needed"
                    id="serviceNeeded"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: false,
                      rows: 1,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                  <CustomInput
                    labelText="Summary"
                    id="summary"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      multiline: true,
                      rows: 5,
                      onChange: (event) =>
                        this.handleChange(event, authUser.uid, authUser.uid),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                type="submit"
                color="primary"
                style={{
                  marginLeft: '10px',
                  marginBottom: '10px',
                }}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);
