import React from 'react';
import PropTypes from 'prop-types';

export const PostVideoButton = ({ onPost }) => (
  <button
    onClick={onPost}
    style={{
      borderWidth: '0px',
      width: '79%',
      borderRadius: '0px 10px 10px 0px',
      background:
        'linear-gradient(0deg, rgba(149,207,155,1) 0%, rgba(216,225,116,1) 100%)',
    }}
  >
    <p
      style={{
        fontSize: '22px',
        fontWeight: '600',
        color: '#1B3D59',
        marginTop: '10px',
      }}
    >
      Post Response
    </p>
  </button>
);

PostVideoButton.propTypes = {
  onPost: PropTypes.func.isRequired,
};

export default PostVideoButton;
