import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from "components/Card/Card.jsx";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';

// Opens on click from table on SharePage

const ShareDetails = () => {
    return (
        <Card
            style={{
                backgroundColor: '#223380',
                borderRadius: '10px',
                color: '#fff',
                padding: '20px'
            }}
        >
            <h3><b>Details</b></h3>
            <Card style={{
                background: 'linear-gradient(177deg, rgba(233,233,233,1) 0%, rgba(198,198,198,1) 96%)'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <AccountCircleIcon style={{ width: '20vw', height: '15vh' }} />
                    <AudioPlayer
                        src="https://www.computerhope.com/jargon/m/example.mp3"
                        onPlay={(e) => console.log('onPlay')}
                        style={{ width: '65vw', margin: '6px 11px 0 0px' }}
                        header='Now Playing: Example'
                    />
                    <FormControl variant="filled" style={{ width: '130px', margin: '33px 16px 0px 0px' }}>
                        <Select
                        >
                            <MenuItem value={'favorite'}>Favorite</MenuItem>
                            <MenuItem value={'reject'}>Reject</MenuItem>
                            <MenuItem value={'accept'}>Accept</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Card>
            <h5>Song Pitched To: </h5>
            <GridContainer>
                <GridItem xs={6}>
                    <Card
                        style={{
                            backgroundColor: '#5476d3',
                            borderRadius: '10px',
                            height: '20vh',
                            margin: '0 0 20px 0',
                            color: '#fff',
                        }}
                    >
                        <div style={{ margin: '0 0 0 10px' }}>
                            <AccountCircleIcon
                                style={{
                                    width: '90%',
                                    height: '12vh',
                                    margin: '12px 14px -20px 0'
                                }} />
                            <center>
                                <h5>Jada Pinkett Smith</h5>
                            </center>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <Card
                        style={{
                            backgroundColor: '#5476d3',
                            borderRadius: '10px',
                            height: '20vh',
                            margin: '0 0 20px 0',
                            color: '#fff',
                        }}
                    >
                        <div style={{ margin: '0 0 0 10px' }}>
                            <AccountCircleIcon
                                style={{
                                    width: '90%',
                                    height: '12vh',
                                    margin: '12px 14px -20px 0'
                                }} />
                            <center>
                                <h5>Emily Jacobs</h5>
                            </center>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <Card
                        style={{
                            backgroundColor: '#5476d3',
                            borderRadius: '10px',
                            height: '20vh',
                            margin: '0 0 20px 0',
                            color: '#fff',
                        }}
                    >
                        <div style={{ margin: '0 0 0 10px' }}>
                            <AccountCircleIcon
                                style={{
                                    width: '90%',
                                    height: '12vh',
                                    margin: '12px 14px -20px 0'
                                }} />
                            <center>
                                <h5>Michael Brown</h5>
                            </center>
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <Card
                        style={{
                            backgroundColor: '#5476d3',
                            borderRadius: '10px',
                            height: '20vh',
                            margin: '0 0 20px 0',
                            color: '#fff',
                        }}
                    >
                        <div style={{ margin: '0 0 0 10px' }}>
                            <AccountCircleIcon
                                style={{
                                    width: '90%',
                                    height: '12vh',
                                    margin: '12px 14px -20px 0'
                                }} />
                            <center>
                                <h5>Rebecca May</h5>
                            </center>
                        </div>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ display: 'flex' }}>
                <GridItem xs={2}>
                    <AddIcon
                        style={{
                            backgroundColor: '#f26599',
                            color: '#fff',
                            borderRadius: '50%',
                            margin: '10px 0 0px 15px'
                        }} />
                </GridItem>
                <GridItem xs={4}>
                    <h5>Add Agent</h5>
                </GridItem>
                <GridItem xs={6}>
                    <FormControl variant="filled" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-filled-label">Select...</InputLabel>
                        <Select
                        >
                            <MenuItem value={'favorite'}>Agent #1</MenuItem>
                            <MenuItem value={'reject'}>Agent #2</MenuItem>
                            <MenuItem value={'accept'}>Agent #3</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
            <button
                style={{
                    background: '#fff',
                    color: '#000',
                    border: '2px solid #000',
                    margin: '10px 0 0 0',
                    width: '40%',
                    height: '6vh',
                    right: '0',
                    alignSelf: 'flex-end'
                }}>
                CONFIRM
            </button>
        </Card>
    )
}

export default ShareDetails;