import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FollowedUser from 'components/FollowedUser';

export const ProfileViewFollowerdUsers = ({ list }) =>
  !!list.length && (
    <GridContainer style={{ margin: '0' }}>
      {list.map((item, i) => (
        <GridItem xs={2} key={`user_avatar_${i}`} style={{ margin: '0' }}>
          <FollowedUser data={item} />
        </GridItem>
      ))}
    </GridContainer>
  );

ProfileViewFollowerdUsers.propTypes = {
  list: PropTypes.array.isRequired,
};

export default ProfileViewFollowerdUsers;
