import React from 'react';

import defaultImage from 'assets/img/default-avatar.png';

class PictureUpload extends React.Component {
  state = {
    file: null,
    imagePreviewUrl: defaultImage,
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    return (
      <div className="picture-container">
        <div className="picture">
          <img src={this.state.imagePreviewUrl} className="picture-src" alt="..." />
          <input type="file" onChange={this.handleImageChange} />
        </div>
        <h6 className="description">Choose Picture</h6>
      </div>
    );
  }
}

export default PictureUpload;
