import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { Link } from 'react-router-dom';
import RoundedBox from 'components/BoxRounded';
import Hidden from '@material-ui/core/Hidden';

export const ProfileViewPostActivity = ({ recordings, linkToLibrary }) => {
  const publicPostsNb = recordings.filter((e) => e.type === 'public').length;
  const privatePostsNb = recordings.filter((e) => e.type === 'private').length;

  return (
    <GridContainer
      style={{
        width: '110vw',
        padding: '0px 0% 0px 0%',
        // margin: '31px 0 0 0',
        display: 'block',
      }}
    >
      {/* Desktop */}
      <Hidden xsDown implementation="css">
        <GridItem lg={12} md={12} sm={12} xs={12} style={{ margin: '0px 0px 0px 65px' }}>
          {[
            { path: '/topics', number: '12', title: 'Active Topics' },
            {
              path: privatePostsNb > 0 ? '/library/private' : 'library',
              number: privatePostsNb.toString(),
              title: 'Private Posts',
            },
            {
              path: publicPostsNb > 0 ? '/library/public' : 'library',
              number: publicPostsNb.toString(),
              title: 'Public Posts',
            },
          ].map((e) => (
            <GridItem key={e.title} xs={4}>
              {linkToLibrary ? (
                <Link to={{ pathname: e.path }}>
                  <RoundedBox title={e.number} subtitle={e.title} />
                </Link>
              ) : (
                <RoundedBox title={e.number} subtitle={e.title} />
              )}
            </GridItem>
          ))}
        </GridItem>
      </Hidden>
      {/* Mobile */}
      <Hidden smUp implementation="css">
        <GridItem
          xs={12}
          style={{
            margin: '0px 0px 0px 4%',
            display: 'inline-flex',
            width: '87%',
          }}
        >
          {[
            { path: '/topics', number: '12', title: 'Active Topics' },
            {
              path: privatePostsNb > 0 ? '/library/private' : 'library',
              number: privatePostsNb.toString(),
              title: 'Private Posts',
            },
            {
              path: publicPostsNb > 0 ? '/library/public' : 'library',
              number: publicPostsNb.toString(),
              title: 'Public Posts',
            },
          ].map((e) => (
            <GridItem key={e.title} xs={4}>
              {linkToLibrary ? (
                <Link to={{ pathname: e.path }}>
                  <RoundedBox title={e.number} subtitle={e.title} />
                </Link>
              ) : (
                <RoundedBox title={e.number} subtitle={e.title} />
              )}
            </GridItem>
          ))}
        </GridItem>
      </Hidden>
    </GridContainer>
  );
};

ProfileViewPostActivity.propTypes = {
  linkToLibrary: PropTypes.bool,
  recordings: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default ProfileViewPostActivity;
