import React from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// react component plugin for creating beatiful tags on an input
import TagsInput from "react-tagsinput";
// plugin that creates slider
import nouislider from "nouislider";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import Today from "@material-ui/icons/Today";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AvTimer from "@material-ui/icons/AvTimer";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ReactTables from "../Tables/ReactTables.jsx";
import RegularTables from '../Tables/RegularTables.jsx';

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const streamingServices = [
  'Amazon Prime',
  'Amazon',
  'Apple Music',
  'Deezer',
  'Google Play',
  'Pandora',
  'Rhapsody/Napster',
  'Spotify',
  'Tidal',
  'YouTube',
  'YouTube Red',
];

class ForecastingForm extends React.Component {

  constructor(props) {
    super(props);

    const formdata = {};

    streamingServices.forEach(
      (service) => (formdata[service] = { numberOfStreams: 0, percentageOwned: 0 })
    );

    this.state = {
      formdata,
      errors: {},
      totalStreamsAmount: 0,
      averagePercentageOwned: 0,
    };
  }

  handleEdit(service, fieldName, e) {
    const { formdata, errors } = this.state;
    const data = formdata[service];
    let error = errors[service];
    let value = Number(e.target.value);
    if (!value) {
      error = { ...error, [fieldName]: 'cannot parse value' };
      value = e.target.value;
    } else {
      error = { ...error, [fieldName]: undefined };
    }
    this.setState({
      formdata: { ...formdata, [service]: { ...data, [fieldName]: value } },
      errors: { ...errors, [service]: error },
    });
  }

  getErrors(service) {
    return this.state.errors[service] || {};
  }

  calculateRevenue(){
    let revenueFromStreams = this.state.totalStreamsAmount * 0.4;
    return revenueFromStreams * (this.state.averagePercentageOwned * 0.01);
  }

  renderRow([service, item]) {
    const numberOfStreamsColor = this.getErrors(service).numberOfStreams
      ? '#f00'
      : '#fff';
    const percentageOwnedColor = this.getErrors(service).percentageOwned
      ? '#f00'
      : '#fff';

    return (
      <tr key={service}>
        <td>{service}</td>
        <td>
          <input
            type="number"
            name={service + 'NumberOfStreams'}
            value={item.numberOfStreams}
            onChange={(e) => this.handleEdit(service, 'numberOfStreams', e)}
            style={{ backgroundColor: '#B28F99', color: numberOfStreamsColor }}
          />
        </td>
        <td>
          <center>
            <input
              type="number"
              name={service + 'percentageOwned'}
              value={item.percentageOwned}
              onChange={(e) => this.handleEdit(service, 'percentageOwned', e)}
              style={{
                backgroundColor: '#B28F99',
                color: percentageOwnedColor,
                width: '38%',
              }}
            />
          </center>
        </td>
      </tr>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let totalStreamsAmount = 0;
    let totalPercentageOwned = 0;

    for (const item of Object.values(this.state.formdata)) {
      totalStreamsAmount += item.numberOfStreams || 0;
      totalPercentageOwned +=
        (item.percentageOwned || 0) * (item.numberOfStreams || 0);
    }

    this.setState({
      totalStreamsAmount,
      averagePercentageOwned: totalPercentageOwned / totalStreamsAmount,
    });
  };

  render() {
    // console.log(this.state);
    return (
      <div>
        <GridContainer style={{ color: '#fff', backgroundColor: '#EF5575' }}>
          <GridItem xs={12}>
            <h5 style={{ fontWeight: '600' }}>Calculate Forecast</h5>
            <p>Fill out the form below to generate predictions</p>
            <table className="table">
              <thead>
                <tr>
                  <th>Streaming Provider</th>
                  <th>Number of Streams</th>
                  <th>Owned</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(this.state.formdata).map((entry) =>
                  this.renderRow(entry)
                )}
                <tr key="totals">
                  <td>Total</td>
                  <td>
                    <p style={{ backgroundColor: '#F7B7D1', color: 'black' }}>
                      {this.state.totalStreamsAmount}
                    </p>
                  </td>
                  <td>
                    <center>
                      <p
                        style={{
                          backgroundColor: '#F7B7D1',
                          color: 'black',
                          width: '38%',
                        }}
                      >
                        {this.state.averagePercentageOwned.toFixed(2)}
                      </p>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
            <center>
              <button
                style={{
                  width: '88vw',
                  margin: '20px 0 20px 0',
                  height: '38px',
                  border: '2px solid black',
                  fontSize: '20px',
                }}
                onClick={(e) => this.handleSubmit(e)}
              >
                Calculate
              </button>
            </center>
          </GridItem>
        </GridContainer>
        <Card
          style={{
            backgroundColor: '#223380',
            borderRadius: '10px',
            height: '35vh',
            margin: '20px 0 0 0',
            color: '#fff',
          }}
        >
          <GridContainer>
            <GridItem style={{ margin: '8px 0 0 25px' }} xs={10}>
              <h5>
                <strong>Predictions</strong>
              </h5>
              <p>Calculated from the table above</p>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr style={{ backgroundColor: '#887aad' }}>
                    <td>Revenue to Date</td>
                    <td>$20,523.61</td>
                  </tr>
                  <tr>
                    <td>1 year</td>
                    <td>${this.calculateRevenue()}</td>
                  </tr>
                  <tr style={{ backgroundColor: '#887aad' }}>
                    <td>2 years</td>
                    <td>${this.calculateRevenue() * 2}</td>
                  </tr>
                  <tr>
                    <td>3 years</td>
                    <td>${this.calculateRevenue() * 3}</td>
                  </tr>
                  <tr style={{ backgroundColor: '#887aad' }}>
                    <td>4 years</td>
                    <td>${this.calculateRevenue() * 4}</td>
                  </tr>
                  <tr>
                    <td>5 years</td>
                    <td>${this.calculateRevenue() * 5}</td>
                  </tr>
                </tbody>
              </table>
            </GridItem>
          </GridContainer>
        </Card>
        <Card
          style={{
            backgroundColor: '#6ABC43',
            borderRadius: '10px',
            height: '20vh',
          }}
        >
          <GridContainer>
            <GridItem xs={10} style={{ margin: '0 0 0 30px', color: '#fff' }}>
              <center>
                <strong>
                  <h5>Max Return</h5>
                  <h1>$400,401.49</h1>
                </strong>
              </center>
            </GridItem>
          </GridContainer>
        </Card>
      </div>
    );
  }
}

export default ForecastingForm;
