import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import carouselStyle from 'assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

class PendingArtistsCarousel extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
        };

        return (
            <Carousel {...settings}>
                <div>
                    <GridItem xs={12} style={{ background: 'linear-gradient(177deg, rgba(127,2,210,1) 0%, rgba(2,0,154,1) 96%)', color: '#fff', borderRadius: '10px', margin: '15px', height: '36vh' }}>
                        <center>
                            {/* AccountCircleIcon as placeholder until we pull data from chartmetric api */}
                            <AccountCircleIcon style={{ width: '45%', height: '15vh', margin: '0 0 -20px 0' }} />
                            <h4><b>Jada Pinkett Smith</b></h4>
                            <p>Artist Bio</p>
                            <FormControl variant="filled" style={{ width: '130px' }}>
                                <InputLabel>Artist Action</InputLabel>
                                <Select
                                >
                                    <MenuItem value={'view-profile'}>View Profile</MenuItem>
                                    <MenuItem value={'contact-artist'}>Contact Artist</MenuItem>
                                </Select>
                            </FormControl>
                        </center>
                    </GridItem>
                </div>
                <div>
                    <GridItem xs={12} style={{ background: 'linear-gradient(177deg, rgba(127,2,210,1) 0%, rgba(2,0,154,1) 96%)', color: '#fff', borderRadius: '10px', margin: '15px', height: '36vh' }}>
                        <center>
                            {/* AccountCircleIcon as placeholder until we pull data from chartmetric api */}
                            <AccountCircleIcon style={{ width: '45%', height: '15vh', margin: '0 0 -20px 0' }} />
                            <h4><b>Glass Animals</b></h4>
                            <p>Artist Bio</p>
                            <FormControl variant="filled" style={{ width: '130px' }}>
                                <InputLabel>Artist Action</InputLabel>
                                <Select
                                >
                                    <MenuItem value={'view-profile'}>View Profile</MenuItem>
                                    <MenuItem value={'contact-artist'}>Contact Artist</MenuItem>
                                </Select>
                            </FormControl>
                        </center>
                    </GridItem>
                </div>
                <div>
                    <GridItem xs={12} style={{ background: 'linear-gradient(177deg, rgba(127,2,210,1) 0%, rgba(2,0,154,1) 96%)', color: '#fff', borderRadius: '10px', margin: '15px', height: '36vh' }}>
                        <center>
                            {/* AccountCircleIcon as placeholder until we pull data from chartmetric api */}
                            <AccountCircleIcon style={{ width: '45%', height: '15vh', margin: '0 0 -20px 0' }} />
                            <h4><b>Cardi B</b></h4>
                            <p>Artist Bio</p>
                            <FormControl variant="filled" style={{ width: '130px' }}>
                                <InputLabel>Artist Action</InputLabel>
                                <Select
                                >
                                    <MenuItem value={'view-profile'}>View Profile</MenuItem>
                                    <MenuItem value={'contact-artist'}>Contact Artist</MenuItem>
                                </Select>
                            </FormControl>
                        </center>
                    </GridItem>
                </div>
            </Carousel>
        );
    }
}

export default withStyles(carouselStyle)(PendingArtistsCarousel);