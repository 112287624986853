import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import Close from '@material-ui/icons/Close';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import { signupError } from 'store/actions/authActions';
import { updateUser } from 'store/actions/profileActions';
import store from 'services/StoreService';
import userEvents from 'config/user_events';
import authService from 'services/AuthService';
import Style from 'assets/jss/material-dashboard-pro-react/views/signupPageStyle';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      passError: false,
      tocModal: false,
      signupError: false,
      advertiser: false,
      // email: this.props.authUser ? this.props.authUser.email : "no email",
      continueForm: false,
      setup: true,
      authInfo: {
        email: '',
        displayName: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        userType: ''
      },
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle
    });
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value,
      }
    })
    // console.log(this.state)
  }

  handleSelectChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        userType: e.target.value,
      }
    })
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const { authInfo } = this.state;
      const { updateUser } = this.props;

      // if (authInfo.code !== '1010') {
      //   return console.log('error');
      // }

      if (authInfo.password !== authInfo.confirmPassword) {
        this.setState({
          passError: true,
        });
        return;
      }

      if (this.state.checked[0] !== 'accepted terms') {
        this.setState({
          termsError: true,
        });
        return;
      }

      this.setState({
        signupError: false,
      });

      const createdAt = new Date().getTime();
      // Sign the user up on the auth server
      const { user } = await authService.emailSignup(authInfo);
      const { uid } = user;
      // The info the user input
      const { email, displayName, userType } = authInfo;
      // Get this event spec
      const event = userEvents.find((e) => e.event === 'join');
      // The events field to save to db
      const events = [{ ...event, createdAt }];
      // The user data to save to db
      const data = { uid, email, displayName, events, userType };
      // Create user in db
      await store.addUser(uid, data);
      // Set user in app state
      updateUser(data);
    } catch (error) {
      console.log(error.toString());
      this.props.signupError(error);
    }
  };

  handleDateChange = (dob) => {
    console.log(dob)
    if (dob) {
      this.setState(() => ({ dob: dob, dobAPI: moment(dob).format('YYYY-MM-DD') }))
    }
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleError = (error) => {
    var errorCode = error.code;

    this.setState({
      signupError: true,
      errorCode,
    });
  };

  isAdvertiser = () => {

    if (this.state.advertiser == "undefined") {
      this.setState({
        advertiser: true,
      })
    } else {
      this.setState({
        advertiser: !this.state.advertiser,
      })
    }

  }

  continueForm = () => {

    this.setState({
      continueForm: true,
    })

  }

  render() {
    const { classes, authError } = this.props;

    if (!!authError && !this.state.signupError) this.handleError(authError);

    const userTypes = ['Agent', 'Artist', 'Admin']

    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={10} md={8} lg={6}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Welcome!</h2>
              <CardBody>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      required: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      required: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <CustomInput
                    labelText="Phone Number"
                    id="phoneNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      required: true,
                      onChange: (event) => this.handleChange(event),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText='Email'
                    id='email'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText='Username'
                    id='displayName'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'displayName',
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <CustomInput
                    labelText="Code"
                    id="code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'code',
                      required: true,
                      onChange: this.handleChange,
                    }}
                  /> */}

                  <CustomInput
                    labelText='Password'
                    id='password'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      type: 'password',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon>lock_outline</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText='Confirm Password'
                    id='confirmPassword'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      type: 'password',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon>lock_outline</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.passError ? (
                    <p className={classes.textDanger}>
                      Passwords do not match, please try again.
                    </p>
                  ) : null}
                  {this.state.termsError ? (
                    <p className={classes.textDanger}>
                      Please accept the terms to continue.
                    </p>
                  ) : null}
                  {this.state.errorCode == 'auth/weak-password' ? (
                    <p className={classes.textDanger}>
                      Please enter a password with six characters or more.
                    </p>
                  ) : null}
                  {this.state.errorCode == 'auth/email-already-in-use' ? (
                    <div className={classes.justifyContentCenter}>
                      <p className={classes.textDanger}>
                        Error: Email already in use. <br />
                        Forgot your password?
                      </p>
                      <Button
                        type='button'
                        onClick={this.handlePasswordReset}
                        color='danger'
                        simple
                        size='lg'
                        block
                      >
                        Click Here
                      </Button>
                      <p className={classes.textDanger}>
                        to send a password reset email.
                      </p>
                    </div>
                  ) : null}
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}>
                    <InputLabel
                      style={{ color: '#fff' }}
                      labelText='User Type'
                      className={classes.selectLabel}>
                      User Type:
                    </InputLabel>
                    <Select
                      style={{ color: '#fff' }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      required
                      value={this.state.userType}
                      inputProps={{
                        required: true,
                        onChange: this.handleSelectChange,
                        type: 'userType',
                        id: 'userType',
                        name: 'userType',
                      }}
                    >
                      {
                        userTypes.map((item) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                            value={item}
                            key={item}
                          >{item}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.isAdvertiser()}
                        checked={this.state.advertiser}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='Advertiser?'
                  />
                  <FormControlLabel
                    style={{ margin: '0 0 0 -11px' }}
                    classes={{
                      root: classes.checkboxLabelControl,
                      label: classes.checkboxLabel,
                    }}
                    control={
                      <Checkbox
                        style={{ color: 'ffffff' }}
                        tabIndex={-1}
                        onClick={() => this.handleToggle('accepted terms')}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={
                      <span className={classes.bodyText}>
                        I agree to the{' '}
                        <span
                          // onClick={() =>
                          //   window.open(
                          //     // Update firebase url
                          //     'https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/FFPO%20--%20Membership%20T%26C.MARKUP.%204.8.19.pdf?alt=media&token=277eaf75-d380-4ee6-95ba-8c00b6f2dff7',
                          //     '_blank'
                          //   )
                          // }
                          // className={classes.linkText}
                        >
                          terms and conditions
                        </span>
                        .
                      </span>
                    }
                  />
                  {/* TOC MODAL */}
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + ' ' + classes.modalLarge,
                    }}
                    open={this.state.tocModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose('tocModal')}
                    aria-labelledby='shipment-modal-slide-title'
                    aria-describedby='shipment-modal-slide-description'
                  >
                    <DialogTitle
                      id='shipment-modal-slide-title'
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key='close'
                        aria-label='Close'
                        onClick={() => this.handleClose('tocModal')}
                      >
                        {' '}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.bodyText}>
                        <strong>Terms and Conditions</strong>
                      </h2>
                    </DialogTitle>
                    <DialogContent
                      id='shipment-modal-slide-description'
                      className={classes.modalBody}
                    >
                      <p className={classes.bodyText}>Welcome to Musiclear!</p>

                      {/* <p className={classes.bodyText}>
                        These terms and conditions outline the rules and
                        regulations for the use of Musiclear's Website, located at
                        https://www.khalilforcongress.com/.
                      </p> */}

                      <p className={classes.bodyText}>
                        By accessing this website we assume you accept these
                        terms and conditions. Do not continue to use Musiclear if you
                        do not agree to take all of the terms and conditions
                        stated on this page.
                      </p>

                      <p className={classes.bodyText}>
                        The following terminology applies to these Terms and
                        Conditions, Privacy Statement and Disclaimer Notice and
                        all Agreements: "Client", "You" and "Your" refers to
                        you, the person log on this website and compliant to the
                        Company’s terms and conditions. "The Company",
                        "Ourselves", "We", "Our" and "Us", refers to our
                        Company. "Party", "Parties", or "Us", refers to both the
                        Client and ourselves. All terms refer to the offer,
                        acceptance and consideration of payment necessary to
                        undertake the process of our assistance to the Client in
                        the most appropriate manner for the express purpose of
                        meeting the Client’s needs in respect of provision of
                        the Company’s stated services, in accordance with and
                        subject to, prevailing law of Netherlands. Any use of
                        the above terminology or other words in the singular,
                        plural, capitalization and/or he/she or they, are taken
                        as interchangeable and therefore as referring to same.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Cookies</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We employ the use of cookies. By accessing Musiclear, you
                        agreed to use cookies in agreement with the Musiclear's
                        Privacy Policy.
                      </p>

                      <p className={classes.bodyText}>
                        Most interactive websites use cookies to let us retrieve
                        the user’s details for each visit. Cookies are used by
                        our website to enable the functionality of certain areas
                        to make it easier for people visiting our website. Some
                        of our affiliate/advertising partners may also use
                        cookies.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>License</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Unless otherwise stated, Musiclear and/or its licensors own
                        the intellectual property rights for all material on
                        Musiclear. All intellectual property rights are reserved. You
                        may access this from Musiclear for your own personal use
                        subjected to restrictions set in these terms and
                        conditions.
                      </p>

                      <p className={classes.bodyText}>You must not:</p>
                      <ul>
                        <li className={classes.bodyText}>
                          Republish material from Musiclear
                        </li>
                        <li className={classes.bodyText}>
                          Sell, rent or sub-license material from Musiclear
                        </li>
                        <li className={classes.bodyText}>
                          Reproduce, duplicate or copy material from Musiclear
                        </li>
                        <li className={classes.bodyText}>
                          Redistribute content from Musiclear
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        This Agreement shall begin on the date hereof.
                      </p>

                      <p className={classes.bodyText}>
                        Parts of this website offer an opportunity for users to
                        post and exchange opinions and information in certain
                        areas of the website. Musiclear does not filter, edit,
                        publish or review Comments prior to their presence on
                        the website. Comments do not reflect the views and
                        opinions of Musiclear,its agents and/or affiliates. Comments
                        reflect the views and opinions of the person who post
                        their views and opinions. To the extent permitted by
                        applicable laws, Musiclear shall not be liable for the
                        Comments or for any liability, damages or expenses
                        caused and/or suffered as a result of any use of and/or
                        posting of and/or appearance of the Comments on this
                        website.
                      </p>

                      <p className={classes.bodyText}>
                        Musiclear reserves the right to monitor all Comments and to
                        remove any Comments which can be considered
                        inappropriate, offensive or causes breach of these Terms
                        and Conditions.
                      </p>

                      <p className={classes.bodyText}>
                        You warrant and represent that:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          You are entitled to post the Comments on our website
                          and have all necessary licenses and consents to do so;
                        </li>
                        <li className={classes.bodyText}>
                          The Comments do not invade any intellectual property
                          right, including without limitation copyright, patent
                          or trademark of any third party;
                        </li>
                        <li className={classes.bodyText}>
                          The Comments do not contain any defamatory, libelous,
                          offensive, indecent or otherwise unlawful material
                          which is an invasion of privacy
                        </li>
                        <li className={classes.bodyText}>
                          The Comments will not be used to solicit or promote
                          business or custom or present commercial activities or
                          unlawful activity.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        You hereby grant Musiclear a non-exclusive license to use,
                        reproduce, edit and authorize others to use, reproduce
                        and edit any of your Comments in any and all forms,
                        formats or media.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Hyperlinking to our Content</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        The following organizations may link to our Website
                        without prior written approval:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          Government agencies;
                        </li>
                        <li className={classes.bodyText}>Search engines;</li>
                        <li className={classes.bodyText}>
                          News organizations;
                        </li>
                        <li className={classes.bodyText}>
                          Online directory distributors may link to our Website
                          in the same manner as they hyperlink to the Websites
                          of other listed businesses; and
                        </li>
                        <li className={classes.bodyText}>
                          System wide Accredited Businesses except soliciting
                          non-profit organizations, charity shopping malls, and
                          charity fundraising groups which may not hyperlink to
                          our Web site.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        These organizations may link to our home page, to
                        publications or to other Website information so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products and/or services; and
                        (c) fits within the context of the linking party’s site.
                      </p>

                      <p className={classes.bodyText}>
                        We may consider and approve other link requests from the
                        following types of organizations:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          commonly-known consumer and/or business information
                          sources;
                        </li>
                        <li className={classes.bodyText}>
                          dot.com community sites;
                        </li>
                        <li className={classes.bodyText}>
                          associations or other groups representing charities;
                        </li>
                        <li className={classes.bodyText}>
                          online directory distributors;
                        </li>
                        <li className={classes.bodyText}>internet portals;</li>
                        <li className={classes.bodyText}>
                          accounting, law and consulting firms; and
                        </li>
                        <li className={classes.bodyText}>
                          educational institutions and trade associations.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        We will approve link requests from these organizations
                        if we decide that: (a) the link would not make us look
                        unfavorably to ourselves or to our accredited
                        businesses; (b) the organization does not have any
                        negative records with us; (c) the benefit to us from the
                        visibility of the hyperlink compensates the absence of
                        Musiclear; and (d) the link is in the context of general
                        resource information.
                      </p>

                      <p className={classes.bodyText}>
                        These organizations may link to our home page so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products or services; and (c)
                        fits within the context of the linking party’s site.
                      </p>

                      <p className={classes.bodyText}>
                        If you are one of the organizations listed in paragraph
                        2 above and are interested in linking to our website,
                        you must inform us by sending an e-mail to Musiclear. Please
                        include your name, your organization name, contact
                        information as well as the URL of your site, a list of
                        any URLs from which you intend to link to our Website,
                        and a list of the URLs on our site to which you would
                        like to link. Wait 2-3 weeks for a response.
                      </p>

                      <p className={classes.bodyText}>
                        Approved organizations may hyperlink to our Website as
                        follows:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          By use of our corporate name; or
                        </li>
                        <li className={classes.bodyText}>
                          By use of the uniform resource locator being linked
                          to; or
                        </li>
                        <li className={classes.bodyText}>
                          By use of any other description of our Website being
                          linked to that makes sense within the context and
                          format of content on the linking party’s site.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        No use of Musiclear's logo or other artwork will be allowed
                        for linking absent a trademark license agreement.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>iFrames</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Without prior approval and written permission, you may
                        not create frames around our Webpages that alter in any
                        way the visual presentation or appearance of our
                        Website.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Content Liability</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We shall not be hold responsible for any content that
                        appears on your Website. You agree to protect and defend
                        us against all claims that is rising on your Website. No
                        link(s) should appear on any Website that may be
                        interpreted as libelous, obscene or criminal, or which
                        infringes, otherwise violates, or advocates the
                        infringement or other violation of, any third party
                        rights.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Your Privacy</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Please read Privacy Policy
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Reservation of Rights</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amen these terms
                        and conditions and it’s linking policy at any time. By
                        continuously linking to our Website, you agree to be
                        bound to and follow these linking terms and conditions.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Removal of links from our website</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        If you find any link on our Website that is offensive
                        for any reason, you are free to contact and inform us
                        any moment. We will consider requests to remove links
                        but we are not obligated to or so or to respond to you
                        directly.
                      </p>

                      <p className={classes.bodyText}>
                        We do not ensure that the information on this website is
                        correct, we do not warrant its completeness or accuracy;
                        nor do we promise to ensure that the website remains
                        available or that the material on the website is kept up
                        to date.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Disclaimer</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        To the maximum extent permitted by applicable law, we
                        exclude all representations, warranties and conditions
                        relating to our website and the use of this website.
                        Nothing in this disclaimer will:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          limit or exclude our or your liability for death or
                          personal injury;
                        </li>
                        <li className={classes.bodyText}>
                          limit or exclude our or your liability for fraud or
                          fraudulent misrepresentation;
                        </li>
                        <li className={classes.bodyText}>
                          limit any of our or your liabilities in any way that
                          is not permitted under applicable law; or
                        </li>
                        <li className={classes.bodyText}>
                          exclude any of our or your liabilities that may not be
                          excluded under applicable law.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        The limitations and prohibitions of liability set in
                        this Section and elsewhere in this disclaimer: (a) are
                        subject to the preceding paragraph; and (b) govern all
                        liabilities arising under the disclaimer, including
                        liabilities arising in contract, in tort and for breach
                        of statutory duty.
                      </p>

                      <p className={classes.bodyText}>
                        As long as the website and the information and services
                        on the website are provided free of charge, we will not
                        be liable for any loss or damage of any nature.
                      </p>
                      <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          marginLeft: '10px',
                          marginTop: '10px',
                        }}
                        key='close'
                        aria-label='Close'
                        onClick={() => this.handleClose('tocModal')}
                      >
                        CLOSE
                      </Button>
                    </DialogContent>
                  </Dialog>
                  <div className={classes.center}>
                    <Button
                      style={{
                        borderRadius: '20px',
                        width: '50%',
                        boxShadow: 'none',
                      }}
                      type='submit'
                      color='primary'
                    >
                      <b>Let's Go</b>
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
  };
};

export default compose(
  connect(mapStateToProps, { signupError, updateUser }),
  withStyles(Style)
)(SignupPage);
