import React from 'react';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import moment from 'moment';
import Hidden from '@material-ui/core/Hidden';

export const BioSummary = ({
  user: { bio, createdAt, firstName, lastName, displayName },
  classes,
  editable,
}) => (
  <div>
    {/* Desktop */}
    <Hidden xsDown implementation='css'>
      <h3
        style={{
          fontSize: '22px',
          margin: '140px 44px -30px 74px',
        }}
        className={classes.body}
      >
        {firstName && lastName ? (
          <span>
            {' '}
            {firstName.toUpperCase()} <strong>{lastName.toUpperCase()}</strong>
          </span>
        ) : displayName ? (
          <strong>{displayName.toUpperCase()}</strong>
        ) : (
          <div>
            <span>Missing name</span>
            {editable && (
              <p className={classes.body}>
                Click EDIT PROFILE to add your name
              </p>
            )}
          </div>
        )}
      </h3>
      <h5
        style={{ fontSize: '18px', margin: '25px 20px 0px 74px' }}
        className={classes.body}
      >
        Joined {moment(createdAt).format('MMM YY')}
      </h5>
      {editable && (
        <p style={{ margin: '0px 20px 0px 74px' }} className={classes.body}>
          {bio ? bio : 'Tap EDIT PROFILE to add your Bio'}
        </p>
      )}
      {!editable && (
        <p style={{ margin: '0px 20px 0px 74px' }} className={classes.body}>
          {bio
            ? bio
            : 'This user does not have a Bio added to their profile yet'}
        </p>
      )}
    </Hidden>
    {/* Mobile */}
    <Hidden smUp implementation='css'>
      <h3
        style={{
          fontSize: '22px',
          margin: '130px 44px -30px 30px',
        }}
        className={classes.body}
      >
        {firstName && lastName ? (
          <span>
            {' '}
            {firstName.toUpperCase()} <strong>{lastName.toUpperCase()}</strong>
          </span>
        ) : displayName ? (
          <strong>{displayName.toUpperCase()}</strong>
        ) : (
          <div>
            <span>Missing name</span>
            {editable && (
              <p className={classes.body}>
                Click EDIT PROFILE to add your name
              </p>
            )}
          </div>
        )}
      </h3>
      <h5
        style={{ fontSize: '18px', margin: '25px 20px 0px 30px' }}
        className={classes.body}
      >
        Joined {moment(createdAt).format('MMM YY')}
      </h5>
      {editable && (
        <p style={{ margin: '0px 20px 0px 30px' }} className={classes.body}>
          {bio ? bio : 'Tap EDIT PROFILE to add your Bio'}
        </p>
      )}
    </Hidden>
  </div>
);

BioSummary.propTypes = {
  user: PropTypes.shape({
    bio: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    createdAt: PropTypes.number,
    displayName: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default withStyles(Style)(BioSummary);
