import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import actionsReducer from './actionsReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import brokeringReducer from './brokeringReducer';
import contentReducer from './contentReducer';
import profileReducer from './profileReducer';
import assessmentReducer from './assessmentReducer';
import offersReducer from './offersReducer';
import casesReducer from './casesReducer';
import errorReducer from './errorReducer';
import gptReducer from './gptReducer';
import threadsReducer from './threadsReducer';
import clientThreadsReducer from './clientThreadsReducer';
import messagesReducer from './messagesReducer';
import screenNameReducer from './screenNameReducer';
import trackSelectorReducer from './trackSelectorReducer';
import trackPortfolioReducer from './trackPortfolioReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    actions: actionsReducer,
    admin: adminReducer,
    auth: authReducer,
    brokering: brokeringReducer,
    content: contentReducer,
    profile: profileReducer,
    assessment: assessmentReducer,
    offers: offersReducer,
    cases: casesReducer,
    threads: threadsReducer,
    clientThreads: clientThreadsReducer,
    messages: messagesReducer,
    screenName: screenNameReducer,
    gptModels: gptReducer,
    error: errorReducer,
    trackSelector : trackSelectorReducer,
    trackPortfolio : trackPortfolioReducer
  });
