import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/arcqStyle.jsx';
import { Link } from 'react-router-dom';
import Card from 'components/Card/Card.jsx';

export const FeedListItem = ({ classes, data }) => (
  <Link
    to={{
      pathname: `/view-post/${data.id}`,
    }}
  >
    <Card className={classes.textWhite}>
      <div className={classes.cardImgOverlay}>
        <h5
          style={{
            bottom: '70px',
            position: 'absolute',
            fontSize: '22px',
          }}
          className={classes.title}
        >
          {data.category}
        </h5>
        <h5
          className={classes.feedBody}
          style={{
            bottom: '45px',
            position: 'absolute',
            margin: '0px 10px 0px -1px',
          }}
        >
          <b>{data.topic}</b>
        </h5>
        <p
          className={classes.feedBody}
          style={{
            bottom: '10px',
            position: 'absolute',
            margin: '0px 10px 0px -1px',
            fontSize: '20px',
            width: '90%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.description}
        </p>
      </div>
      <div className={classes.imgDarken}>
        <video className={classes.feedImg} data-holder-rendered="true">
          <source type="video/mp4" src={data.url} />
        </video>
      </div>
    </Card>
  </Link>
);

FeedListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(Style)(FeedListItem);
