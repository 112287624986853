import React from 'react';
import { Link } from 'react-router-dom';

// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import LocationOn from '@material-ui/icons/LocationOn';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import carouselStyle from 'assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx';
import image1 from 'assets/img/arcq/forest.png';
import image2 from 'assets/img/arcq/gameboy.png';
import image3 from 'assets/img/arcq/lightTunnel.png';
import { Grid } from '@material-ui/core';

class SectionCarouselMobile extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <Carousel
        style={{ borderRadius: '15px', height: '200px' }}
        {...settings}
        id="home"
      >
        {[
          { category: 'Technology', topic: 'Cutting Edge', image: image3 },
          { category: 'Recreation', topic: 'Video Games', image: image2 },
          { category: 'Environment', topic: 'Trees & Plants', image: image1 },
        ].map((e, i) => (
          <Link
            key={`${e}_${i}`}
            to={{
              pathname: `/topic-detail/${e.topic}`,
            }}
          >
            <div>
              <img
                style={{ padding: '5px', height: '200px', borderRadius: '15px' }}
                src={e.image}
                alt="Slide1"
                className="slick-image"
              />
              <div style={{ textAlign: 'left' }} className="slick-caption">
                <h5 style={{ marginBottom: '-5px' }}>{e.category}</h5>
                <h4 style={{ fontWeight: '600' }}>{e.topic}</h4>
              </div>
            </div>
          </Link>
        ))}
      </Carousel>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarouselMobile);
