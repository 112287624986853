export default {
  models: [
    { model: 'oracle', type: 'Poetry' },
    { model: 'rapper', type: 'Lyricist' },
  ],
  picAllowedExt: ['jpg', 'jpeg', 'png'],
  // _TODO: why do we need "%2F"?
  avatarStoragePath:
    'https://firebasestorage.googleapis.com/v0/b/smck-media.appspot.com/o/avatars%2F',
  avatarStorageFolder: 'avatars',
  defaultOracleResponseLength: 100,
};
