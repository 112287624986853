import React from "react";

import {
  getFirestoreData,
  updateFirestoreWithOneObject
} from "store/actions/firestoreActions.jsx";
import { startSetUser, updateUser } from "store/actions/profileActions.jsx";
import moment from "moment";
import { db, firebase } from "firebase/fbConfig.js";
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetCasesList = uid => {
  return dispatch => {
    db.collection("TEST-users")
      .doc(uid)
      .collection("cases")
      .get()
      .then(
        snapshot => {
          const data = [];
          // Parse data into array like in firebase.js
          snapshot.forEach(doc => {
            var docData = doc.data();

            var docObj = {
              id: doc.id,
              caseNumber: docData.caseNumber ? docData.caseNumber : "N/A",
              caseType: docData.caseType ? docData.caseType : "N/A",
              clientFirstName: docData.clientFirstName
                ? docData.clientFirstName
                : "N/A",
              clientLastName: docData.clientLastName
                ? docData.clientLastName
                : "N/A",
              createdDate: docData.createdDate ? docData.createdDate : "N/A",
              serviceNeeded: docData.serviceNeeded
                ? docData.serviceNeeded
                : "N/A",
              summary: docData.summary ? docData.summary : "N/A"
            };
            data.push(docObj);
          });
          return data;
        },
        error => {
          console.log("error fetching data: ", error);
        }
      )
      .then(data => {
        dispatch(setCasesList(data));
      });
  };
};

// Get Users for Users Panel
export const startGetCurrentCase = (uid, id) => {
  return dispatch => {
    db.collection("TEST-users")
      .doc(uid)
      .collection("cases")
      .doc(id)
      .get()
      .then(
        snapshot => {
          var docData = snapshot.data();
          if (docData) {
            var docObj = {
              id: snapshot.id,
              caseType: docData.caseType ? docData.caseType : "N/A",
              clientFirstName: docData.clientFirstName
                ? docData.clientFirstName
                : "N/A",
              clientLastName: docData.clientLastName
                ? docData.clientLastName
                : "N/A",
              createdDate: docData.createdDate ? docData.createdDate : "N/A",
              serviceNeeded: docData.serviceNeeded
                ? docData.serviceNeeded
                : "N/A",
              summary: docData.summary ? docData.summary : "N/A"
            };
            return docObj;
          } else {
            return null;
          }
        },
        error => {
          console.log("error fetching data: ", error);
        }
      )
      .then(data => {
        dispatch(getCurrentCase(data));
      });
  };
};

export const setCasesList = dataRows => ({
  type: "SET_CASES_LIST",
  dataRows
});

export const getCurrentCase = dataRows => ({
  type: "GET_CURRENT_CASE",
  dataRows
});
