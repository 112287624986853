import React, { useState } from 'react';
import PropTypes from 'prop-types';

// custom tabs-UI with Icons
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import VpnLockOutlinedIcon from '@material-ui/icons/VpnLockOutlined';
import NaturePeopleOutlinedIcon from '@material-ui/icons/NaturePeopleOutlined';
import FormatShapesSharpIcon from '@material-ui/icons/FormatShapesSharp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import SortTabs from 'components/CustomTabs/SortTabs.jsx';
import PostTabs from 'components/CustomTabs/PostTabs.jsx';
import SearchInput from 'components/SearchInput';

// core components
import FilteredTopicList from 'components/FilteredTopicList';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import VideoCategoryItem from '../VideoCategoryItem/VideoCategoryItem';
import categoryTopics from '../../config/categoryTopics';

const allTopics = categoryTopics.reduce((r, { category, topics }) => {
  const entry = topics.map((topic) => ({ category, topic: topic.name }));
  return r.concat(entry);
}, []);

const VideoForm = ({ onSubmit }) => {
  const [type, setType] = useState('public');
  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const getTopics = async (input) => {
    if (!input) {
      setSearchResults([]);
      return;
    }
    const results = [];
    const reg = new RegExp(input, 'i');
    allTopics.forEach((item) => {
      if (reg.test(item.topic)) {
        results.push(item);
      }
    });
    setSearchResults(results);
  };

  const handleChange = (input) => {
    setTopic(input);
    getTopics(input);
  };

  const onSelectTopic = ({ category, topic }) => {
    setCategory(category);
    setTopic(topic);
    // Delete search results on selecting a topic
    setSearchResults([]);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer
            style={{
              backgroundColor: '#232020',
              borderRadius: '10px 10px 0px 0px',
              margin: '0px 0px -20px 0px',
              width: '100%',
            }}
          >
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h3
                style={{
                  fontSize: '28px',
                  textAlign: 'center',
                  fontWeight: '600',
                  color: '#FFFFFF',
                }}
              >
                {' '}
                Record & Post
              </h3>
            </GridItem>
          </GridContainer>

          <Card style={{ backgroundColor: '#232020', paddingBottom: '60px' }}>
            <GridContainer justify="center">
              {/* Private and Public Posts */}
              <div>
                <PostTabs
                  onSelect={(type) => setType(type)}
                  headerColor="primary"
                  tabs={[
                    {
                      tabValue: 'public',
                      tabName: 'Public Post',
                      tabIcon: PublicOutlinedIcon,
                      tabContent: (
                        <div style={{ padding: '0px 0px 25px 0px' }}>
                          <GridContainer style={{ width: '100%' }}>
                            <GridItem xs={4} />
                          </GridContainer>
                        </div>
                      ),
                    },
                    {
                      tabValue: 'private',
                      tabName: 'Private Post',
                      tabIcon: VpnLockOutlinedIcon,
                      tabContent: (
                        <div style={{ padding: '0px 0px 25px 0px' }}>
                          <GridContainer style={{ width: '100%' }}>
                            <GridItem xs={4} />
                          </GridContainer>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </GridContainer>

            {/* SEARCH INPUT */}
            <GridContainer>
              <GridItem xs={11} sm={11} md={11} lg={11}>
                <div
                  style={{
                    backgroundColor: '#67898C',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 15px -5px rgba(0,0,0,0)',
                    width: '90%',
                    margin: '-20px -34px 0px 35px',
                  }}
                >
                  <SearchInput
                    value={topic}
                    onInput={handleChange}
                    placeholder="Select a topic..."
                  />
                </div>
                {!!searchResults.length && (
                  <ClickAwayListener onClickAway={() => setSearchResults([])}>
                    <FilteredTopicList
                      list={searchResults}
                      onSelect={onSelectTopic}
                    />
                  </ClickAwayListener>
                )}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} style={{ margin: '0px 0px 0px 14px' }}>
                <h4
                  style={{
                    marginLeft: '10px',
                    marginTop: '30px',
                    fontSize: '14px',
                    color: '#FFFFFF',
                  }}
                >
                  Sort:
                </h4>
              </GridItem>
              <div
                xs={8}
                sm={8}
                md={8}
                lg={8}
                style={{ marginLeft: '60px', marginTop: '-57px' }}
              >
                <SortTabs
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: 'Most Popular',
                      tabIcon: NaturePeopleOutlinedIcon,
                      tabContent: (
                        <div style={{ padding: '0px 35px 5px 0px' }}>
                          {categoryTopics
                            .filter((item) => item.popular)
                            .map((item) => (
                              <VideoCategoryItem
                                key={item.category}
                                item={item}
                                onSelectTopic={onSelectTopic}
                              />
                            ))}
                        </div>
                      ),
                    },
                    {
                      tabName: 'Alphabetical',
                      tabIcon: FormatShapesSharpIcon,
                      tabContent: (
                        <div style={{ padding: '0px 35px 5px 0px' }}>
                          {categoryTopics.map((item) => (
                            <VideoCategoryItem
                              key={item.category}
                              item={item}
                              onSelectTopic={onSelectTopic}
                            />
                          ))}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ margin: '-80px 0px 0px -15px' }}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card
            style={{
              backgroundColor: '#E74B4B',
              borderRadius: '0px 0px 10px 10px',
              width: '100%',
            }}
          >
            <Button
              style={{ margin: '0rem 1px' }}
              simple
              key="close"
              aria-label="Close"
              // When submitting form we pass the
              // video type and category to the parent cb
              onClick={() => onSubmit({ type, topic, category })}
            >
              <h3
                style={{
                  fontWeight: '600',
                  color: '#FFFFFF',
                  fontSize: '20px',
                  margin: '15px 0px',
                  textAlign: 'center',
                }}
              >
                Start Recording
              </h3>
            </Button>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

VideoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VideoForm;
