import React, { useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Style from 'assets/jss/material-dashboard-pro-react/components/commentStyle.jsx';
import SendIcon from '@material-ui/icons/Send';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DefaultAvatar from 'assets/img/default-avatar.png';

const CommentBox = ({ comments, classes, send, close }) => {
  const placeholder = 'Add a comment';
  const [value, setValue] = useState(placeholder);

  const onChange = ({ target: { value } }) => {
    setValue(value);
  };

  const onFocus = () => {
    if (value === placeholder) {
      setValue('');
    }
  };

  const onFocusOut = () => {
    if (value.trim() === '') {
      setValue(placeholder);
    }
  };

  const onSend = () => {
    const val = value.trim();
    if (val === placeholder) return;
    send(val);
    setValue(placeholder);
  };

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 100000,
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        width: '98%',
        bottom: '70px',
        textAlign: 'center',
        opacity: 0.9,
        color: 'white',
        padding: '10px',
        background: '#333E46',
      }}
    >
      <div style={{ position: 'absolute', marginTop: '-20px', marginLeft: '45%' }}>
        <HighlightOffIcon onClick={close} />
      </div>
      <List>
        {comments.sort((a, b) => a.createdAt - b.createdAt).map((e) => (
          <ListItem key={e.id}>
            <ListItemAvatar>
              <Avatar src={e.avatar || DefaultAvatar} />
            </ListItemAvatar>
            <ListItemText
              classes={{
                primary: classes.listText,
                secondary: classes.listText,
              }}
              primary={e.displayName}
              secondary={e.comment}
            />
          </ListItem>
        ))}
      </List>
      <ClickAwayListener onClickAway={onFocusOut}>
        <TextField
          id="filled-basic"
          variant="filled"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          className={classes.textField}
          InputProps={{
            className: classes.input,
          }}
        />
      </ClickAwayListener>
      <div
        style={{
          color: 'white',
          position: 'relative',
          top: '15px',
          left: '10px',
          display: 'inline-block',
        }}
      >
        <SendIcon onClick={onSend} />
      </div>
    </div>
  );
};

CommentBox.propTypes = {
  classes: PropTypes.object.isRequired,
  comments: PropTypes.array,
};

export default withStyles(Style)(CommentBox);
