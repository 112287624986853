import store from 'services/StoreService';

// export const setGPTModels = (data) => ({
//   type: 'SET_GPT_MODELS',
//   data,
// });

export const getGPTModels = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_GPT_MODELS',
      data: await store.getGPTModels(),
    });
  };
};
