import React from 'react';
import PropTypes from 'prop-types';
import withStyle from '@material-ui/core/styles/withStyles';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';

export const SearchInput = ({ onInput, placeholder, color, value }) => (
  <div
    style={{
      backgroundColor: '#a8a7a7',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0)',
      width: '100%',
      height: '8vh',
      margin: '68px 0 0 0px',
      borderRadius: '5px',
    }}
  >
    <SearchIcon
      style={{
        color: '#FFFFFF',
        margin: '-30px 10px -8px 10px',
      }}
    />
    <CustomInput
      formControlProps={{
        style: { width: '80%', margin: '-10px 0px 10px 0px' },
      }}
      id="query"
      inputProps={{
        value,
        placeholder,
        onChange: ({ target: { value } }) => onInput(value),
        inputProps: {
          'aria-label': 'Search',
        },
      }}
    />
    {/* <div
      style={{
        backgroundColor: '#f1659b',
        height: '28px',
        width: '7%',
        borderRadius: '13px',
        display: 'inline-block',
        boxShadow: '0px 0px 15px -5px rgba(0,0,0,1.75)',
      }}
    >
      <AddIcon />
    </div> */}
  </div>
);

SearchInput.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string,
  onInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default withStyle(Style)(SearchInput);
