import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import Button from 'components/CustomButtons/Button.jsx';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';

export const ConnectedButton = ({ connected, connectUser, unConnectUser }) => (
  <div>
    {/* Desktop */}
    <Hidden xsDown implementation='css'>
      <Button
        style={{
          margin: '-80% 0% 0% 18%',
          borderRadius: '50px',
          boxShadow: 'none',
          backgroundColor: '#6E9290',
        }}
        color='primary'
        onClick={connected ? unConnectUser : connectUser}
      >
        <b>
          {connected ? 'Connected' : 'Connect'}
          {connected ? (
            <CheckBoxIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '17px',
                height: '17px',
              }}
            />
          ) : (
            <SettingsEthernetIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '24px',
                height: '24px',
              }}
            />
          )}
        </b>
      </Button>
    </Hidden>
    {/* Mobile */}
    <Hidden smUp implementation='css'>
      <Button
        style={{
          margin: '-120% 0% 0% 2%',
          borderRadius: '50px',
          boxShadow: 'none',
          backgroundColor: '#6E9290',
        }}
        color='primary'
        onClick={connected ? unConnectUser : connectUser}
      >
        <b>
          {connected ? 'Connected' : 'Connect'}
          {connected ? (
            <CheckBoxIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '17px',
                height: '17px',
              }}
            />
          ) : (
            <SettingsEthernetIcon
              style={{
                margin: '-5px 0px -5px 15px',
                width: '24px',
                height: '24px',
              }}
            />
          )}
        </b>
      </Button>
    </Hidden>
  </div>
);

ConnectedButton.propTypes = {
  connected: PropTypes.bool.isRequired,
  connectUser: PropTypes.func.isRequired,
  unConnectUser: PropTypes.func.isRequired,
};

export default ConnectedButton;
