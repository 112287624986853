import React from 'react';
import ChartistGraph from "react-chartist";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import avatar from 'assets/img/faces/marc.jpg';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SearchInput from 'components/SearchInput';
import SectionCarousel from './Sections/SectionCarousel.jsx';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import {
    roundedLineChart,
    straightLinesChart,
    simpleBarChart,
    colouredLineChart,
    multipleBarsChart,
    colouredLinesChart,
    pieChart,
} from "variables/charts.jsx";

export const AnalyticsPage = ({ onInput, timeframe, handleChange, classes }) => {
    return (
        <div>
            <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
                <GridItem xs={8}>
                    <SearchInput placeholder="Search All Artists" onInput={onInput} />
                </GridItem>
                <GridItem xs={4} style={{ margin: '69px 0 0px 0px' }}>
                    <FormControl variant="filled" style={{ width: '130px' }}>
                        <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={timeframe}
                            onChange={handleChange}
                        >
                            <MenuItem value={'weekly'}>Weekly</MenuItem>
                            <MenuItem value={'monthly'}>Monthly</MenuItem>
                            <MenuItem value={'yearly'}>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
            <SectionCarousel style={{ margin: '20px 0 50px 0' }} />
            <GridContainer style={{ display: 'flex', justifyContent: 'space-around', background: 'linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(98,97,99,1) 100%)', color: '#fff', height: '50vh' }}>
                <GridItem xs={4}>
                    <img src={avatar} style={{
                        height: '18vh',
                        borderRadius: '50%',
                        margin: '20px 0px 0px 0px',
                        display: 'inline-block',
                    }} />
                </GridItem>
                <GridItem xs={6} style={{ margin: '10px 0 -60px 0' }}>
                    <h5><b>Glass Animals</b></h5>
                    <p>Band Members: Dave Bayley, Joe Seaward, Edmund-Irwin Singer, Drew MacFarlane</p>
                    <button style={{ width: '200px', color: '#fff', background: 'linear-gradient(176deg, rgba(98,97,99,1) 0%, rgba(181,181,181,1) 100%)' }}>VIEW FULL PROFILE</button>
                </GridItem>
                <GridContainer>
                    <GridItem xs={12} style={{ margin: '45px 0 0 0' }}>
                        <CardHeader color="info">
                            <h4><b>Total Streams</b></h4>
                            <p>Glass Animals</p>
                            <ChartistGraph
                                className="ct-chart-white-colors"
                                data={roundedLineChart.data}
                                type="Line"
                                options={roundedLineChart.options}
                                listener={roundedLineChart.animation}
                            />
                        </CardHeader>
                    </GridItem>
                </GridContainer>
            </GridContainer>
            <GridContainer style={{ backgroundColor: '#f4719b', height: '80vh', color: '#fff' }}>
                <GridItem xs={6} style={{ margin: '80px 0 0 0' }}>
                    <CardHeader>
                        <h4><b>STREAMS BY SOURCE</b></h4>
                        <p>Glass Animals</p>
                        <ChartistGraph
                            data={pieChart.data}
                            type="Pie"
                            options={pieChart.options}
                        />
                    </CardHeader>
                </GridItem>
                <GridItem xs={6} style={{ margin: '105px 0 0 0' }}>
                    <FormControl variant="filled" style={{ width: '130px', margin: '0 0 20px 65px' }}>
                        <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={timeframe}
                            onChange={handleChange}
                        >
                            <MenuItem value={'weekly'}>Weekly</MenuItem>
                            <MenuItem value={'monthly'}>Monthly</MenuItem>
                            <MenuItem value={'yearly'}>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                    <Table
                        tableData={[
                            ['Other', '980k'],
                            ['iTunes', '582k'],
                            ['Spotify', '1.5m'],
                            ['YouTube', '890k'],
                        ]}
                        coloredColls={[2]}
                    />
                </GridItem>
                <GridItem xs={11} style={{ background: 'linear-gradient(93deg, rgba(43,43,241,1) 0%, rgba(4,14,185,1) 100%)', borderRadius: '5px', margin: '0 0 0 18px' }}>
                    <GridContainer style={{
                        display: 'flex', justifyContent: 'space-around'
                    }}>
                        <GridItem xs={4}>
                            <h4><b>SONG STREAMS</b></h4>
                            <p>Glass Animals</p>
                        </GridItem>
                        <GridItem xs={4}>
                            <FormControl variant="filled" style={{ width: '130px', margin: '0 0 20px 0' }}>
                                <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={timeframe}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                                    <MenuItem value={'monthly'}>Monthly</MenuItem>
                                    <MenuItem value={'yearly'}>Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                    <Table
                        tableHead={['Rank', 'Song Title', 'Album', 'Streams']}
                        tableData={[
                            ['1', 'Pork Soda', 'Dreamland', '1.52m'],
                            ['2', 'Dreamland', 'Dreamland', '1.52m'],
                            ['3', 'Heat Waves', 'Dreamland', '1.52m'],
                            ['4', 'Tangerine', 'Dreamland', '1.52m'],
                            ['5', 'Poplar St.', 'Dreamland', '1.52m'],
                            ['6', 'Youth', 'Dreamland', '1.52m'],
                            ['7', 'Life Itself', 'Dreamland', '1.52m'],
                        ]}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer style={{ backgroundColor: '#83c965', height: '135vh', color: '#fff' }}>
                <GridItem xs={6}>
                    <div style={{ margin: '340px 0 0px 20px' }}>
                        <h4><b>SOCIAL MEDIA</b></h4>
                        <p>Glass Animals</p>
                    </div>
                </GridItem>
                <GridItem xs={4}>
                    <FormControl variant="filled" style={{ width: '130px', margin: '340px 0 20px 55px' }}>
                        <InputLabel id="demo-simple-select-filled-label">Timeframe</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={timeframe}
                            onChange={handleChange}
                        >
                            <MenuItem value={'weekly'}>Weekly</MenuItem>
                            <MenuItem value={'monthly'}>Monthly</MenuItem>
                            <MenuItem value={'yearly'}>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={6}>
                    <FormControl variant="filled" style={{ width: '150px', margin: '0 0 0 20px' }}>
                        <InputLabel id="demo-simple-select-filled-label">Media Source</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={timeframe}
                            onChange={handleChange}
                        >
                            <MenuItem value={'weekly'}>Facebook</MenuItem>
                            <MenuItem value={'monthly'}>YouTube</MenuItem>
                            <MenuItem value={'yearly'}>Instagram</MenuItem>
                            <MenuItem value={'yearly'}>Twitter</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={6}>
                    <div style={{ margin: '0 0 0 60px' }}>
                        <h4><b>88.7k</b></h4>
                        <p>Interactions Total</p>
                    </div>
                </GridItem>
                <GridItem xs={12} style={{ margin: '0 0 0 0px' }}>
                    <ChartistGraph
                        data={colouredLinesChart.data}
                        type="Line"
                        options={colouredLinesChart.options}
                        listener={colouredLinesChart.animation}
                    />
                </GridItem>
                <GridContainer style={{
                    display: 'flex', justifyContent: 'space-around'
                }}>
                    <GridItem xs={6}>
                        <Table
                            tableData={[
                                ['Comments', '1.62k'],
                                ['Likes', '10.78k'],
                                ['Views', '78.3k'],
                            ]}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <div style={{ backgroundColor: '#fff', borderRadius: '50%', height: '20vh', color: 'black', display: 'block' }}>
                            <h3 style={{margin: '0 0 0 10px'}}><b>6.3%</b></h3>
                            <p>Interactions</p>
                        </div>
                    </GridItem>
                </GridContainer>
            </GridContainer>
            <GridContainer style={{ display: 'flex', justifyContent: 'space-around', background: 'linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(98,97,99,1) 100%)', height: '15vh'}}>
                <button style={{ width: '350px', height: '7vh', color: '#fff', background: 'linear-gradient(176deg, rgba(98,97,99,1) 0%, rgba(181,181,181,1) 100%)', margin: '30px 0 0 0' }}>SYNC ANALYTICS</button>
            </GridContainer>
        </div>
    )
}

export default AnalyticsPage;