import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// @material-ui/icons
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Loader from 'components/Loader';
import CommentBox from 'components/CommentBox';
import store from 'services/StoreService';
import { updateUser } from 'store/actions/profileActions';
import PostActions from './PostActions';

const ViewPost = ({ updateUser, history, classes, user, id }) => {
  let vid;
  const [playing, setPlaying] = useState(false);
  const [postData, setPostData] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsVisible, setCommentsVisible] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const setPostStatus = async (type) => {
    const recordings = user.recordings.map(
      (e) => (e.id === id ? { ...e, type } : e)
    );
    await store.setRecordingType(id, type);
    // _TODO: remove as recordings collection should be the single source of truth
    await store.updateUserRecordings(user.uid, recordings);
    // Update app state
    updateUser({ recordings });
    // redirect
    history.push('/library');
  };

  const toggleComments = () => {
    setCommentsVisible(!commentsVisible);
  };

  const getData = async () => {
    const data = await store.getOneRecordingData(id);
    setPostData(data);
    const comments = await store.getRecordingComments(id);
    setComments(comments);
  };

  const addComment = async (comment) => {
    const { displayName, avatar } = user;
    const userData = { displayName, avatar: avatar || '' };
    const cid = await store.addComment(id, comment, userData);
    setComments(comments.concat({ id: cid, comment, ...userData }));
  };

  const playVideo = () => {
    vid.play();
    setPlaying(true);
  };

  const stopVideo = () => {
    vid.pause();
    setPlaying(false);
  };

  return !postData ? (
    <Loader />
  ) : (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <GridItem xs={12} md={12}>
          <Card
            style={{
              marginTop: '-75px',
              marginBottom: '0vh',
              marginLeft: '-2%',
              width: '104%',
            }}
            className={classes.textWhite}
          >
            <div
              style={{ margin: '70px 20px 0px 20px' }}
              className={classes.cardImgOverlay}
            >
              <h6
                style={{
                  textTransform: 'uppercase',
                  color: 'white',
                  fontWeight: '400',
                  fontSize: '14px',
                  marginBottom: '-30px',
                }}
              >
                {postData.category}
              </h6>
              <h5 className={classes.title}>{postData.topic}</h5>
              <h5 className={classes.title}>{postData.description}</h5>
            </div>
            <div className={classes.imgDarken}>
              <video
                ref={(v) => {
                  vid = v;
                }}
                style={{
                  height: '101vh',
                  width: '300%',
                  borderRadius: '0px',
                  marginLeft: '-100%',
                }}
                className={classes.feedImg}
                data-holder-rendered="true"
                autoPlay={false}
                loop
              >
                <source type="video/mp4" src={postData.url} />
              </video>
            </div>
          </Card>
        </GridItem>
        {playing ? (
          <StopIcon
            onClick={stopVideo}
            style={{
              bottom: '75px',
              position: 'fixed',
              zIndex: '1030',
              color: '#D51515',
              fontSize: '60px',
              width: '100%',
              margin: '0 auto',
            }}
          />
        ) : (
          <PlayArrowIcon
            onClick={playVideo}
            style={{
              bottom: '75px',
              position: 'fixed',
              zIndex: '1030',
              color: '#D51515',
              fontSize: '60px',
              width: '100%',
              margin: '0 auto',
            }}
          />
        )}
        {commentsVisible ? (
          <CommentBox send={addComment} comments={comments} close={toggleComments} />
        ) : (
          <PostActions
            isOwner={postData.uid === user.uid}
            postStatus={postData.type}
            setPostStatus={setPostStatus}
            toggleComments={toggleComments}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

ViewPost.propTypes = {
  id: PropTypes.string.isRequired,
  uid: PropTypes.string,
  history: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    displayName: PropTypes.string,
    recordings: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const { uid, avatar, displayName, recordings } = state.profile || {};
  return { id, user: { uid, avatar, displayName, recordings } };
};

export default compose(
  connect(
    mapStateToProps,
    { updateUser }
  ),
  withStyles(Style)
)(ViewPost);
