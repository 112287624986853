import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import carouselStyle from 'assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx';

class SectionCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <Carousel {...settings}>
        <div>
          <center>
            Transparently representing artists and record labels using AI predictive
            forecasting.
          </center>
        </div>
        <div>
          <center>
            Enabling artists and writers with superior music resource sharing
            technology.
          </center>
        </div>
        <div>
          <center>
            Managing and understanding your royalty payments has never been so clear.
          </center>
        </div>
      </Carousel>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);
