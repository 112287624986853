import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import forest from 'assets/img/arcq/forest.png';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

export const CategoryHeader = ({ classes, description, title }) => (
  <GridContainer>
    <GridItem xs={12}>
      {/* Mobile  View HTML  */}
      <Hidden smUp implementation="css">
        {/* Images with cardImgOverlay */}
        <GridContainer>
          <GridItem xs={11} sm={11} md={11} lg={11} style={{ marginLeft: '-16px' }}>
            <div className={classes.cardImgOverlay}>
              <div
                style={{
                  margin: '242px 20px 0px 20px',
                  fontSize: '16px',
                  color: '#FFFFFF',
                }}
                className={classes.bodyText}
              >
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginBottom: '15px',
                  }}
                >
                  {title}
                </p>
                <p>{description}</p>
              </div>
            </div>
            <div className={classes.imgDarken}>
              <img
                style={{
                  opacity: '1.6',
                  width: '123%',
                  marginBottom: '-10px',
                  marginTop: '-70px',
                }}
                className={classes.cardImg}
                src={forest}
                alt="..."
                data-holder-rendered="true"
              />
            </div>
          </GridItem>
        </GridContainer>
      </Hidden>
      {/* Desktop View HTML  */}
      <Hidden smDown implementation="css">
        {/* Images with cardImgOverlay */}
        <GridContainer>
          <GridItem xs={11} sm={11} md={11} lg={11} style={{ marginLeft: '-16px' }}>
            <div className={classes.cardImgOverlay}>
              <h3>Environmentalism</h3>
              <p
                style={{
                  margin: '98% 0px 0px -10px',
                  fontSize: '16px',
                  color: '#FFFFFF',
                }}
                className={classes.bodyText}
              >
                Environmentalism or environmental rights is a broadphilosophy,
                ideology,and social movement regardingconcerns for environmental
                protection and improvement ofthe health of the environment.
              </p>
            </div>
            <div className={classes.imgDarken}>
              <img
                style={{
                  opacity: '1.6',
                  width: '118%',
                  marginBottom: '-10px',
                  marginTop: '-90px',
                }}
                className={classes.cardImg}
                src={forest}
                alt="..."
                data-holder-rendered="true"
              />
            </div>
          </GridItem>
        </GridContainer>
      </Hidden>
    </GridItem>
  </GridContainer>
);

CategoryHeader.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
};

export default withStyles(Style)(CategoryHeader);
