import React from 'react';
import PropTypes from 'prop-types';

import ProfileForm from 'components/Forms/profileForm.jsx';
import PicUpload from 'components/CustomUpload/profilePicUpload';
import Button from 'components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import withStyle from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';
import Transition from 'components/Transition';
import DefaultAvatar from 'assets/img/default-avatar.png';

export const ModalUpdateProfile = ({
  classes,
  open,
  user,
  uid,
  onError,
  onClose,
  onUploadPic,
}) => (
  <Dialog
    classes={{
      root: classes.modalRoot,
      paper: classes.modal + ' ' + classes.modalSmall,
    }}
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={onClose}
    aria-labelledby="shipment-modal-slide-title"
    aria-describedby="shipment-modal-slide-description"
  >
    <DialogTitle
      style={{
        backgroundColor: '#2B4F5C',
        margin: '0px 15px',
        borderRadius: '10px 10px 0px 0px',
      }}
      id="shipment-modal-slide-title"
      disableTypography
      className={classes.modalHeader}
    >
      <Button
        style={{
          fill: 'white',
          position: 'relative',
          zIndex: '9999',
        }}
        simple
        className={classes.modalCloseButton}
        key="close"
        aria-label="Close"
        onClick={onClose}
      >
        {' '}
        <CloseIcon className={classes.modalClose} />
      </Button>
      {/* <h2 className={classes.modalTitle}>EDIT PROFILE</h2> */}
    </DialogTitle>
    <DialogContent
      style={{
        backgroundColor: 'rgb(43, 79, 92)',
        borderRadius: '0px 0px 10px 10px',
        padding: '0px 20px 0px 20px',
        margin: '0px 15px 20px 15px',
      }}
      id="shipment-modal-slide-description"
      className={classes.modalBody}
    >
      {/* FORM */}
      <div>
        <h5
          style={{
            color: 'white',
            marginTop: '-25px',
            marginBottom: '20px',
            marginLeft: '0px',
            textAlign: 'left',
          }}
          className={classes.bodyText}
        >
          {user.email}
        </h5>
        <PicUpload
          uid={uid}
          onError={onError}
          onSuccess={onUploadPic}
          currentPic={user.avatar || DefaultAvatar}
        />
        <ProfileForm user={user} uid={uid} modalOpen={open} onSave={onClose} />
      </div>
    </DialogContent>
  </Dialog>
);

ModalUpdateProfile.propTypes = {
  uid: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadPic: PropTypes.func.isRequired,
};

export default withStyle(Style)(ModalUpdateProfile);
