import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import LibraryTabs from 'components/CustomTabs/LibraryTabs.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import LibraryContainer from 'components/LibraryContainer';
import Card from 'components/Card/Card.jsx';
import LibraryByDate from 'components/LibraryByDate';
import Loader from 'components/Loader';
import { capitalize } from '../../utils';

// actions
import { setScreenName } from 'store/actions/screenNameActions';

// Images
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import forest from 'assets/img/arcq/forest.png';
import gameboy from 'assets/img/arcq/gameboy.png';
import FamilyReunion from 'assets/img/arcq/FamilyReunion.jpg';
import Parties from 'assets/img/arcq/Parties.jpg';
import Weather from 'assets/img/arcq/weather.jpg';
import FamilyHeritage from 'assets/img/arcq/FamilyHertiage.jpg';

const Library = ({ uid, type, classes, recordings, setScreenName }) => {
  const sampleLength = 3;
  const [showType, setShowType] = useState(type);

  // The recordings to display: we only keep the type
  // selected if any, but all recordings if no type selected
  const recordingsToDisplay = showType
    ? recordings.filter((e) => e.type === showType)
    : recordings || [];

  // Group the recordins to display by formatted date
  const recordingsByDate = groupBy(
    recordingsToDisplay.sort((a, b) => b.createdAt - a.createdAt).map((e) => {
      const createdAt = moment(e.createdAt).format('MMMM DD YYYY');
      return { ...e, createdAt };
    }),
    'createdAt'
  );

  // Determines what type of recording we display
  const types = showType ? [showType] : ['public', 'private', 'archived'];

  // Determines the number of recordings we dislay (all if one type selected)
  const listLength = showType ? recordings.length : sampleLength;
  const buttontext = showType ? 'Library' : 'View All';

  const viewItems = (type) => {
    setShowType(!showType ? type : false);
    // Name in the header
    setScreenName(!showType ? `${capitalize(type)} Library` : null);
  };

  return uid ? (
    <div style={{ padding: '0px 20px 100px 20px' }}>
      <GridContainer>
        <GridItem xs={12}>
          {/* Private and Public Posts */}
          <div style={{marginTop: '10%'}}>
            <LibraryTabs
              headerColor="primary"
              tabs={[
                {
                  tabName: 'All',
                  tabContent: (
                    <GridContainer
                      style={{
                        margin: '10px 0px -24px 0px',
                        width: '100%',
                        padding: '0px 0px 15px 0px',
                      }}
                    >
                      {types.map((type) => (
                        <LibraryContainer
                          list={recordings
                            .filter((e) => e.type === type)
                            .slice(0, listLength)}
                          key={type}
                          type={type}
                          buttonText={buttontext}
                          onButtonClick={() => viewItems(type)}
                        />
                      ))}
                    </GridContainer>
                  ),
                },
                {
                  tabName: 'Date',
                  tabContent: <LibraryByDate data={recordingsByDate} />,
                },
                {
                  tabName: 'Topic',
                  tabContent: (
                    <div style={{ padding: '0px 0px 15px 0px' }}>
                      <GridContainer
                        style={{
                          margin: '10px 0px -24px 0px',
                          width: '100%',
                          padding: '0px 0px 0px 0px',
                        }}
                      >
                        <GridContainer
                          style={{
                            padding: '15px 15px 0px 15px',
                            width: '100%',
                            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                            borderRadius: '10px',
                            backgroundColor: '#1B3D59',
                            margin: '15px 0px 0px 0px',
                          }}
                        >
                          <GridItem xs={12}>
                            <h4
                              style={{
                                margin: '0px 10px 10px 0px',
                                fontSize: '22px',
                              }}
                              className={classes.title}
                            >
                              Environmentalism
                            </h4>
                          </GridItem>
                          <GridItem xs={12}>
                            <p style={{ color: 'white' }}>
                              Environmentalism is a broad philosophy, ideology, and
                              social movement regarding concerns for environmental
                              protection and improvement of the health of the
                              environment.
                            </p>
                          </GridItem>
                          <GridItem xs={6}>
                            <Link
                              to={{
                                pathname: '/view-post',
                              }}
                            >
                              <Card className={classes.textWhite}>
                                <div className={classes.cardImgOverlay}>
                                  <h4
                                    style={{
                                      marginTop: '0px',
                                      fontSize: '16px',
                                    }}
                                    className={classes.bodyText}
                                  >
                                    <strong>Trees & Plants</strong>
                                  </h4>
                                </div>
                                <div className={classes.imgDarken}>
                                  <img
                                    style={{
                                      boxShadow:
                                        '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                    }}
                                    className={classes.cardImg}
                                    src={forest}
                                    alt="..."
                                    data-holder-rendered="true"
                                  />
                                </div>
                              </Card>
                            </Link>
                          </GridItem>
                          <GridItem xs={6}>
                            <Link
                              to={{
                                pathname: '/view-post',
                              }}
                            >
                              <Card className={classes.textWhite}>
                                <div className={classes.cardImgOverlay}>
                                  <h4
                                    style={{
                                      marginTop: '0px',
                                      fontSize: '16px',
                                    }}
                                    className={classes.bodyText}
                                  >
                                    <strong>Weather Patterns</strong>
                                  </h4>
                                </div>
                                <div className={classes.imgDarken}>
                                  <img
                                    className={classes.cardImg}
                                    src={Weather}
                                    alt="..."
                                    data-holder-rendered="true"
                                  />
                                </div>
                              </Card>
                            </Link>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{
                            padding: '15px 15px 0px 15px',
                            width: '100%',
                            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                            borderRadius: '10px',
                            backgroundColor: '#1B3D59',
                            margin: '15px 0px 0px 0px',
                          }}
                        >
                          <GridItem xs={12}>
                            <h4
                              style={{
                                margin: '0px 10px 10px 0px',
                                fontSize: '22px',
                              }}
                              className={classes.title}
                            >
                              Family Heritage
                            </h4>
                          </GridItem>

                          <GridItem xs={12}>
                            <p style={{ color: 'white' }}>
                              Family heritage is the legacy of physical artifacts and
                              intangible attributes of a family that is inherited
                              from past generations. Not all legacies of past
                              generations are "heritage", rather heritage is a
                              product of selection by society.
                            </p>
                          </GridItem>
                          <GridItem xs={6}>
                            <Card className={classes.textWhite}>
                              <div className={classes.cardImgOverlay}>
                                <h4
                                  style={{
                                    marginTop: '0px',
                                    fontSize: '16px',
                                  }}
                                  className={classes.bodyText}
                                >
                                  <strong>Family Reunions</strong>
                                </h4>
                              </div>
                              <div className={classes.imgDarken}>
                                <img
                                  style={{
                                    boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                  }}
                                  className={classes.cardImg}
                                  src={FamilyHeritage}
                                  alt="..."
                                  data-holder-rendered="true"
                                />
                              </div>
                            </Card>
                          </GridItem>
                          <GridItem xs={6}>
                            <Link
                              to={{
                                pathname: '/view-post',
                              }}
                            >
                              <Card className={classes.textWhite}>
                                <div className={classes.cardImgOverlay}>
                                  <h4
                                    style={{
                                      marginTop: '0px',
                                      fontSize: '16px',
                                    }}
                                    className={classes.bodyText}
                                  >
                                    <strong>Traditions</strong>
                                  </h4>
                                </div>
                                <div className={classes.imgDarken}>
                                  <img
                                    className={classes.cardImg}
                                    src={FamilyReunion}
                                    alt="..."
                                    data-holder-rendered="true"
                                  />
                                </div>
                              </Card>
                            </Link>
                          </GridItem>
                        </GridContainer>
                        <GridContainer
                          style={{
                            padding: '15px 15px 0px 15px',
                            width: '100%',
                            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                            borderRadius: '10px',
                            backgroundColor: '#1B3D59',
                            margin: '15px 0px 70px 0px',
                          }}
                        >
                          <GridItem xs={12}>
                            <h4
                              style={{
                                margin: '0px 10px 10px 0px',
                                fontSize: '22px',
                              }}
                              className={classes.title}
                            >
                              Recreation
                            </h4>
                          </GridItem>

                          <GridItem xs={12}>
                            <p style={{ color: 'white' }}>
                              Having fun is an art form. What do you do in your spare
                              time? Share it with the group.
                            </p>
                          </GridItem>
                          <GridItem xs={6}>
                            <Link
                              to={{
                                pathname: '/view-post',
                              }}
                            >
                              <Card className={classes.textWhite}>
                                <div className={classes.cardImgOverlay}>
                                  <h4
                                    style={{
                                      marginTop: '0px',
                                      fontSize: '16px',
                                    }}
                                    className={classes.bodyText}
                                  >
                                    <strong>Parties & Social</strong>
                                  </h4>
                                </div>
                                <div className={classes.imgDarken}>
                                  <img
                                    style={{
                                      boxShadow:
                                        '0px 0px 15px -5px rgba(0,0,0,0.75)',
                                    }}
                                    className={classes.cardImg}
                                    src={Parties}
                                    alt="..."
                                    data-holder-rendered="true"
                                  />
                                </div>
                              </Card>
                            </Link>
                          </GridItem>
                          <GridItem xs={6}>
                            <Link
                              to={{
                                pathname: '/view-post',
                              }}
                            >
                              <Card className={classes.textWhite}>
                                <div className={classes.cardImgOverlay}>
                                  <h4
                                    style={{
                                      marginTop: '0px',
                                      fontSize: '16px',
                                    }}
                                    className={classes.bodyText}
                                  >
                                    <strong>Video Games</strong>
                                  </h4>
                                </div>
                                <div className={classes.imgDarken}>
                                  <img
                                    className={classes.cardImg}
                                    src={gameboy}
                                    alt="..."
                                    data-holder-rendered="true"
                                  />
                                </div>
                              </Card>
                            </Link>
                          </GridItem>
                        </GridContainer>
                      </GridContainer>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  ) : (
    <Loader />
  );
};

Library.propTypes = {
  uid: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.object.isRequired,
  recordings: PropTypes.array,
  setScreenName: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { uid, recordings } = state.profile || {};
  const { type } = ownProps.match.params;

  return { uid, recordings, type };
};

export default compose(
  connect(
    mapStateToProps,
    { setScreenName }
  ),
  withStyles(Style)
)(Library);
