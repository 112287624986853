import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';
import { Link } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { capitalize } from '../../utils';

export const LibraryContainer = ({
  classes,
  type,
  list,
  buttonText,
  onButtonClick,
}) => (
  <GridContainer
    style={{
      padding: '15px',
      width: '107%',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
      borderRadius: '10px',
      backgroundColor: '#1B3D59',
      margin: '20px 0px 0px 0px',
    }}
  >
    <GridItem xs={8}>
      <h4
        style={{
          margin: '0px 10px 10px 0px',
          fontSize: '20px',
        }}
        className={classes.title}
      >
        {capitalize(type)} Responses
      </h4>
    </GridItem>
    {!!list.length && (
      <GridItem xs={3}>
        <h3
          style={{
            margin: '7px 5px 0px 0px',
            fontWeight: '600',
            fontSize: '14px',
            textAlign: 'right',
          }}
          onClick={onButtonClick}
          className={classes.title}
        >
          {buttonText}
        </h3>
      </GridItem>
    )}
    {!!list.length && (
      <GridItem xs={1}>
        <Link
          to={{
            pathname: `/${type}-list`,
          }}
        >
          <ArrowForwardIosRoundedIcon
            style={{
              color: '#ffff',
              fontSize: '14pt',
              margin: '6px -10px 0px 0px',
            }}
          />
        </Link>
      </GridItem>
    )}
    {!list.length ? (
      <div
        style={{
          color: '#CCC',
          fontWeight: 'bold',
        }}
      >
        You have no {type} posts yet
      </div>
    ) : (
      list.map((data) => (
        <GridItem key={data.id} xs={4}>
          <Link
            to={{
              pathname: `/view-post/${data.id}`,
            }}
          >
            <Card style={{ margin: '5px 0px' }} className={classes.textWhite}>
              <div className={classes.cardImgOverlay}>
                {data.type === 'archived' && (
                  <h4
                    style={{
                      color: '#2B2E34',
                      padding: '2px 5px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      backgroundColor: '#FAEA61',
                      margin: '50px 0px 15px 0px',
                      fontSize: '14px',
                    }}
                    className={classes.bodyText}
                  >
                    <strong>Archived</strong>
                  </h4>
                )}
              </div>
              <div>
                <video className={classes.feedImg} data-holder-rendered="true">
                  <source type="video/mp4" src={data.url} />
                </video>
              </div>
            </Card>
          </Link>
        </GridItem>
      ))
    )}
  </GridContainer>
);

LibraryContainer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  type: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default withStyles(Style)(LibraryContainer);
