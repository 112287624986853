import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';
import DefaultAvatar from 'assets/img/default-avatar.png';
import Hidden from '@material-ui/core/Hidden';
import EditIcon from '@material-ui/icons/Edit';

export const ProfileViewPic = ({ avatar, classes }) => (
  <GridContainer justify="center" style={{ margin: '10px 0px 0px 0px ' }}>
    {/* Desktop */}
    <Hidden xsDown implementation="css">
      <GridItem xs={12} style={{ minHeight: '200px' }}>
        <img
          style={{
            margin: '0% 0% 2% 0%',
            width: '102%',
            borderRadius: '10px',
          }}
          className={classes.profilePic}
          src={avatar || DefaultAvatar}
          alt="User Photo"
        />
      </GridItem>
    </Hidden>

    {/* Mobile */}
    <Hidden smUp implementation="css">
      <GridItem xs={12} style={{ minHeight: '200px' }}>
        <img
          style={{
            float: 'left',
            margin: '63px 0px 0px -40px',
            boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.75)',
            borderRadius: '50%',
            width: '40vw',
          }}
          className={classes.profilePic}
          src={avatar || DefaultAvatar}
          alt="User Photo"
        />
      </GridItem>
      {/* <EditIcon
        style={{
          border: '3px solid #8B193D',
          borderRadius: '50%',
          width: '8vw',
          height: '5vh',
          margin: '-20px 0 0 -20px',
        }}
      /> */}
    </Hidden>
  </GridContainer>
);

ProfileViewPic.propTypes = {
  avatar: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(ProfileViewPic);
