import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from "components/Card/Card.jsx";

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Opens on click from audio player on PitchesPage

const AcceptanceConfirmation = () => {
    return (
        <Card
            style={{
                backgroundColor: '#223380',
                borderRadius: '10px',
                color: '#fff',
                padding: '10px'
            }}
        >
            <h3><b>Previous Estimate</b></h3>
            <GridContainer>
                <GridItem xs={4}>
                    <AccountCircleIcon style={{ width: '30vw', height: '15vh', margin: '17px 0 0 0' }} />
                </GridItem>
                <GridItem xs={8}>
                    <h4><b>Jada Pinkett Smith</b></h4>
                    <p>Band: Wicked Wisdom</p>
                    <p>Song: Avalanche</p>
                    <button style={{ color: '#fff', backgroundColor: '#8f8f8f', borderRadius: '2px', border: 'none', height: '4vh', width: '94%' }}>VIEW FULL PROFILE</button>
                </GridItem>
            </GridContainer>
            <GridItem xs={12}>
                <button style={{ background: 'linear-gradient(94deg, rgba(73,210,117,1) 0%, rgba(64,228,79,1) 96%)', height: '4vh', width: '100%', border: 'none', borderRadius: '2px', margin: '10px 0 0 0', color: '#fff' }}>CONFIRM ARTIST</button>
            </GridItem>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px' }}>
                <button
                    style={{
                        width: '27vw',
                        margin: '20px 0 20px 0',
                        height: '60px',
                        border: '1px solid black',
                        fontSize: '15px',
                    }}
                    onClick={(e) => this.handleSubmit(e)}>
                    CALL
                </button>
                <button
                    style={{
                        width: '27vw',
                        margin: '20px 0 20px 0',
                        height: '60px',
                        border: '1px solid black',
                        fontSize: '15px',
                    }}
                    onClick={(e) => this.handleSubmit(e)}>
                    MESSAGE
                </button>
                <button
                    style={{
                        width: '27vw',
                        margin: '20px 0 20px 0',
                        height: '60px',
                        border: '1px solid black',
                        fontSize: '15px',
                    }}
                    onClick={(e) => this.handleSubmit(e)}>
                    EMAIL
                </button>
            </div>
        </Card>
    )
}

export default AcceptanceConfirmation;