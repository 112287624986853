export default [
  {
    label: 'Joining Musiclear',
    event: 'join',
    type: 'reward',
    tokens: 1000,
  },
  {
    label: 'Inviting a new member',
    event: 'invite',
    type: 'reward',
    tokens: 100,
  },
  {
    label: 'Adding a private connection',
    event: 'add-connection',
    type: 'reward',
    tokens: 5,
  },
  {
    label: 'Voting on a public thread',
    event: 'vote',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Following a public profile',
    event: 'follow',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Answering a bot training question',
    event: 'answer-bot',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Adding a new video to your private library',
    event: 'private-post',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Sharing a private video',
    event: 'share-post',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Commenting on a post',
    event: 'comment',
    type: 'reward',
    tokens: 2,
  },
  {
    label: 'Delete a private post',
    event: 'delete-post',
    type: 'fee',
    tokens: 50,
  },
  {
    label: 'Report a post',
    event: 'report-post',
    type: 'fee',
    tokens: 50,
  },
  {
    label: 'Hide a private post',
    event: 'hide-post',
    type: 'fee',
    tokens: 50,
  },
  {
    label: 'Remove a private connection',
    event: 'remove-connection',
    type: 'fee',
    tokens: 50,
  },
  {
    label: 'Post a response to a public board',
    event: 'board-response',
    type: 'fee',
    tokens: 250,
  },
  {
    label: 'Post a new question to a topic board ',
    event: 'board-question',
    type: 'fee',
    tokens: 1000,
  },
  {
    label: 'Querying the Lyricist',
    event: 'query-oracle',
    type: 'fee',
    tokens: 1,
  },
];
