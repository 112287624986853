import React from 'react';
import PropTypes from 'prop-types';

import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import UserVideoList from 'components/UserVideoList';
import Placeholder from 'components/Placeholder';

import Face from '@material-ui/icons/Face';
import Chat from '@material-ui/icons/Chat';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle.jsx';

export const UserVideos = ({ classes, data }) => (
  <CustomTabs
    headerColor="primary"
    tabs={[{ name: 'public', icon: Chat }, { name: 'private', icon: Face }].map(
      (tab) => {
        // filter by video type
        const list = data.filter((e) => e.type === tab.name);
        // sort by most recent
        list.sort((a, b) => b.createdAt - a.createdAt);
        return {
          tabIcon: tab.icon,
          tabName: tab.name,
          tabContent: !list.length ? (
            <Placeholder text={`No ${tab.name} videos uploaded yet`} />
          ) : (
            <UserVideoList data={list} classes={classes} />
          ),
        };
      }
    )}
  />
);

UserVideos.defaultProps = {
  data: [],
};

UserVideos.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(UserVideos);
