import * as firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(config);
const storage = firebase.storage();
firebase.firestore().settings({});
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const db = firebase.firestore();
const { FieldValue } = firebase.firestore;

export {
  db,
  firebase,
  googleAuthProvider,
  FieldValue,
  storage,
  firebase as default,
};
