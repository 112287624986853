export default {
  collection: {
    users: 'TEST-users',
    recordings: 'recordings',
    gptModels: 'gpt2-models',
  },
  subcollection: {
    comments: 'comments',
  },
};
