import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const FilteredTopicList = ({ list, onSelect }) => (
  <List
    dense
    component="nav"
    aria-labelledby="user-list"
    style={{
      position: 'absolute',
      backgroundColor: '#67898C',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0)',
      width: '80%',
      margin: '0 10px 0 45px',
      zIndex: '100',
    }}
  >
    {list.map((item, i) => (
      <ListItem key={`${item}_${i}`} onClick={() => onSelect(item)}>
        <ListItemText primary={item.topic} style={{ color: '#FFFFFF' }} />
      </ListItem>
    ))}
  </List>
);

FilteredTopicList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FilteredTopicList;
