import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from 'store/actions/profileActions';
import store from 'services/StoreService';

// core components
import Loader from 'components/Loader';
import userEvents from 'config/user_events';
import MyProfileView from './MyProfileView';
import UserProfileView from './UserProfileView';

const ProfileView = ({ profileId, user, updateUser }) => {
  const followUser = async (userToFollow) => {
    try {
      const createdAt = new Date().getTime();
      // add key to determine this user's access to your videos
      const userData = { ...userToFollow, access: 'public', createdAt };
      // add new use to follow into list
      const following = (user.following || []).concat(userData);
      // persist the augmented list
      await store.updateFollowedUsers(user.uid, following);
      // Credit user with token for adding a post
      const reward = userEvents.find((e) => e.event === 'follow');
      const events = (user.events || []).concat({ ...reward, createdAt });
      // Update db
      await store.updateUser(user.uid, { events });
      // Update state
      updateUser({ following, events });
    } catch (error) {
      console.log('Error >>', error);
    }
  };

  const unFollowUser = async (id) => {
    try {
      // Remove the user from the array
      const following = user.following.filter((e) => e.id !== id);
      // Persist in db
      await store.updateFollowedUsers(user.uid, following);
      // Update app state
      updateUser({ following });
    } catch (error) {
      console.log('Error >>', error);
    }
  };

  const isAlreadyFollowed = (id) => {
    const { following } = user;
    if (!following || !following.length) return false;
    return !!following.find((e) => e.id === id);
  };

  if (!user.uid) return <Loader />;

  if (profileId) {
    return (
      <UserProfileView
        uid={profileId}
        followUser={followUser}
        unFollowUser={unFollowUser}
        isAConnection={isAlreadyFollowed}
      />
    );
  }

  return (
    <MyProfileView
      user={user}
      followUser={followUser}
      updateUser={updateUser}
      unFollowUser={unFollowUser}
      isAlreadyFollowed={isAlreadyFollowed}
    />
  );
};

ProfileView.propTypes = {
  profileId: PropTypes.string,
  user: PropTypes.shape({
    uid: PropTypes.string,
    events: PropTypes.array,
    following: PropTypes.array,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const user = state.profile || {};
  const { profileId } = ownProps.match.params || {};
  return { profileId, user };
};

export default connect(
  mapStateToProps,
  { updateUser }
)(ProfileView);
