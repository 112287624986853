import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import VideocamTwoToneIcon from '@material-ui/icons/VideocamTwoTone';
import Hidden from '@material-ui/core/Hidden';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import store from 'services/StoreService';
import categoryTopics from 'config/categoryTopics';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';

// Images
import forest from 'assets/img/arcq/forest.png';

const TopicDetail = ({
  classes,
  match: {
    params: { topic },
  },
}) => {
  const [posts, setPosts] = useState([]);

  const entry = categoryTopics.find((e) => {
    return e.topics.find((k) => k.name === topic);
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const posts = await store.getRecordingsByTopic(topic);
    setPosts(posts);
  };

  return !entry ? (
    <div
      style={{
        color: 'white',
        fontWeight: 'bold',
        fontSize: '26px',
        textAlign: 'center',
        marginTop: '20px',
      }}
    >
      Page not found
    </div>
  ) : (
    <div style={{ padding: '0px 20px 0px 20px' }}>
      <GridContainer>
        <GridItem xs={12}>
          {/* Mobile View  */}
          <Hidden smUp implementation="css">
            <GridContainer justify="center">
              <GridItem
                xs={5}
                sm={5}
                md={5}
                lg={5}
                style={{
                  margin: '20px 2px 4px 1px',
                  padding: '20px',
                  borderRadius: '15px',
                }}
              >
                <img
                  style={{ width: '100%' }}
                  className={classes.cardImg}
                  src={entry.image}
                  alt="..."
                  data-holder
                />
              </GridItem>
              <GridItem
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{ margin: '25px 0px 0px 0px' }}
              >
                <h4
                  style={{
                    fontWeight: '400',
                    color: '#FFFFFF',
                    fontSize: '20px',
                    marginBottom: '-20px',
                  }}
                >
                  {topic}
                </h4>
                <h3
                  style={{
                    fontWeight: '600',
                    color: '#FFFFFF',
                    fontSize: '22px',
                  }}
                >
                  {entry.category}
                </h3>
                <h4
                  style={{
                    fontWeight: '600',
                    color: '#FFFFFF',
                    fontSize: '22px',
                  }}
                >
                  {' '}
                  {posts.length} video
                  {posts.length > 1 ? 's' : ''}
                </h4>
              </GridItem>
            </GridContainer>
          </Hidden>
          {/* Desktop View  */}
          <Hidden xsDown implementation="css">
            <GridContainer justify="center">
              <GridItem
                xs={5}
                sm={5}
                md={5}
                lg={5}
                style={{
                  margin: '20px 2px 4px 1px',
                  padding: '20px',
                  borderRadius: '15px',
                }}
              >
                <img
                  style={{ width: '100%' }}
                  className={classes.cardImg}
                  src={forest}
                  alt="..."
                  data-holder
                />
              </GridItem>
              <GridItem
                xs={4}
                sm={4}
                md={4}
                lg={4}
                style={{ margin: '50px 0px 0px 0px' }}
              >
                <h4
                  style={{
                    fontWeight: '400',
                    color: '#FFFFFF',
                    fontSize: '25px',
                    marginBottom: '-20px',
                  }}
                >
                  Environmentalism
                </h4>
                <h3
                  style={{
                    fontWeight: '600',
                    color: '#FFFFFF',
                    fontSize: '32px',
                  }}
                >
                  {entry.category}
                </h3>
                <h4
                  style={{
                    fontWeight: '600',
                    color: '#FFFFFF',
                    fontSize: '32px',
                  }}
                >
                  {' '}
                  {posts.length} video
                  {posts.length > 1 ? 's' : ''}
                </h4>
              </GridItem>
            </GridContainer>
          </Hidden>
        </GridItem>
      </GridContainer>

      {/* Pictures Column  */}
      <GridContainer style={{ width: '106%', margin: '10px 0px 60px -10px' }}>
        {!posts.length ? (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px auto',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            No posts uploaded for that topic yet
          </div>
        ) : (
          posts.map((e) => (
            <GridItem style={{ marginBottom: "10px" }} key={e.id} xs={4} sm={4} md={4} lg={4}>
              <Link
                to={{
                  pathname: `/view-post/${e.id}`,
                }}
              >
                <div
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    borderRadius: '5px',
                  }}
                >
                  <video
                    style={{
                      width: '192%',
                      margin: '0px 0px -6px -47%',
                      height: '120px',
                      borderRadius: '0px',
                    }}
                    className={classes.cardImg}
                    data-holder-rendered="true"
                  >
                    <source type="video/mp4" src={e.url} />
                  </video>
                </div>
              </Link>
            </GridItem>
          ))
        )}
      </GridContainer>

      {/* Record a response Button  */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ margin: '0px 20% 0px 20%' }}>
            <Link
              to={{
                pathname: '/record',
              }}
            >
              <button
                className={classes.eternal}
                style={{
                  width: '60%',
                  color: '#1C3E59',
                  bottom: '75px',
                  border: 'transparent',
                  padding: ' 12px 0px 15px 0px',
                  zIndex: '1200',
                  position: 'fixed',
                  fontSize: '16px',
                  animation: 'MoveUpDown 2s linear infinite',
                  background:
                    'linear-gradient(90deg, rgba(143,206,159,1) 0%, rgba(241,232,102,1) 100%)',
                  boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
                  fontWeight: '600',
                  borderRadius: '50px',
                }}
              >
                <span style={{ margin: '0px 0px 30px 0px' }}>Record a response</span>
                <VideocamTwoToneIcon style={{ margin: '0px 130px -7px -184px' }} />
              </button>
            </Link>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

TopicDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      topic: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withStyles(Style)(TopicDetail);
