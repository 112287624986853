import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import { push } from 'connected-react-router'

export const updateUser = (data) => {
  return {
    type: 'UPDATE_USER_DATA',
    data,
  };
};

export const logout = () => ({
  type: 'LOG_OUT',
});

// _TODO: remove
// export const startSetUser = () => null;

// Get User
export const startSetUser = (userObj, user) => {
  return (dispatch) => {
    dispatch(setUser(userObj))
  }
}

export const setUser = (userObj) => ({
  type: 'SET_USER',
  userObj
})

// export const updateUser = (userObj, uid, userType) => {
//   return (dispatch) => {

//     console.log(userObj)
//     db.collection(userType).doc(uid).set({
//       ...userObj,
//       updatedAt: new Date()
//     }, { merge: true }).then(() => {
//       db.collection("users").doc(uid).set({
//         ...userObj,
//         updatedAt: new Date()
//       }, { merge: true }).then(() => {
//         if (userObj.legalDisclaimer) {
//           dispatch(push('/cases'))
//           window.location.reload()
//         } else {
//           window.location.reload()
//         }


//       }).catch((err) => {
//         return (`Update TEST-users with id: ${uid} with error: ${err}`)
//       })
//     }).catch((err) => {
//       return (`Update TEST-users with id: ${uid} with error: ${err}`)
//     })
//   }

// }

export const createUser = (userObj, uid, userType) => {
  return (dispatch) => {

    console.log(userType)
    db.collection(userType).doc(uid).set({
      ...userObj,
      updatedAt: new Date()
    }, { merge: true }).then(() => {
      db.collection("users").doc(uid).set({
        ...userObj,
        updatedAt: new Date()
      }, { merge: true }).then(() => {
        // window.location.reload() 
        // Check to see what should be home page, currently set to profile
        if (userType == "agent") {
          dispatch(push('/profile'))
        } else if (userType == "artist") {
          dispatch(push('/profile'))
        }
      }).catch((err) => {
        return (`Update TEST-users with id: ${uid} with error: ${err}`)
      })
    }).catch((err) => {
      return (`Update TEST-users with id: ${uid} with error: ${err}`)
    })
  }
}
