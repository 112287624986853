import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import Style from 'assets/jss/material-dashboard-pro-react/views/discoveryStyle.jsx';

//Sections
import CarouselDesktop from './sections/SectionCarouselDesktop.jsx';
import CarouselMobile from './sections/SectionCarouselMobile.jsx';

// Images
import forest from 'assets/img/arcq/forest.png';
import gameboy from 'assets/img/arcq/gameboy.png';
import lightTunnel from 'assets/img/arcq/lightTunnel.png';
import Environmentalism from 'assets/img/arcq/Environmentalism.jpg';
import FamilyReunion from 'assets/img/arcq/FamilyReunion.jpg';
import Parties from 'assets/img/arcq/Parties.jpg';
import Graduation from 'assets/img/arcq/Graduation.jpg';
import Timesless from 'assets/img/arcq/Timesless.jpg';

class Discovery extends React.Component {
  render() {
    const { classes } = this.props;

    const TrendingImages = [forest, gameboy, lightTunnel];

    return (
      <div style={{ padding: '0px 20px 0px 20px' }}>
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer style={{ margin: '20px 0px 10px 0px' }}>
              <GridItem xs={6}>
                <h3
                  style={{ margin: '0px 10px 0px -5px', fontSize: '22px' }}
                  className={classes.title}
                >
                  Active Topics
                </h3>
              </GridItem>
              <GridItem xs={4}>
                <Link
                  to={{
                    pathname: '/topics',
                  }}
                >
                  <h3
                    style={{
                      margin: '7px 5px 0px 0px',
                      fontWeight: '300',
                      background: 'E8E8E8',
                      fontSize: '15px',
                      textAlign: 'right',
                    }}
                    className={classes.title}
                  >
                    See more
                  </h3>
                </Link>
              </GridItem>
              <GridItem xs={2}>
                <Link
                  to={{
                    pathname: '/topics',
                  }}
                >
                  <ArrowForwardIosRoundedIcon
                    style={{ color: '#ffff', margin: '5px -10px 0px 0px' }}
                  />
                </Link>
              </GridItem>
            </GridContainer>
            {/* Carousel */}
            <Hidden xsDown implementation="css">
              <div style={{ margin: '0px 0px 20px 0px', borderRadius: '20px' }}>
                <CarouselDesktop />
              </div>
            </Hidden>
            <Hidden smUp implementation="css">
              <div style={{ margin: '0px 0px 20px 0px', borderRadius: '20px' }}>
                <CarouselMobile />
              </div>
            </Hidden>
          </GridItem>
        </GridContainer>
        {/* trending video and see more icons */}
        <GridContainer style={{ margin: '20px 0px 10px 0px' }}>
          <GridItem xs={6}>
            <h3
              style={{ margin: '10px 10px 10px -15px', fontSize: '22px' }}
              className={classes.title}
            >
              Trending Videos
            </h3>
          </GridItem>

          <GridItem xs={4}>
            <Link
              to={{
                pathname: '/trending',
              }}
            >
              <h3
                style={{
                  margin: '15px 5px 0px 0px',
                  fontWeight: '300',
                  background: 'E8E8E8',
                  fontSize: '15px',
                  textAlign: 'right',
                }}
                className={classes.title}
              >
                See more
              </h3>
            </Link>
          </GridItem>
          <GridItem xs={2}>
            <Link
              to={{
                pathname: '/trending',
              }}
            >
              <ArrowForwardIosRoundedIcon
                style={{ color: '#ffff', margin: '12px -10px 0px 0px' }}
              />
            </Link>
          </GridItem>
        </GridContainer>

        <GridContainer
          justify="center"
          spacing={8}
          style={{ margin: '-26px 0px 0px -16px', paddingTop: '10px' }}
        >
          {TrendingImages.map((image, index) => (
            <GridItem xs={4} key={index}>
              <Link
                to={{
                  pathname: '/view-post',
                }}
              >
                <Card className={classes.textWhite}>
                  <div className={classes.cardImgOverlay}>
                    <h4
                      style={{ marginTop: '0px', fontSize: '14px' }}
                      className={classes.bodyText}
                    >
                      <strong>What is the meaning of life?</strong>
                    </h4>
                  </div>
                  <div className={classes.imgDarken}>
                    <img
                      className={classes.cardImg}
                      src={image}
                      alt="..."
                      data-holder-rendered="true"
                    />
                  </div>
                </Card>
              </Link>
            </GridItem>
          ))}
        </GridContainer>
        {/* Popular and view all Icon  */}
        <GridContainer style={{ margin: '-10px 0px -6px 0px' }}>
          <GridItem xs={6}>
            <h3
              style={{ margin: '10px 10px 10px -15px', fontSize: '22px' }}
              className={classes.title}
            >
              Categories
            </h3>
          </GridItem>
          <GridItem xs={4}>
            <Link
              to={{
                pathname: '/topics',
              }}
            >
              <h3
                style={{
                  margin: '17px 5px 0px 0px',
                  fontWeight: '300',
                  background: 'E8E8E8',
                  fontSize: '15px',
                  textAlign: 'right',
                }}
                className={classes.title}
              >
                See more
              </h3>
            </Link>
          </GridItem>
          <GridItem xs={2}>
            <Link
              to={{
                pathname: '/category',
              }}
            >
              <ArrowForwardIosRoundedIcon
                style={{ color: '#ffff', margin: '15px -10px 0px 0px' }}
              />
            </Link>
          </GridItem>
        </GridContainer>
        {/* Family Reunion and Environmentalism Pics  */}
        <GridContainer style={{ margin: '-10px 0px 0px -14px' }}>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Environmentalism</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={Environmentalism}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Family Reunion</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={FamilyReunion}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
        {/* Graduation and Parties */}
        <GridContainer style={{ margin: '-20px 0px 0px -14px' }}>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Graduation</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={Graduation}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Parties</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={Parties}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
        {/* Timesless and Family Hertiage  */}
        <GridContainer style={{ margin: '-20px 0px 70px -14px' }}>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Timeless</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={Timesless}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={6}>
            <Link
              to={{
                pathname: '/topic-detail',
              }}
            >
              <Card className={classes.textWhite}>
                <div className={classes.cardImgOverlay}>
                  <h4
                    style={{ marginTop: '0px', fontSize: '18px' }}
                    className={classes.bodyText}
                  >
                    <strong>Beliefs</strong>
                  </h4>
                </div>
                <div className={classes.imgDarken}>
                  <img
                    className={classes.cardImg}
                    src={Environmentalism}
                    alt="..."
                    data-holder-rendered="true"
                  />
                </div>
              </Card>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Discovery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(Discovery);
