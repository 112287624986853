import React from "react";
import { Link } from "react-router-dom";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import carouselStyle from 'assets/jss/material-dashboard-pro-react/views/componentsSections/carouselStyle.jsx';

class SectionCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
    };

    return (
      <Carousel {...settings} style={{ margin: '20px 0 50px 20px' }}>
        <div>
          <center
            style={{
              color: 'black',
              backgroundColor: '#7E7E7E',
              height: '35px',
              width: '35vw',
              padding: '8px 0 0 0',
            }}
          >
            <strong>Glass Animals</strong>
          </center>
        </div>
        <div>
          <center
            style={{
              color: 'black',
              backgroundColor: '#6dbe48',
              height: '35px',
              width: '35vw',
              padding: '8px 0 0 0',
            }}
          >
            <strong>Wicked Wisdom</strong>
          </center>
        </div>
        <div>
          <center
            style={{
              color: 'black',
              backgroundColor: '#7E7E7E',
              height: '35px',
              width: '35vw',
              padding: '8px 0 0 0',
            }}
          >
            Khemmis
          </center>
        </div>
      </Carousel>
    );
  }
}

export default withStyles(carouselStyle)(SectionCarousel);