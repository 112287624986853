import React from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from "components/Card/Card.jsx";
import Table from "components/Table/Table.jsx";

// Opens on click from table on FinancingPage

const PreviousLyrics = () => {
    return (
        <Card
            style={{
                backgroundColor: '#223380',
                borderRadius: '10px',
                color: '#fff',
                padding: '20px'
            }}
        >
            <h3><b>Previous Lyrics</b></h3>
            <Table
                tableHead={['Date', 'Genre', 'Action']}
                tableData={[
                    ['03/01/20', 'R&B Hip Hop', 'Delete'],
                    ['07/22/20', 'Heavy Metal', 'View'],
                    ['09/09/20', 'Heavy Metal', 'View'],

                ]}
            />
        </Card>
    )
}

export default PreviousLyrics;