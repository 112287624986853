const initState = {
    pending: false,
    services: {}
};

function filterSelected(services) {
    let toggledServices = {};
    for (let [serviceName,service] of Object.entries(services)) {
        let toggledTracks = Object.entries(service.tracks).filter(([_, track])=>track.selected);
        if (toggledTracks.length > 0) {
            let tracks = {};
            toggledTracks.forEach(([trackId,track])=>{ tracks[trackId] = track })
            toggledServices[serviceName] = {
                ...services[serviceName],
                tracks
            }
        }
    }
    return toggledServices;
}

function mergeResult(services, searchResult) {
    let merged = filterSelected(services);
    for (let searchItem of searchResult) {
        let service = merged[searchItem.service] || { tracks: {} };
        let track = service.tracks[searchItem.id] || {};
        service.tracks[searchItem.id] = { 
            ...track, 
            isrc: searchItem.isrc,
            name: searchItem.name,
            artists: searchItem.artists,
            img: searchItem.img
        }
        merged[searchItem.service] = service;
    }
    return merged;
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'TRACK_SELECTOR_SET_SEARCH_PENDING':
            return {
                ...state,
                pending: true
            }
        case 'TRACK_SELECTOR_UPDATE_SEARCH_RESULT':
            return {
                ...state,
                services: mergeResult(state.services, action.data),
                pending: false
            }
        case 'TRACK_SELECTOR_TOGGLE_TRACK':
            let service = state.services[action.service] || { tracks: {} };
            let track = service.tracks[action.trackId] || {};
            return {
                ...state,
                services: { 
                    ...state.services, 
                    [action.service] : { 
                        ...service, 
                        tracks: { 
                            ...service.tracks, 
                            [action.trackId] : { 
                                ...track, 
                                selected: !track.selected
                            } 
                        } 
                    } 
                }
            }
        case 'TRACK_SELECTOR_CLEAR':
            return {
                ...state,
                services: {}
            }
        default:
            return state;
    }
}