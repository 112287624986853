import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import { updateUser } from 'store/actions/profileActions';
import logo from 'assets/img/logos/musiclear-logo.png';
import loginImage from 'assets/img/login/admin-login-image.png';
import store from 'services/StoreService';
import userEvents from 'config/user_events';
import authService from 'services/AuthService';
import SectionCarousel from './Sections/SectionCarousel.jsx';

// actions
import { emailSignIn, passwordReset } from '../../store/actions/authActions';

import Close from '@material-ui/icons/Close';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

// Will eventually change to AdminLoginPage

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      passwordNotice: null,
      updateSignUpModal: false,
      updateLogInModal: false,
      authInfo: {
        email: '',
        password: '',
      },
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmitSignUp = (e) => {
    e.preventDefault();

    if (this.state.authInfo.password !== this.state.authInfo.confirmPassword) {
      this.setState({
        passError: true,
      });
      return;
    }

    if (this.state.checked[0] !== 'accepted terms') {
      this.setState({
        termsError: true,
      });
      return;
    }

    this.setState({
      signupError: false,
    });

    this.props.emailSignup(this.state.authInfo);
  };

  handleSubmitLogIn = (e) => {
    e.preventDefault();
    this.props.emailSignIn(this.state.authInfo);
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value,
      },
    });
  };

  handlePasswordReset = (e) => {
    e.preventDefault();
    passwordReset(this.state.authInfo.email);
    this.setState({
      ...this.state,
      passwordNotice: 'Password reset link sent. Please check your email.',
    });
  };

  googleSignin = async () => {
    // Sign in on the server
    const { user } = await authService.googleSignin();
    // Check whether has already been registered before
    const userData = await store.getUserData(user.uid);
    // If already registered just update the app state and exit
    // if (userData) return this.props.updateUser(data);
    // Save data to db
    const createdAt = new Date().getTime();
    // Get this event spec
    const event = userEvents.find((e) => e.event === 'join');
    // The events field to save to db
    const events = [{ ...event, createdAt }];
    // The info the user input
    const { uid, email, displayName, photoURL: avatar } = user;
    // The user data to save to db
    const data = { uid, email, displayName, avatar, events };
    // Create user in db
    await store.addUser(uid, data);
    this.props.updateUser(data);
  };

  render() {
    const { authError, classes } = this.props;
    const { passwordNotice } = this.state;

    return (
      <div className={classes.container} style={{height: '50vh', width: '100%'}}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
            <div style={{ width: '20rem', margin: '0 auto' }}>
              <img src={logo} style={{ margin: '-100px 0px -30px 15%', width: '70%' }} alt="logo" className={classes.img} />
              <img src={loginImage} style={{ margin: '0px 0px -30px 0px' }} alt="login-image" className={classes.login} />
              <p className={classes.subdescription} />
              {/* <div style={{ margin: '0 0 30px 0'}}>
                <SectionCarousel />
              </div> */}
              <br/>
              <Button
                justify="center"
                style={{
                  backgroundColor: '#f74f66',
                  boxShadow: 'none',
                  borderRadius: '50px',
                  color: '#fff',
                  width: '101.5%',
                  padding: '15px',
                  fontSize: '18px',
                }}
                onClick={() => this.handleClickOpen('updateLogInModal')}
              >
                <b>Log In</b>
              </Button>
                <br />
              <Link to="/pages/signup-page">
                <Button
                  justify="center"
                  style={{
                    backgroundColor: '#7bb85f',
                    boxShadow: 'none',
                    borderRadius: '50px',
                    color: '#fff',
                    width: '101.5%',
                    padding: '15px',
                    fontSize: '18px',
                  }}
                >
                  <b>Sign Up</b>
                </Button>
              </Link>
              {/* <Button
                style={{
                  boxShadow: 'none',
                  backgroundSize: 'cover',
                  width: '100%',
                  height: '4.4rem',
                }}
                onClick={this.googleSignin}
                className={classes.customButtonClass}
              >
                <img
                  width="320px"
                  src="https://firebasestorage.googleapis.com/v0/b/golegalese.appspot.com/o/googlelogin.png?alt=media&token=94c33cb1-6957-4b4c-bcb3-ced5f8b4af22"
                />
              </Button> */}
            </div>

            {/* UPDATE LOG IN MODAL */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + ' ' + classes.modalSmall,
              }}
              open={this.state.updateLogInModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.handleClose('updateLogInModal')}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
                style={{ padding: '0px' }}
              >
                {/* FORM */}
                <form onSubmit={this.handleSubmitLogIn}>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardBody>
                      <Button
                        style={{ float: 'right', margin: '-10px -20px' }}
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose('updateLogInModal')}
                      >
                        {' '}
                        <Close className={classes.modalClose} />
                      </Button>
                      <p className={classes.textCenter}>
                        <span style={{ color: 'white' }}>New? </span>
                        <Link to="/pages/signup-page" className={classes.linkText}>
                          Create an account now.
                        </Link>
                      </p>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          type: 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon>lock_outline</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {authError ? (
                        <div className={classes.justifyContentCenter}>
                          <p className={classes.textDanger}>
                            Error: {authError.message}
                            <br />
                            Forgot your password?
                          </p>
                          <Button
                            type="button"
                            onClick={this.handlePasswordReset}
                            color="danger"
                            simple
                            size="lg"
                            block
                          >
                            Click Here
                          </Button>
                          <p className={classes.textDanger}>
                            to send a password reset email.
                          </p>
                        </div>
                      ) : null}
                      {passwordNotice ? (
                        <p className={classes.textDanger}>{passwordNotice}</p>
                      ) : null}
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button
                        type="submit"
                        color="primary"
                        style={{ boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)' }}
                      >
                        Let's Go
                      </Button>
                    </CardFooter>
                  </Card>
                </form>
                {/* <Button
                  className={classes.ltrButtonWhite}
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px"
                  }}
                  key="close"
                  aria-label="Close"
                  onClick={() => this.handleClose("updateLogInModal")}
                >
                  CLOSE
                            </Button> */}
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
  passwordReset: (email) => dispatch(passwordReset(email)),
  updateUser: (data) => dispatch(updateUser(data)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(loginPageStyle)
)(LoginPage);
