import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import auth from 'services/AuthService';
import { logout } from 'store/actions/profileActions';

// @material-ui/icons
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import AccessibilityRoundedIcon from '@material-ui/icons/AccessibilityRounded';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';


// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class Settings extends React.Component {
  logout = async () => {
    await auth.logout();
    this.props.logout({});
  };

  render() {
    const { uid } = this.props;

    return uid ? (
      <Card
        style={{
          width: '117%',
          height: '120vh',
          margin: '-25% 0px 0px -5%',
          padding: '25% 4% 15% 4%',
          backgroundColor:
            'linear-gradient(0deg, rgba(43,46,53,1) 0%, rgba(27,61,89,1) 100%)',
        }}
      >
        <CardBody>
          {/* Account Icons  */}
          <GridContainer>
            <GridContainer style={{ margin: '0px 0px 0px 0px' }}>
              <GridItem xs={12}>
                <h3
                  style={{
                    margin: '0px 0px 15px 0px',
                    fontFamily: 'Raleway',
                    fontSize: '22px',
                    color: '#86C59C',
                    fontWeight: '600',
                  }}
                >
                  Account
                </h3>
              </GridItem>
            </GridContainer>
            {/* Icons for Account */}
            <GridContainer style={{}}>
              <GridItem xs={2}>
                <PersonOutlineIcon
                  style={{ margin: '0px 0px 0px 15px', color: '#E6E6E6' }}
                />
                <SettingsOutlinedIcon
                  style={{ margin: '0px 0px 0px 15px', color: '#E6E6E6' }}
                />
                <AccountBalanceWalletOutlinedIcon
                  style={{
                    margin: '0px 0px -32px -25px',
                    color: '#E6E6E6',
                  }}
                />
              </GridItem>
              <GridItem xs={7}>
              <Link 
              to={{ pathname: `/profile/`,
              state: { modalOpen: true }
               }}>                  
                <h4
                  style={{
                    margin: '0px 0px 0px -14px',
                    fontSize: '18px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Edit profile
                </h4>
                </Link>
                <a href="https://storage.googleapis.com/stateless-arcq/2020/05/ec106827-arcq-privacy-policy.docx">
                <h4
                  style={{
                    margin: '5px 0px -5px -14px',
                    fontSize: '18px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Privacy
                </h4>
                </a>
                <Link 
              to={{ pathname: `/wallet/` }}>
                <h4
                  style={{
                    margin: '13px 0px 0px -14px',
                    fontSize: '18px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Wallet
                </h4>
                </Link>
              </GridItem>
            </GridContainer>
            {/* General section and Icons */}
            <br />

            {/* <GridContainer style={{ margin: '0px 0px 0px 30px' }}>
              <GridItem xs={12}>
                <h4
                  style={{
                    margin: '10px 0px 10px -30px',
                    fontSize: '22px',
                    fontFamily: 'Raleway',
                    color: '#86C59C',
                    fontWeight: '600',
                  }}
                >
                  General
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer style={{}}>
              <GridItem xs={2}>
                <NotificationsActiveOutlinedIcon
                  style={{ margin: '0px 0px 0px 15px', color: '#E6E6E6' }}
                />
                <TranslateOutlinedIcon
                  style={{ margin: '0px 0px 0px 15px', color: '#E6E6E6' }}
                />
                <AccessibilityRoundedIcon
                  style={{
                    margin: '0px 0px -32px -25px',
                    color: '#E6E6E6',
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
                <h1
                  style={{
                    margin: '4px -6px 0px -15px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Push Notification
                </h1>
                <h4
                  style={{
                    margin: '5px -14px 0px -13px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Langauage
                </h4>
                <h4
                  style={{
                    margin: '5px -14px 0px -13px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Accessibility
                </h4>
              </GridItem>
            </GridContainer> */}

            {/* Support Icons */}
            <GridContainer
              style={{
                margin: '10px 0px 0px 0px',
                padding: '0 0px !important',
              }}
            >
              <GridItem xs={12}>
                <h4
                  style={{
                    margin: '10px 0px 10px 0px',
                    fontSize: '22px',
                    fontFamily: 'Raleway',
                    color: '#86C59C',
                    fontWeight: '600',
                  }}
                >
                  Support
                </h4>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer style={{}}>
              <GridItem xs={2}>
                <BorderColorOutlinedIcon
                  style={{ margin: '5px 0px 0px 15px', color: '#E6E6E6' }}
                />
                <HelpOutlineIcon
                  style={{
                    margin: '0px 0px -32px -25px',
                    color: '#E6E6E6',
                  }}
                />
              </GridItem>
              <GridItem xs={8}>
              <a href="https://www.arcq.app/contact/">
                <h4
                  style={{
                    margin: '5px -14px 0px -13px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Report a Problem
                </h4>
                </a>
                <a href="https://www.arcq.app/faq/">
                  <h4
                    style={{
                      margin: '5px -14px 0px -13px',
                      fontSize: '17px',
                      fontFamily: 'Raleway',
                      color: '#FEFEFE',
                      fontWeight: '300',
                    }}
                  >
                    Help Center
                  </h4>
                </a>
              </GridItem>
            </GridContainer>
            <br />
            {/* About */}

            <GridContainer>
              <GridItem xs={12}>
                <h4
                  style={{
                    margin: '20px 0px 10px 15px',
                    fontSize: '22px',
                    fontFamily: 'Raleway',
                    color: '#86C59C',
                    fontWeight: '600',
                  }}
                >
                  About
                </h4>
              </GridItem>
            </GridContainer>
            {/* Icons for about section  */}
            <GridContainer style={{}}>
              <GridItem xs={2}>
                <TurnedInNotIcon
                  style={{ margin: '5px 0px 0px 20px', color: '#E6E6E6' }}
                />
              </GridItem>
              <GridItem xs={8}>
              <a href="https://storage.googleapis.com/stateless-arcq/2020/05/c85fb4f2-arcq-terms.docx">
                <h4
                  style={{
                    margin: '4px -6px 0px -15px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Term of Use
                </h4>
                </a>
{/*                 
                <h4
                  style={{
                    margin: '4px -6px 0px -15px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Community Guidelines
                </h4> */}
                <h4
                  style={{
                    margin: '4px -6px 0px -15px',
                    fontSize: '17px',
                    fontFamily: 'Raleway',
                    color: '#FEFEFE',
                    fontWeight: '300',
                  }}
                >
                  Privacy Policy
                </h4>
              </GridItem>
            </GridContainer>
            {/* Logout section  */}
            <GridContainer
              style={{
                margin: '10px 0px 0px 0px',
                padding: '0 0px !important',
              }}
            >
              <GridItem xs={2}>
                <ExitToAppOutlinedIcon
                  style={{ margin: '0px 0px 0px 5px', color: '#E6E6E6' }}
                />
              </GridItem>
              <GridItem xs={8}>
                <span>
                  <h1
                    onClick={this.logout}
                    style={{
                      margin: '4px 0px 0px -22px',
                      fontSize: '17px',
                      fontFamily: 'Raleway',
                      color: '#FEFEFE',
                      fontWeight: '300',
                    }}
                  >
                    Log Out
                  </h1>
                </span>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </CardBody>
      </Card>
    ) : (
      <p> </p>
    );
  }
}

Settings.propTypes = {
  uid: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { uid } = state.profile || {};
  return { uid };
};

export default connect(
  mapStateToProps,
  { logout }
)(Settings);
