import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FeedListItem from 'components/FeedListItem';
import Loader from 'components/Loader';
import store from 'services/StoreService';
import Style from 'assets/jss/material-dashboard-pro-react/views/arcqStyle.jsx';

//Sections
import CarouselDesktop from '../Topics/sections/SectionCarouselDesktop.jsx';
import CarouselMobile from '../Topics/sections/SectionCarouselMobile.jsx';

const Feed = ({ classes, uid, following }) => {
  const [posts, setPosts] = useState([]);

  useEffect(
    () => {
      getPosts();
    },
    [following]
  );

  const getPosts = async () => {
    // Get all user's following ids
    const ids = (following || []).map((e) => e.id);
    if (!ids.length) return;
    const posts = await store.getUsersRecordingsByType(ids, 'public');
    setPosts(posts);
  };

  return uid ? (
    <div>
      <h3
        className={classes.title}
        style={{ marginLeft: '10px', paddingTop: '20px' }}
      >
        Trending On ARCQ
      </h3>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <GridContainer style={{ margin: '0px 0px 20px 0px' }}>
            <GridItem xs={12}>
              <Hidden xsDown implementation="css">
                <div
                  style={{
                    padding: '0px 40px 0px 10px',
                    margin: '0px 0px 20px 0px',
                    borderRadius: '20px',
                  }}
                >
                  <CarouselDesktop />
                </div>
              </Hidden>
              <Hidden smUp implementation="css">
                <div
                  style={{
                    padding: '0px 35px 0px 10px',
                    margin: '0px 0px 20px 0px',
                    borderRadius: '20px',
                  }}
                >
                  <CarouselMobile />
                </div>
              </Hidden>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ width: '98%', margin: '0px 5px 70px 5px' }}>
        {!posts.length ? (
          <div
            style={{
              color: 'white',
              width: '100%',
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: 'bold',
              paddingTop: '20px',
            }}
          >
            Nothing in your feed yet
          </div>
        ) : (
          <div>
            <h3 style={{ marginLeft: '10px' }} className={classes.title}>
              Your Feed
            </h3>
            {/* Desktop */}
            <Hidden xsDown implementation="css">
              <div style={{ display: 'flex', justifyContent: 'spaceAround' }}>
                {posts.map((data, index) => (
                  <GridItem
                    // xs={12}
                    // sm={10}
                    // md={4}
                    // lg={3}
                    style={{ padding: '30px' }}
                    key={index}
                  >
                    <GridItem xs={12}>
                      <FeedListItem data={data} />
                    </GridItem>
                  </GridItem>
                ))}
              </div>
            </Hidden>
            {/* Mobile */}
            <Hidden smUp implementation="css">
              {posts.map((data, index) => (
                <GridItem
                  // xs={12}
                  // sm={10}
                  // md={4}
                  // lg={3}
                  style={{ padding: '30px' }}
                  key={index}
                >
                  <GridItem xs={12}>
                    <FeedListItem data={data} />
                  </GridItem>
                </GridItem>
              ))}
            </Hidden>
          </div>
        )}
      </GridContainer>
    </div>
  ) : (
    <Loader />
  );
};

Feed.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  following: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ),
};

const mapStateToProps = (state) => {
  const { uid, following } = state.profile || {};
  return { uid, following };
};

export default compose(
  connect(mapStateToProps),
  withStyles(Style)
)(Feed);
