import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import ChartistGraph from 'react-chartist';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Timeline from '@material-ui/icons/Timeline';
import InputAdornment from '@material-ui/core/InputAdornment';
import People from '@material-ui/icons/People';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Loader from 'assets/img/preloader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';
import { Grid } from '@material-ui/core';

// images
import Token from 'assets/img/arcq/arq-token.png';
import marc from 'assets/img/faces/marc.jpg';
import avatar from 'assets/img/faces/avatar.jpg';

import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from 'variables/charts.jsx';

// Forms
import AssignActionForm from 'components/Forms/AssignActionForm.jsx';

// Firebase Redux Integration
import { startSetPendingActionsList } from 'store/actions/actionsActions';
import {
  tableToFirestoreObjects,
  updateFirestoreWithTableData,
  addFieldToDocument,
} from 'store/actions/firestoreActions';
import { startSetUsersList } from 'store/actions/adminActions';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class TokenRedeem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color='primary'
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });

    this.setState({
      usersData,
      dataLoaded: true,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const { AuthBool, classes } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded && !this.state.dataLoaded) {
        this.loadData();
      }
    }

    return (
      <div>
        {this.state.dataLoaded ? (
          <div>
            <GridContainer style={{ padding: '0px 20px' }}>
              <Card style={{ backgroundColor: '#6ABC43' }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={3} sm={3} md={3}>
                      <img
                        src={Token}
                        style={{
                          width: '100%',
                          padding: '12px',
                          margin: '-10px 0px 0px 0px',
                        }}
                        alt='ARCQ Token'
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <h4
                        style={{
                          color: 'white',
                          fontSize: '22px',
                          fontWeight: '600',
                          display: 'inline-block',
                          marginRight: '5px',
                        }}
                        className={classes.cardTitle}
                      >
                        12,500{' '}
                      </h4>
                      <span
                        style={{
                          display: 'inline-block',
                          color: 'white',
                          fontWeight: '600',
                          fontSize: '18px',
                        }}
                      >
                        {' '}
                        ARCQ
                      </span>
                      <h6
                        style={{ fontSize: '16px', fontWeight: '400' }}
                        className={classes.cardSubtitle}
                      >
                        $12.50
                      </h6>
                      {/* <p style={{color: "white", fontWeight: "500"}}>
                        Send Bitcoin To A Wallet Address
                        </p> */}
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <Button
                        style={{
                          width: '40px',
                          height: '40px',
                          margin: '-3px 5px 0px 0px',
                          padding: '9px 0px 8px 3px',
                          float: 'right',
                          boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.5)',
                          background:
                            'linear-gradient(33deg, rgba(148,207,155,1) 0%, rgba(244,232,101,1) 100%)',
                        }}
                      >
                        <AddIcon style={{ fontSize: '25px' }} />
                      </Button>
                      <br />
                      <br />
                      <p
                        style={{
                          marginRight: '5px',
                          float: 'right',
                          fontSize: '12px',
                          color: 'white',
                        }}
                      >
                        Add ARCQ
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer style={{ padding: '0px 2px' }}>
                    <GridItem xs={9} sm={9} md={9}>
                      <div style={{ padding: '0px 13px !important' }}>
                        <CustomInput
                          labelText='Send Bitcoin To A Wallet Address'
                          id='material'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}>
                      <Button
                        style={{
                          margin: '15px 0px 0px -8px',
                          padding: '13px 18px',
                          fontSize: '14px',
                          backgroundColor: '#1B3D59',
                          textTransform: 'capitalize',
                        }}
                      >
                        Redeem
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridContainer>
            <GridContainer style={{ padding: '0px 20px' }}>
              {/*<GridItem xs={12} sm={12} md={4}>
                          <Card chart>
                            <CardHeader color="rose">
                              <ChartistGraph
                                className="ct-chart-white-colors"
                                data={roundedLineChart.data}
                                type="Line"
                                options={roundedLineChart.options}
                                listener={roundedLineChart.animation}
                              />
                            </CardHeader>
                            <CardBody>
                              <h4 className={classes.cardTitle}>Rounded Line Chart</h4>
                              <p className={classes.cardCategory}>Line Chart</p>
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Card chart>
                            <CardHeader color="warning">
                              <ChartistGraph
                                className="ct-chart-white-colors"
                                data={straightLinesChart.data}
                                type="Line"
                                options={straightLinesChart.options}
                                listener={straightLinesChart.animation}
                              />
                            </CardHeader>
                            <CardBody>
                              <h4 className={classes.cardTitle}>Straight Lines Chart</h4>
                              <p className={classes.cardCategory}>Line Chart with Points</p>
                            </CardBody>
                          </Card>
                </GridItem>*/}
              <GridItem xs={12} sm={12} md={12}>
                <Card
                  style={{ marginTop: '20px', backgroundColor: '#6ABC43' }}
                  chart
                >
                  <CardHeader color='info'>
                    <ChartistGraph
                      className='ct-chart-white-colors'
                      data={simpleBarChart.data}
                      type='Bar'
                      options={simpleBarChart.options}
                      responsiveOptions={simpleBarChart.responsiveOptions}
                      listener={simpleBarChart.animation}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4
                      style={{ color: 'white' }}
                      className={classes.cardTitle}
                    >
                      Monthly Balance History
                    </h4>
                    <p
                      style={{ color: 'white' }}
                      className={classes.cardCategory}
                    >
                      This ARCQ Wallet Chart shows the points you've used and
                      earned each month.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: '-20px', padding: '0px 20px' }}>
              <GridItem xs={4} sm={4} md={4}>
                <Card style={{ backgroundColor: '#6ABC43', padding: '5%' }}>
                  <CardHeader color='rose' icon>
                    {/*<CardIcon color="rose">
                    <Timeline />
                    </CardIcon>*/}
                    <h4
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                      className={classes.cardTitle}
                    >
                      <b>15,500</b>
                    </h4>
                    <p
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      <small>Earned</small>
                    </p>
                  </CardHeader>
                  {/* <CardBody>
                  <ChartistGraph
                    data={multipleBarsChart.data}
                    type="Bar"
                    options={multipleBarsChart.options}
                    listener={multipleBarsChart.animation}
                  />
                </CardBody>*/}
                </Card>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <Card style={{ backgroundColor: '#FAEA61', padding: '5%' }}>
                  <CardHeader color='info' icon>
                    {/*<CardIcon color="info">
                    <Timeline />
    </CardIcon>*/}
                    <h4
                      style={{
                        color: '#2B2E34',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                      className={classes.cardTitle}
                    >
                      <b>81.5%</b>
                    </h4>
                    <p
                      style={{
                        color: '#2B2E34',
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      <small>AI Trained</small>
                    </p>
                  </CardHeader>
                  {/* <CardBody>
                  <ChartistGraph
                    data={colouredLineChart.data}
                    type="Line"
                    options={colouredLineChart.options}
                    listener={colouredLineChart.animation}
                  />
                </CardBody>*/}
                </Card>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <Card style={{ backgroundColor: '#6ABC43', padding: '5%' }}>
                  <CardHeader color='rose' icon>
                    {/*<CardIcon color="rose">
                  <Timeline />
                </CardIcon>*/}
                    <h4
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                      className={classes.cardTitle}
                    >
                      <b>3,000</b>
                    </h4>
                    <p
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      <small>Spent</small>
                    </p>
                  </CardHeader>
                  {/*  <CardBody>
                <ChartistGraph
                  data={multipleBarsChart.data}
                  type="Bar"
                  options={multipleBarsChart.options}
                  listener={multipleBarsChart.animation}
                />
              </CardBody>*/}
                </Card>
              </GridItem>
            </GridContainer>
            {/* <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <CardHeader color="warning" icon>
                    <CardIcon color="warning">
                    <Timeline />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    Individual Case Metrics
                  </h4>
                </CardHeader>
                <CardBody>
                  <ChartistGraph
                    data={colouredLinesChart.data}
                    type="Line"
                    options={colouredLinesChart.options}
                    listener={colouredLinesChart.animation}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <CardHeader color="danger" icon>
                  <CardIcon color="danger">
                    <Timeline />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Pie Chart</h4>
                </CardHeader>
                <CardBody>
                  <ChartistGraph
                    data={pieChart.data}
                    type="Pie"
                    options={pieChart.options}
                  />
                </CardBody>
                <CardFooter stats className={classes.cardFooter}>
                  <h6 className={classes.legendTitle}>Legend</h6>
                  <i className={"fas fa-circle " + classes.info} /> Apple{` `}
                  <i className={"fas fa-circle " + classes.warning} /> Samsung
                  {` `}
                  <i className={"fas fa-circle " + classes.danger} /> Windows Phone
                  {` `}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer> */}
            <GridContainer style={{ margin: '0px 20px 0px 20px' }}>
              <GridItem xs={12}>
                <h3
                  style={{ margin: '0px 0px 0px -10px', fontSize: '22px' }}
                  className={classes.title}
                >
                  Transactions
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 10px 0px 10px' }}>
              <GridItem xs={3}>
                <Card
                  style={{
                    marginTop: '18px',
                    padding: '11px 0px 0px 0px',
                    position: 'relative',
                    zIndex: '36',
                    backgroundColor: '#FAEA61',
                  }}
                >
                  <p
                    style={{
                      color: '#2B2E34',
                      fontWeight: '800',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    +10,000
                  </p>
                </Card>
              </GridItem>
              <GridItem xs={9}>
                <Card
                  style={{
                    position: 'relative',
                    zIndex: '35',
                    margin: '9px 0px 0px -45px',
                    padding: '3px 0px 3px 50px',
                    backgroundColor: '#85ADA1',
                  }}
                >
                  <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                    <h5
                      style={{
                        color: 'white',
                        fontWeight: '600',
                        textAlign: 'left',
                      }}
                    >
                      You bought 10,000 ARCQ
                    </h5>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 10px 0px 10px' }}>
              <GridItem xs={3}>
                <Card
                  style={{
                    marginTop: '18px',
                    padding: '11px 0px 0px 0px',
                    position: 'relative',
                    zIndex: '36',
                    backgroundColor: '#1B3D59',
                  }}
                >
                  <p
                    style={{
                      color: '#FFF',
                      fontWeight: '800',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    -250
                  </p>
                </Card>
              </GridItem>
              <GridItem xs={9}>
                <Card
                  style={{
                    position: 'relative',
                    zIndex: '35',
                    margin: '9px 0px 0px -45px',
                    padding: '3px 0px 3px 50px',
                    backgroundColor: '#85ADA1',
                  }}
                >
                  <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                    <h5
                      style={{
                        color: 'white',
                        fontWeight: '600',
                        textAlign: 'left',
                      }}
                    >
                      You spent 250 ARCQ
                    </h5>
                    <p style={{ fontWeight: '400', color: 'white' }}>
                      Posted a video response to the “Artificial Intelligence”
                      public board.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 10px 0px 10px' }}>
              <GridItem xs={3}>
                <Card
                  style={{
                    marginTop: '18px',
                    padding: '11px 0px 0px 0px',
                    position: 'relative',
                    zIndex: '36',
                    backgroundColor: '#FAEA61',
                  }}
                >
                  <p
                    style={{
                      color: '#2B2E34',
                      fontWeight: '800',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    +5
                  </p>
                </Card>
              </GridItem>
              <GridItem xs={9}>
                <Card
                  style={{
                    position: 'relative',
                    zIndex: '35',
                    margin: '9px 0px 0px -45px',
                    padding: '3px 0px 3px 50px',
                    backgroundColor: '#85ADA1',
                  }}
                >
                  <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                    <h5
                      style={{
                        color: 'white',
                        fontWeight: '600',
                        textAlign: 'left',
                      }}
                    >
                      You earned 5 ARCQ
                    </h5>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 10px 0px 10px' }}>
              <GridItem xs={3}>
                <Card
                  style={{
                    marginTop: '18px',
                    padding: '11px 0px 0px 0px',
                    position: 'relative',
                    zIndex: '36',
                    backgroundColor: '#FAEA61',
                  }}
                >
                  <p
                    style={{
                      color: '#2B2E34',
                      fontWeight: '800',
                      fontSize: '18px',
                      textAlign: 'center',
                    }}
                  >
                    +500
                  </p>
                </Card>
              </GridItem>
              <GridItem xs={9}>
                <Card
                  style={{
                    position: 'relative',
                    zIndex: '35',
                    margin: '9px 0px 0px -45px',
                    padding: '3px 0px 3px 50px',
                    backgroundColor: '#85ADA1',
                  }}
                >
                  <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                    <h5
                      style={{
                        color: 'white',
                        fontWeight: '600',
                        textAlign: 'left',
                      }}
                    >
                      You earned 500 ARCQ
                    </h5>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 20px 80px 20px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  style={{
                    width: '88%',
                    margin: '15px 0px 0px -8px',
                    padding: '18px 20px',
                    borderRadius: '10px',
                    fontSize: '16px',
                    backgroundColor: '#6ABC43',
                    textTransform: 'capitalize',
                  }}
                >
                  Learn How You Can Earn More ARCQ Utilities
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div>
            <Hidden smDown implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-25% 0% 0% -8%',
                  height: '180%',
                  zIndex: '99999',
                }}
              >
                <img
                  style={{
                    margin: '2% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
            <Hidden mdUp implementation='css'>
              <div
                style={{
                  background: '#26202A',
                  position: 'absolute',
                  width: '110%',
                  margin: '-30% 0% 0% -10%',
                  height: '110%',
                  zIndex: '9999',
                }}
              >
                <img
                  style={{
                    margin: '15% 0% 0% 38%',
                    position: 'relative',
                    width: '20%',
                  }}
                  src={Loader}
                  alt='...'
                />
              </div>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.admin.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetUsersList: dispatch(startSetUsersList()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(TokenRedeem);
