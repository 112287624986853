import React from 'react';
import PropTypes from 'prop-types';

const Placeholder = ({ text }) => (
  <div
    style={{
      color: 'white',
      fontSize: '18px',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '20px 35px 105px 0px',
    }}
  >
    {text}
  </div>
);

Placeholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Placeholder;
