import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

export const TokenTransactionItem = ({ label, tokens, isReward }) => (
  <GridContainer style={{ margin: '0px 10px 0px 10px' }}>
    <GridItem xs={2}>
      <Card
        style={{
          marginTop: '18px',
          padding: '11px 0px 0px 0px',
          position: 'relative',
          zIndex: '36',
          backgroundColor: isReward ? '#FAEA61' : '#1B3D59',
        }}
      >
        <p
          style={{
            color: isReward ? '#2B2E34' : 'white',
            fontWeight: '800',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          {isReward ? '+' : '-'}
          {tokens}
        </p>
      </Card>
    </GridItem>
    <GridItem xs={10}>
      <Card
        style={{
          position: 'relative',
          zIndex: '35',
          margin: '9px 0px 0px -45px',
          padding: '3px 0px 3px 50px',
          backgroundColor: '#22307c',
        }}
      >
        <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
          <h5
            style={{
              color: 'white',
              fontWeight: '600',
              textAlign: 'left',
            }}
          >
            {label}
          </h5>
        </CardBody>
      </Card>
    </GridItem>
  </GridContainer>
);

TokenTransactionItem.propTypes = {
  label: PropTypes.string.isRequired,
  tokens: PropTypes.number.isRequired,
  isReward: PropTypes.bool,
};

export default TokenTransactionItem;
