import Pages from 'layouts/Pages.jsx';
import RTL from 'layouts/RTL.jsx';
import Dashboard from 'layouts/Dashboard.jsx';

var indexRoutes = [
  { path: '/pages', name: 'Pages', component: Pages },
  { path: '/rtl', name: 'RTL', component: RTL },
  { path: '/', name: 'Home', component: Dashboard },
];

export default indexRoutes;
