import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/MoreVert';

import Style from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';

class SimpleDropDown extends React.Component {
  state = { open: false };

  onClickIcon = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  onClickAway = (event) => {
    if (!this.anchorEl.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  render() {
    const { open } = this.state;
    const { classes, options } = this.props;

    return (
      <div>
        <IconButton
          color="inherit"
          onClick={this.onClickIcon}
          aria-label="action"
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
        >
          <Close className={classes.close} />
        </IconButton>
        <Popper
          style={{ borderRadius: '5px', margin: '5px 20px 0 0' }}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
          })}
        >
          <Grow in={open} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
            <Paper
              style={{ backgroundColor: '#79A197' }}
              className={classes.dropdown}
            >
              <ClickAwayListener onClickAway={this.onClickAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {options.map((e) => (
                    <MenuItem
                      key={e.text}
                      style={{ fontSize: '14px', padding: '5px', color: 'white' }}
                      onClick={() => {
                        this.setState({ open: false });
                        e.action();
                      }}
                      className={classNames({
                        [classes.dropdownItem]: true,
                        [classes['primaryHover']]: true,
                      })}
                    >
                      {e.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        </Popper>
      </div>
    );
  }
}

SimpleDropDown.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default withStyles(Style)(SimpleDropDown);
