import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Snack from '@material-ui/core/SnackbarContent';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SimpleDropDown from 'components/SimpleDropDown';

// Images
import DefaultProfile from 'assets/img/default-avatar.png';
import snackbarContentStyle from 'assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.jsx';

function SnackbarContent({ classes, options, data, color }) {
  const { avatar, displayName, firstName, lastName, bio, id } = data;
  const username = firstName ? firstName + ' ' + (lastName || '') : displayName;

  return (
    <Snack
      message={
        <div>
          <GridContainer>
            <GridItem xs={2} style={{ margin: '10px 0 0 8px' }}>
              <Link to={`/user-profile/${id}`}>
                <img
                  style={{
                    width: '100%',
                    borderRadius: '100%',
                  }}
                  src={avatar}
                  alt="..."
                />
              </Link>
            </GridItem>
            <GridItem
              xs={9}
              style={{
                marginTop: '10px',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  paddingLeft: '20px',
                }}
              >
                <b>{username}</b>
                <br />
                {bio}
              </span>
            </GridItem>
          </GridContainer>
        </div>
      }
      classes={{
        root: classes.root + ' ' + classes[color],
        message: classes.message,
      }}
      action={[<SimpleDropDown key="action" options={options} />]}
    />
  );
}

SnackbarContent.defaultProps = {
  color: 'info',
  avatar: DefaultProfile,
};

SnackbarContent.propTypes = {
  data: PropTypes.shape({
    bio: PropTypes.string,
    avatar: PropTypes.string,
    fistName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
  }),
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'danger',
    'primary',
    'rose',
  ]),
  close: PropTypes.bool,
};

export default withStyles(snackbarContentStyle)(SnackbarContent);
