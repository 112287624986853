const initState = {
    portfolios: { }
};

function mergeTrackData(existing, update) {
    let result = {};
    for (let [trackId, trackData] of Object.entries(update)) {
        let existingTrackData = existing[trackId];
        if (existingTrackData && existingTrackData.history) trackData = { ...trackData, history: existingTrackData.history }
        result[trackId] = trackData;
    }
    return result;
}

function mergeService(existing, update) {
    return {
        ...update,
        tracks: mergeTrackData(existing.tracks, update.tracks)
    }
}

function mergeServices(existing, update) {
    let result = {};
    for (let [serviceName, serviceData] of Object.entries(update)) {
        let existingService = existing[serviceName];
        if (existingService) serviceData = mergeService(existingService, serviceData);
        result[serviceName] = serviceData;
    }
    return result;
}

function mergeTrack(existing, update) {
    return { 
        ...update, 
        services: mergeServices(existing.services, update.services) 
    };
}

function mergeTracks(existing, update) {
    let result = {};
    for (let [trackName, trackData] of Object.entries(update)) {
        let existingTrack = existing[trackName];
        if (existingTrack) trackData = mergeTrack(existingTrack, trackData);
        result[trackName] = trackData;
    }
    return result;
}

// Retain existing track history, as this is updated asynchronously
function mergePortfolio(existing, update) {
    return {
        ...update,
        tracks: mergeTracks(existing.tracks, update.tracks)
    };
}

function setHistory(portfolio, trackName, serviceName, trackId, history) {
    let track = portfolio.tracks[trackName] || { services : {} };
    let service = track.services[serviceName] || { tracks : {} };
    let trackData = service.tracks[trackId] || {};
    return { 
        ...portfolio, 
        tracks: { 
            ...portfolio.tracks, 
            [trackName] : { 
                ...track, 
                services : {
                    ...track.services,
                    [serviceName] : {
                        ...service,
                        tracks: {
                            ...service.tracks,
                            [trackId] : {
                                ...trackData,
                                history
                            }
                        }
                    }
                }
            }
        }
    }
}

export default (state = initState, action) => {
    let portfolio;
    switch (action.type) {
        case 'PORTFOLIO_ADD': 
            return {
                ...state,
                portfolios: { ...portfolios, [action.pid] : { ...action.data } }
            }
        case 'PORTFOLIO_MODIFY': 
            portfolio = state.portfolios[action.pid];
            return {
                ...state,
                portfolios: { ...state.portfolios, [action.pid] : mergePortfolio(portfolio, action.data) }
            }
        case 'PORTFOLIO_REMOVE': 
            let { [action.pid] : remove, ...portfolios } = state.portfolios;
            return {
                ...state,
                portfolios
            }
        case 'PORTFOLIO_SET_HISTORY':
            portfolio = state.portfolios[action.pid];
            return {
                ...state,
                portfolios: { ...state.portfolios, [action.pid] : setHistory(portfolio, action.trackName, action.serviceName, action.trackId, action.history) }
            }
        default:
            return state;
    }
}