import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyle from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/recordStyle.jsx';
import Card from 'components/Card/Card.jsx';

// @material-ui/icons
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';

export const VideoPreview = ({ classes, videoUrl }) => (
  <Card
    style={{
      overflowY: 'hidden',
      borderRadius: '15px',
      height: '440px',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0)',
      background: 'transparent',
      padding: '40px',
      width: '100%',
      margin: '-60px 0px -30px 0px',
    }}
    className={classes.textWhite}
  >
    <div>
      <div
        style={{
          background: 'rgb(27, 61, 89)',
          overflowX: 'hidden',
          borderRadius: '10px',
          boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
        }}
        className={classes.imgDarken}
      >
        <video
          style={{
            width: '166%',
            marginLeft: '-39%',
            height: '402px',
            borderRadius: '0px',
          }}
          className={classes.feedImg}
          data-holder-rendered="true"
          autoPlay={true}
          loop
        >
          <source type="video/mp4" src={videoUrl} />
        </video>
      </div>
      <Link
        to={{
          pathname: '/record',
        }}
      >
        <ReplayOutlinedIcon
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            position: 'absolute',
            zIndex: '999',
            fontSize: '50px',
            color: '#C6C2BD',
            width: '80%',
            bottom: '39%',
          }}
        />
      </Link>
    </div>
  </Card>
);

VideoPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default withStyle(Style)(VideoPreview);
