import Actions from 'views/Actions/Actions.jsx';
import PendingActions from 'views/Admin/PendingActions.jsx';
import Users from 'views/Admin/Users.jsx';
import Trending from 'views/Trending/Trending.jsx';

// import JobApplication from 'views/JobApplication/JobApplication.jsx';
// import Brokers from 'views/Brokering/Brokers.jsx';
// import Orders from 'views/Brokering/Orders.jsx';
// import pagesRoutes from './pages.jsx';

// @material-ui/icons
import PersonIcon from '@material-ui/icons/Person';
import TrendingUp from '@material-ui/icons/TrendingUp';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Chat from '@material-ui/icons/Chat';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import FlareIcon from '@material-ui/icons/Flare';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ShareIcon from '@material-ui/icons/Share';

// import ContactSupport from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import AddBoxIcon from '@material-ui/icons/AddBox';
import BusinessIcon from '@material-ui/icons/Business';
import PollIcon from '@material-ui/icons/Poll';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FaceIcon from '@material-ui/icons/Face';
import HeadsetIcon from '@material-ui/icons/Headset';

// JSX ROUTING COMPONENTS
import ARCQ from '../views/ARCQ/ARCQ.jsx';
import Discovery from '../views/Discovery/Discovery.jsx';
import Record from '../views/Record/Record.jsx';
import Topics from '../views/Topics/Topics.jsx';
import Library from '../views/Library/Library.jsx';
import NotFound from '../views/NotFound';
import Wallet from '../views/Wallet/Wallet.jsx';
import Custodian from '../views/Custodian/Custodian.jsx';
import Oracle from '../views/Oracle';
import PickOracle from '../views/PickOracle';
import Settings from '../views/Settings/Settings.jsx';
import Notifications from '../views/Notifications/Notifications.jsx';
import RecordConfirm from '../views/Record/RecordConfirm.jsx';
import ViewPost from '../views/Record/ViewPost.jsx';
import Category from '../views/Topics/Category.jsx';
import TopicDetail from '../views/Topics/TopicDetail.jsx';
import ConfirmPurchase from '../views/Wallet/ConfirmTokenPurchase.jsx';
import InitializeChat from '../views/Wallet/InitializeChatWizard.jsx';
import RewardsFees from '../views/Wallet/RewardsFees.jsx';
import RedeemTokens from '../views/Wallet/TokenRedeem.jsx';
import ProfileView from '../views/ProfileView/ProfileView';
import PrivateList from '../views/Lists/PrivateList.jsx';
import PublicList from '../views/Lists/PublicList.jsx';
import Followers from '../views/Lists/Followers';
import InviteForm from '../views/Forms/InviteForm.jsx';
import Setup from "../views/Setup/Setup.jsx";
import ForecastingPage from '../views/Pages/AdminPages/ForecastingPage.jsx';
import PlatformAnalyticsPage from '../views/Pages/AdminPages/PlatformAnalyticsPage.jsx';
import ManageLabelsPage from '../views/Pages/AdminPages/ManageLabelsPage.jsx';
import ManageArtistsPage from '../views/Pages/AdminPages/ManageArtistsPage.jsx';
import AgentsPage from '../views/Pages/A&RPages/AgentsPage.jsx';
import AnalyticsPage from '../views/Pages/A&RPages/AnalyticsPage.jsx';
import FinancingPage from '../views/Pages/A&RPages/FinancingPage.jsx';
import LyricsPage from '../views/Pages/A&RPages/LyricsPage.jsx';
import PitchesPage from '../views/Pages/A&RPages/PitchesPage.jsx';
import ResourcesPage from '../views/Pages/A&RPages/ResourcesPage.jsx';
import RoyaltiesPage from '../views/Pages/A&RPages/RoyaltiesPage.jsx';
import SharePage from '../views/Pages/A&RPages/SharePage.jsx';
import ArtistFinancingPage from '../views/Pages/ArtistPages/FinancingPage.jsx';
import ArtistLabelsPage from '../views/Pages/ArtistPages/LabelsPage.jsx';
import ArtistLyricsPage from '../views/Pages/ArtistPages/LyricsPage.jsx';
import ArtistResourcesPage from '../views/Pages/ArtistPages/ResourcesPage.jsx';
import ArtistRoyaltiesPage from '../views/Pages/ArtistPages/RoyaltiesPage.jsx';
import ArtistSharePage from '../views/Pages/ArtistPages/SharePage.jsx';

export default [
  {
    admin: true,
    path: '/not-found',
    name: 'Not Found',
    component: NotFound,
  },
  {
    collapse: true,
    admin: true,
    path: '/admin',
    name: 'Admin',
    icon: PersonIcon,
    views: [
      { path: '/admin/Users', name: 'Users', component: Users },
      {
        path: '/admin/Pending',
        name: 'Pending Actions',
        component: PendingActions,
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: PersonIcon,
    component: ProfileView,
  },
  { 
    admin: true, 
    path: "/setup",
    name: "Setup", 
    component: Setup 
  },

  // Admin Pages
  { 
    // admin: true,
    path: '/admin/forecasting', 
    name: 'Forecasting', 
    icon: BusinessIcon,
    component: ForecastingPage,
    userType: 'admin'
  },
  { 
    // admin: true,
    path: '/admin/platform-analytics', 
    name: 'Platform Analytics', 
    icon: PollIcon,
    component: PlatformAnalyticsPage,
    userType: 'admin'
  },
  {
    // admin: true,
    path: '/admin/manage-artists',
    name: 'Manage Artists',
    icon: FaceIcon,
    component: ManageArtistsPage,
    userType: 'admin'
  },
  {
    // admin: true,
    path: '/admin/manage-labels',
    name: 'Manage Labels',
    icon: HeadsetIcon,
    component: ManageLabelsPage,
    userType: 'admin'
  },

  // A&R Pages
  {
    path: '/a&r/agents',
    name: 'Agents',
    icon: FaceIcon,
    component: AgentsPage,
    userType: 'agent'
  },
  {
    path: '/a&r/analytics',
    name: 'Analytics',
    icon: PollIcon,
    component: AnalyticsPage,
    userType: 'agent'
  },
  {
    path: '/a&r/financing',
    name: 'Financing',
    icon: LocalAtmIcon,
    component: FinancingPage,
    userType: 'agent'
  },
  {
    path: '/a&r/lyrics',
    name: 'Lyrics',
    icon: MusicNoteIcon,
    component: LyricsPage,
    userType: 'agent'
  },
  {
    path: '/a&r/pitches',
    name: 'Pitches',
    icon: RecordVoiceOverIcon,
    component: PitchesPage,
    userType: 'agent'
  },
  {
    path: '/a&r/resources',
    name: 'Resources',
    icon: SupervisorAccountIcon,
    component: ResourcesPage,
    userType: 'agent'
  },
  {
    path: '/a&r/royalties',
    name: 'Royalties',
    icon: MonetizationOnIcon,
    component: RoyaltiesPage,
    userType: 'agent'
  },
  {
    path: '/a&r/share',
    name: 'Share',
    icon: ShareIcon,
    component: SharePage,
    userType: 'agent'
  },

  // Artist Pages
  {
    path: '/artist/financing',
    name: 'Financing',
    icon: LocalAtmIcon,
    component: ArtistFinancingPage,
    userType: 'artist'
  },
  {
    path: '/artist/labels',
    name: 'Labels',
    icon: HeadsetIcon,
    component: ArtistLabelsPage,
    userType: 'artist'
  },
  {
    path: '/artist/lyrics',
    name: 'Lyrics',
    icon: MusicNoteIcon,
    component: ArtistLyricsPage,
    userType: 'artist'
  },
  {
    path: '/artist/resources',
    name: 'Resources',
    icon: SupervisorAccountIcon,
    component: ArtistResourcesPage,
    userType: 'artist'
  },
  {
    path: '/artist/royalties',
    name: 'Royalties',
    icon: MonetizationOnIcon,
    component: ArtistRoyaltiesPage,
    userType: 'artist'
  },
  {
    path: '/artist/share',
    name: 'Share',
    icon: ShareIcon,
    component: ArtistSharePage,
    userType: 'artist'
  },

  // { path: '/arcq', name: 'ARCQ', icon: HomeIcon, component: ARCQ },
  // {
  //   path: '/discovery',
  //   name: 'Discovery',
  //   icon: ExploreIcon,
  //   component: Discovery,
  // },
  // {
  //   path: '/trending',
  //   name: 'Trending',
  //   icon: TrendingUp,
  //   component: Trending,
  // },
  // { path: '/record', name: 'Record', icon: AddBoxIcon, component: Record },
  // { path: '/topics', name: 'Topics', icon: ListAltIcon, component: Topics },
  // {
  //   path: '/library/:type',
  //   admin: true,
  //   name: 'Library',
  //   icon: LocalLibraryIcon,
  //   component: Library,
  // },
  // {
  //   path: '/library',
  //   name: 'Library',
  //   icon: LocalLibraryIcon,
  //   component: Library,
  // },
  {
    path: '/wallet',
    name: 'Wallet',
    icon: AccountBalanceWalletIcon,
    component: Wallet,
    userType: 'all'
  },
  // { path: '/custodian', name: 'Custodian', icon: Chat, component: Custodian },
  { path: '/oracle/:type', name: 'Orator', admin: true, component: Oracle },
  {
    path: '/pick-oracle',
    name: 'Lyricist',
    icon: FlareIcon,
    component: PickOracle,
    userType: 'all'
  },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   icon: NotificationImportant,
  //   component: Notifications,
  // },
  {
    path: '/settings',
    name: 'Settings',
    icon: SettingsApplications,
    component: Settings,
    userType: 'all'
  },

  // Other Routes
  { path: '/actions', admin: true, name: 'Actions', component: Actions },
  // {
  //   path: '/record-confirm/:id',
  //   admin: true,
  //   name: 'Confirm Recording',
  //   component: RecordConfirm,
  // },
  // {
  //   path: '/view-post/:id',
  //   admin: true,
  //   name: 'View Post',
  //   component: ViewPost,
  // },
  // {
  //   path: '/category/:category',
  //   admin: true,
  //   name: 'Category',
  //   component: Category,
  // },
  // {
  //   path: '/topic-detail/:topic',
  //   admin: true,
  //   name: 'Topic Detail',
  //   component: TopicDetail,
  // },
  // {
  //   path: '/confirm-purchase',
  //   admin: true,
  //   name: 'Confirm Purchase',
  //   component: ConfirmPurchase,
  // },
  // {
  //   path: '/initialize-chatbot',
  //   admin: true,
  //   name: 'Initialize Bot',
  //   component: InitializeChat,
  // },
  {
    path: '/rewards-fees',
    admin: true,
    name: 'Rewards & Fees',
    component: RewardsFees,
  },
  // {
  //   path: '/redeem-tokens',
  //   admin: true,
  //   name: 'Redeem Tokens',
  //   component: RedeemTokens,
  // },
  {
    path: '/user-profile/:profileId',
    admin: true,
    name: 'Public Profile',
    component: ProfileView,
  },
  // {
  //   path: '/private-list',
  //   admin: true,
  //   name: 'Private Posts',
  //   component: PrivateList,
  // },
  // {
  //   path: '/public-list',
  //   admin: true,
  //   name: 'Public Posts',
  //   component: PublicList,
  // },
  {
    path: '/followers',
    admin: true,
    name: 'Followers',
    component: Followers,
  },
  // {
  //   path: '/invite',
  //   admin: true,
  //   name: 'Invite',
  //   component: InviteForm,
  // },
  {
    path: '/',
    admin: true,
    name: 'Not Found',
    component: NotFound,
  },

  // { path: "/support", name: "Support", icon: ContactSupport, component: Support },
  // { path: "/threads/:id", admin: true, name: "threads", component: Threads },
  // { path: "/:caseid/clientthreads/:threadid", admin: true, name: "threads", component: ClientThreads },
  // { path: "/case/:id", admin: true, name: "threads", component: CasesDetails },
  // { path: "/casechat/:id", admin: true, name: "threads", component: ChatCasesDetails },

  // {
  //   collapse: true,
  //   path: "/learn",
  //   name: "Learn",
  //   icon: DashboardIcon,
  //   state: "openLearn",
  //   views: [
  //     { path: "/learn/core-beliefs", name: "Core Beliefs", component: Profile },
  //     { path: "/learn/principles", name: "Principles", component: Profile },
  //     { path: "/learn/practices", name: "Practices", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/assess",
  //   name: "Assess",
  //   icon: DashboardIcon,
  //   state: "openAssess",
  //   views: [
  //     { path: "/assess/assessment", name: "Take the Assessment", component: TakeAssessment },
  //     { path: "/assess/path", name: "Path to Purposeful Leadership", component: Profile },
  //     { path: "/assess/purpose", name: "Purpose Embraced Path", component: Profile },
  //     { path: "/assess/stakeholders", name: "Stakeholders Engaged Path", component: Profile },
  //     { path: "/assess/strategy", name: "Strategy Aligned Path", component: Profile },
  //     { path: "/assess/actions", name: "Actions Aligned Path", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/grow",
  //   name: "Grow",
  //   icon: DashboardIcon,
  //   state: "openGrow",
  //   views: [
  //     { path: "/grow/articles", name: "Articles Library", component: Profile },
  //     { path: "/grow/tudies", name: "Research Studies Library", component: Profile },
  //     { path: "/grow/books", name: "Books Library", component: Profile },
  //     { path: "/grow/share", name: "Share a Resource", component: Profile },
  //     { path: "/grow/consultants", name: "Directory of Consultants", component: Profile },
  //     { path: "/grow/professionals", name: "Directory of Consultants", component: Profile },
  //     { path: "/grow/courses", name: "Upcoming Courses", component: Profile },
  //     { path: "/grow/archive", name: "Course Archive", component: Profile },
  //     { path: "/grow/suggest", name: "Suggest a Course or Webinar", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/connect",
  //   name: "Connect",
  //   icon: DashboardIcon,
  //   state: "openConnect",
  //   views: [
  //     { path: "/connect/communities", name: "Communities of Interest", component: Profile },
  //     { path: "/connect/blog", name: "Blog", component: Profile },
  //     { path: "/connect/newsletter", name: "Newsletter", component: Profile },
  //     { path: "/connect/radio", name: "Radio", component: Profile },
  //     { path: "/connect/group-calls", name: "Group Calls", component: Profile },
  //     { path: "/connect/group-calls", name: "Affliates", component: Profile },
  //     { path: "/connect/member", name: "Become a Member", component: Profile },
  //     { path: "/connect/partner", name: "Become a Partner", component: Profile },
  //     { path: "/connect/champion", name: "Become a Champion", component: Profile },
  //     { path: "/connect/sponsor", name: "Become a Sponsor or Donor", component: Profile },
  //     { path: "/connect/advocate", name: "Become an Advocate", component: Profile },
  //     { path: "/connect/marketplace", name: "Talent Marketplace", component: Profile }

  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/about",
  //   name: "About",
  //   icon: DashboardIcon,
  //   state: "openAbout",
  //   views: [
  //     { path: "/about/who-we-are", name: "Who we Are", component: Profile },
  //     { path: "/about/champions", name: "Our Champions", component: Profile },
  //     { path: "/about/bbard", name: "Our Board Members", component: Profile },
  //     { path: "/about/sponsors", name: "Our Sponsors", component: Profile },
  //     { path: "/about/memmbers", name: "Member Directory", component: Users }
  //   ]
  // },
  // { path: "/contact", name: "Contact", component: Profile },

  // { path: "/dashboard/apply", name: "Job Application", component: JobApplication },
  // {
  //   collapse: true,
  //   path: "/admin",
  //   name: "Admin",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/field",
  //   name: "Field",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/communications",
  //   name: "Communications",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/volunteers",
  //   name: "Volunteers",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/political",
  //   name: "Political",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/fundraising",
  //   name: "Fundraising",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/treasurer",
  //   name: "Treasurer",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/legal",
  //   name: "Legal",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/technology",
  //   name: "Technology",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/scheduling",
  //   name: "Scheduling",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // }
];
