import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { displayError } from 'store/actions/errorActions';
import { updateUser } from 'store/actions/profileActions';
import store from 'services/StoreService';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Loader from 'components/Loader';
import ModalUpdateProfile from 'components/ModalUpdateProfile';
import UserVideos from 'components/UserVideos';
import DefaultAvatar from 'assets/img/default-avatar.png';
import ProfileViewPic from './ProfileViewPic';
import ProfileViewBioSummary from './ProfileViewBioSummary';
import ProfileViewButtons from './ProfileViewButtons';
import ProfileViewPostActivity from './ProfileViewPostActivity';
import ProfileViewSearchBox from './ProfileViewSearchBox';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import Hidden from '@material-ui/core/Hidden';
import SectionCarouselDesktop from './Sections/SectionCarouselDesktop.jsx';
import SectionCarouselMobile from './Sections/SectionCarouselMobile.jsx';
import linkedInIcon from 'assets/img/linkedin-icon.png';
import slackIcon from 'assets/img/slack-icon.png';
import twitterIcon from 'assets/img/twitter-icon.png';

const MyProfileView = ({
  user,
  updateUser,
  followUser,
  displayError,
  unFollowUser,
  isAlreadyFollowed,
}) => {
  let allUsers;

  const { uid, recordings } = user || {};

  const [modalOpen, setModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const getUsers = async (input) => {
    if (!input) {
      setSearchResults([]);
      return;
    }
    const results = [];
    const reg = new RegExp(input, 'i');
    allUsers = allUsers || (await store.getAllUsers());
    allUsers.forEach((e) => {
      if (
        // exclude current user
        e.id !== uid &&
        (reg.test(e.firstName) ||
          reg.test(e.lastName) ||
          reg.test(e.displayName))
      ) {
        results.push({
          id: e.id,
          avatar: e.avatar || DefaultAvatar,
          lastName: e.lastName || null,
          firstName: e.firstName || null,
          displayName: e.displayName || `${e.firstName} ${e.lastName}`,
          userType: e.userType
        });
      }
    });
    setSearchResults(results);
  };

  return (
    //   <div>
    //     <GridContainer style={{overflowX: 'hidden'}}>
    //       <GridItem lg={6} md={6} sm={6} xs={12}>
    //         <ProfileViewPic avatar={user.avatar} />
    //       </GridItem>
    //       <GridItem lg={6} md={6} sm={6} xs={12}>
    //         <ProfileViewBioSummary user={user} editable={true} />
    //       </GridItem>
    //       <ModalUpdateProfile
    //         uid={uid}
    //         user={user}
    //         open={modalOpen}
    //         onError={displayError}
    //         onClose={() => setModalOpen(false)}
    //         onUploadPic={(avatar) => updateUser({ avatar })}
    //       />
    //     </GridContainer>
    //     {/* <GridContainer>
    //       <GridItem xs={12}>
    //         <FloatingButton text="Become Eternal" />
    //       </GridItem>
    //     </GridContainer> */}
    //   </div>
    // ) : (
    //     <Loader />
    <div>
      <GridContainer style={{ overflowX: 'hidden' }}>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <ProfileViewPic avatar={user.avatar} />
          {/* <GridItem lg={6} md={6} sm={6} xs={12}>
            <ProfileViewBioSummary user={user} editable={true} />
          </GridItem> */}
          <h2 style={{ color: '#8f8f8f' }}>
            <center>{user.displayName}</center>
          </h2>
          <h4 style={{ color: '#7AB75E' }}>
            <center>{user.email}</center>
          </h4>
        </GridItem>
      </GridContainer>
      <Card
        style={{
          backgroundColor: '#55338B',
          height: '48vh',
          color: '#fff',
          padding: '10px',
        }}
      >
        <GridContainer>
          <GridItem xs={10} sm={10} md={10} lg={10}>
            <div style={{ margin: '0 0 0 25px' }}>
              <h5 style={{ fontWeight: '800' }}>Job Title</h5>
              <p>{user.userType}</p>
              <h5 style={{ fontWeight: '800' }}>Phone</h5>
              <p>555-555-5555</p>
              <h5 style={{ fontWeight: '800' }}>Mailing Address</h5>
              <p>SMCK Media</p>
              <p>1234 Broadway St.</p>
              <p>State, City, Zip</p>
            </div>
          </GridItem>
        </GridContainer>
      </Card>
      <GridContainer style={{ backgroundColor: '#F45D86', color: '#fff' }}>
        <h5 style={{ fontWeight: '800', margin: '8px 0 23px 150px' }}>
          Social Media
        </h5>
        <GridContainer>
          <GridItem
            xs={8}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '0 0 15px 60px',
            }}
          >
            <img
              src={linkedInIcon}
              style={{ width: '14vw', borderRadius: '50%' }}
            />
            <img
              src={slackIcon}
              style={{ width: '14vw', borderRadius: '50%' }}
            />
            <img
              src={twitterIcon}
              style={{ width: '14vw', borderRadius: '50%' }}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
    </div>
  );
};

MyProfileView.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    avatar: PropTypes.string,
    following: PropTypes.array,
    recordings: PropTypes.array,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
  followUser: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
  unFollowUser: PropTypes.func.isRequired,
  isAlreadyFollowed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const user = state.profile || {};
  return { user };
};

export default connect(mapStateToProps, { updateUser, displayError })(
  MyProfileView
);
