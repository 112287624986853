// Store as a singleton

import cf from 'config/db';
import { db, FieldValue } from 'firebase/fbConfig.js';

class StoreService {
  constructor() {
    if (!StoreService.instance) {
      StoreService.instance = this;
    }
    // Collections shortcuts
    this.userCollection = db.collection(cf.collection.users);
    this.recordingCollection = db.collection(cf.collection.recordings);
    this.gptModelsCollection = db.collection(cf.collection.gptModels);
  }

  getUserData = async (uid) => {
    // check users collection if users exists
    const snapshot = await this.userCollection.doc(uid).get();
    // grab user data
    const docData = snapshot.data();
    // return user id and data
    return (
      docData && {
        // add id to data fetched
        uid: snapshot.id,
        ...docData,
      }
    );
  };

  getAllUsers = async () => {
    const data = [];
    const snapshot = await this.userCollection.get();
    snapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return data;
  };

  getUsersRecordingsByType = async (ids, type) => {
    const data = [];
    // _TODO: loop when array is over 10 ids long
    const snapshot = await this.recordingCollection
      .where('uid', 'in', ids)
      .where('type', '==', type)
      .get();
    snapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  };

  getRecordingsByTopic = async (topic) => {
    const data = [];
    const snapshot = await this.recordingCollection
      .where('topic', '==', topic)
      .get();
    snapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  };

  getOneRecordingData = async (rid) => {
    const snapshot = await this.recordingCollection.doc(rid).get();
    const docData = snapshot.data();
    return (
      docData && {
        // add id to data fetched
        id: snapshot.id,
        ...docData,
      }
    );
  };

  getRecordingComments = async (rid) => {
    const { comments } = cf.subcollection;
    const data = [];
    const doc = this.recordingCollection.doc(rid);
    const snapshot = await doc.collection(comments).get();
    snapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  };

  getModelSamples = async (uid, model) => {
    const data = [];
    const snapshot = await this.userCollection.doc(uid).collection(model).get();
    snapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  };

  getGPTModels = async () => {
    const data = [];
    const snapshot = await this.gptModelsCollection.get();
    snapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    return data;
  };

  addUser = async (uid, data) => {
    await this.userCollection.doc(uid).set(data, { merge: true });
  };

  addRecording = async (uid, data) => {
    const createdAt = new Date().getTime();
    // Then persist in the recordings collection
    const { id } = await this.recordingCollection.add({
      ...data,
      uid,
      createdAt,
    });
    // _TODO: remove as the recordings collection should br the only source of truth
    // Persist in user collection
    const entry = { ...data, id };
    await this.updateUser(uid, {
      recordings: FieldValue.arrayUnion(entry),
    });
    return entry;
  };

  addComment = async (rid, comment, userData) => {
    const createdAt = new Date().getTime();
    const { id } = await this.recordingCollection
      .doc(rid)
      .collection('comments')
      .add({ ...userData, comment, createdAt });
    return id;
  };

  updateUser = async (uid, data) => {
    await this.userCollection.doc(uid).update(data);
  };

  // _TODO: remove as recordings collection should be the single source of truth
  updateUserRecordings = async (uid, recordings) => {
    await this.updateUser(uid, { recordings });
  };

  updateFollowedUsers = async (uid, following) => {
    await this.updateUser(uid, { following });
  };

  updateAvatar = async (uid, avatar) => {
    await this.updateUser(uid, { avatar });
  };

  setRecordingType = async (rid, type) => {
    await this.recordingCollection.doc(rid).set(
      {
        type,
        typeChangedAt: new Date().getTime(),
      },
      { merge: true }
    );
  };

  addSample = async (uid, model, entry) => {
    await this.userCollection.doc(uid).collection(model).add(entry);
  };
}

const instance = new StoreService();
Object.freeze(instance);

export default instance;
